import React, { useState, useEffect } from 'react';
import BalanceSheet from './BalanceSheet';
import IncomeStatement from './IncomeStatement';
import PerformanceRatios from './PerformanceRatios';
import Demographics from '../DemographicComponents/Demographics';


const SummaryGreeting = () => {
  const [bank, setBank] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [financialStatement, setFinancialStatement] = useState('BalanceSheet');
  const [view, setView] = useState('Quarterly');

  useEffect(() => {
    fetchRandomBank();
  }, []);

  const fetchRandomBank = async () => {
    try {
      setIsLoading(true);
      const countResponse = await fetch('https://www.bankr.ai/api/commercial-re/');
      const countData = await countResponse.json();
      const recordsPerPage = 25; 
      const totalPages = Math.ceil(countData.count / recordsPerPage);
      const randomPage = Math.floor(Math.random() * totalPages) + 1;

      const response = await fetch(`https://www.bankr.ai/api/commercial-re/?page=${randomPage}`);
      const data = await response.json();
      const randomBankData = data.results[Math.floor(Math.random() * data.results.length)];
      const cert = randomBankData.CERT;

      const bankResponse = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${cert}&fields=REPDTE%2CNAME&sort_by=REPDTE&sort_order=DESC&limit=10&offset=0&agg_limit=0&format=json&download=false&filename=data_file`);
      const bankData = await bankResponse.json();
      const bankName = bankData.data[0].data.NAME;

      setBank({
        name: bankName,
        certificate: cert,
        ...randomBankData,
      });

    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatementChange = (e) => {
    setFinancialStatement(e.target.value);
  };

  const setViewQuarterly = () => setView('Quarterly');
  const setViewAnnual = () => setView('Annual');

  const buttonStyle = (isActive) => ({
    backgroundColor: isActive ? '#4169E1' : 'white',
    color: isActive ? 'white' : '#4169E1',
    border: '1px solid #4169E1',
    borderRadius: '30px',
    padding: '4px 8px',
    cursor: 'pointer',
    outline: 'none',
  });

  const renderStatement = () => {
    switch (financialStatement) {
      case 'BalanceSheet':
        return <BalanceSheet view={view} cert={bank.certificate} />;
      case 'IncomeStatement':
        return <IncomeStatement view={view} cert={bank.certificate} />;
      case 'PerformanceRatios':
        return <PerformanceRatios view={view} cert={bank.certificate} />;
      case 'Demographics':
        return <Demographics cert={bank.certificate} />;
      default:
        return <BalanceSheet view={view} cert={bank.certificate} />;
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <h1>Summary Financials</h1>
      {bank && (
        <>
          <h2>{bank.name} - ID: {bank.certificate}</h2>
          <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <span>Select View: </span>
              <select value={financialStatement} onChange={handleStatementChange}>
                <option value="BalanceSheet">Balance Sheet</option>
                <option value="IncomeStatement">Income Statement</option>
                <option value="PerformanceRatios">Performance Ratios</option>
                <option value="Demographics">Demographics</option>

              </select>
              </div>
                {/* Conditionally display the buttons */}
                {financialStatement !== 'Demographics' && (
                  <div>
                    <button
                      style={buttonStyle(view === 'Quarterly')}
                      onClick={setViewQuarterly}
                    >
                      Quarterly
                    </button>
                    <button
                      style={buttonStyle(view === 'Annual')}
                      onClick={setViewAnnual}
                    >
                      Annual
                    </button>
                  </div>
                )}
              </div>
              {renderStatement()}
        </>
      )}
    </div>
  );
};

export default SummaryGreeting;
