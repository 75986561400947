import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import BalanceSheet from '../components/SummaryComponents/BalanceSheet';
import IncomeStatement from '../components/SummaryComponents/IncomeStatement';
import PerformanceRatios from '../components/SummaryComponents/PerformanceRatios';
import Demographics from '../components/DemographicComponents/Demographics';

const SummaryScreen = () => {

  const location = useLocation();
  const { bankName, bankId } = location.state;

  console.log(location.state);

  const [financialStatement, setFinancialStatement] = useState('BalanceSheet');
  const [view, setView] = useState('Quarterly');

  const handleStatementChange = (e) => {
    setFinancialStatement(e.target.value);
  };

  const setViewQuarterly = () => {
    setView('Quarterly');
  };

  const setViewAnnual = () => {
    setView('Annual');
  };

  const buttonStyle = (isActive) => ({
    backgroundColor: isActive ? '#4169E1' : 'white',
    color: isActive ? 'white' : '#4169E1',
    border: '1px solid #4169E1',
    borderRadius: '30px',
    padding: '4px 8px',
    cursor: 'pointer',
    outline: 'none',
  });

  const renderStatement = () => {
    switch (financialStatement) {
      case 'BalanceSheet':
        return <BalanceSheet view={view} cert={bankId} />;
      case 'IncomeStatement':
        return <IncomeStatement view={view} cert={bankId} />;
      case 'PerformanceRatios':
        return <PerformanceRatios view={view} cert={bankId} />;
      case 'Demographics':
        return <Demographics cert={bankId} />;
      default:
        return <BalanceSheet view={view} cert={bankId} />;
    }
  };

  return (
    <div>
      <h1>Summary Financials</h1>
      <h2>{bankName} - ID: {bankId}</h2>
      <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <span>Select View: </span>
          <select value={financialStatement} onChange={handleStatementChange}>
            <option value="BalanceSheet">Balance Sheet</option>
            <option value="IncomeStatement">Income Statement</option>
            <option value="PerformanceRatios">Performance Ratios</option>
            <option value="Demographics">Demographics</option>
          </select>
          </div>
                {/* Conditionally display the buttons */}
                {financialStatement !== 'Demographics' && (
                  <div>
                    <button
                      style={buttonStyle(view === 'Quarterly')}
                      onClick={setViewQuarterly}
                    >
                      Quarterly
                    </button>
                    <button
                      style={buttonStyle(view === 'Annual')}
                      onClick={setViewAnnual}
                    >
                      Annual
                    </button>
                  </div>
                )}
              </div>
              {renderStatement()}
    </div>
  );
};

export default SummaryScreen;