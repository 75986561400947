import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './BalanceSheet.css'
import {rowData,balanceSheet} from './balanceRowData';
import LineGraph from '../GraphComponents/LineGraph';


const BalanceSheet = ({ view, cert }) => {
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [annualData, setAnnualData] = useState(null);
  const [quarterlyData, setQuarterlyData] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showGraph, setShowGraph] = useState(false);
  const hideTimeout = useRef(null);
  const [activeGraphRow, setActiveGraphRow] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  

  const [expandedRows, setExpandedRows] = useState(rowData.filter(row => row.level === 0).map(row => row.label));

  const calculateLargestQuarterlyJump = (extractedValues, valueKey) => {
    if (valueKey === 'N/A') return "";
    if (!extractedValues || extractedValues.length <= 1) return "Inconsistent Reporting";
  
    let greatestChange = "No change";
    let largestJump = 0;
    let hasNumericValue = false;
    let hasNAValue = false;
    
    for (let i = 0; i < extractedValues.length - 1; i++) {
        const startValue = parseFloat(extractedValues[i + 1].value);
        const endValue = parseFloat(extractedValues[i].value);
        
        if(!isNaN(endValue)) hasNumericValue = true;
        if(isNaN(startValue) || isNaN(endValue)) hasNAValue = true;
        
        const dateString = extractedValues[i].date.toString();
        const year = dateString.slice(0, 4);
        const month = parseInt(dateString.slice(4, 6));
        let quarter;
        if (month <= 3) quarter = 'Q1';
        else if (month <= 6) quarter = 'Q2';
        else if (month <= 9) quarter = 'Q3';
        else quarter = 'Q4';
        
        if(isNaN(startValue) && isNaN(endValue)) continue;
        
        if(startValue === 0 && isNaN(endValue)) {
            greatestChange = `${year} ${quarter} : Decrease from 0`;
            continue;
        }
        
        if(startValue === 0 && endValue === 0) continue;
        
        if(startValue === 0) {
            greatestChange = `${year} ${quarter} : ${endValue > 0 ? "Increase From 0" : "Decrease From 0"}`;
            continue;
        }

        let jump;
        if(startValue < 0 && endValue > 0) {
            jump = ((endValue + Math.abs(startValue)) / Math.abs(startValue)) * 100;
        } else {
            jump = ((endValue - startValue) / Math.abs(startValue)) * 100;
        }
        
        if (Math.abs(jump) > Math.abs(largestJump)) {
            largestJump = jump;
            greatestChange = `${year} ${quarter} : ${toFixedTruncate(largestJump,2)}%`;
        }
    }
    
    if(hasNAValue && hasNumericValue) return 'Inconsistent Reporting';
    if(hasNAValue && !hasNumericValue) return 'Inconsistent Reporting';
    
    return greatestChange;
};
function toFixedTruncate(num, fixed) {
  const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${(fixed || -1)}})?`);
  const matched = num.toString().match(re);
  return matched ? matched[0] : num.toString();
}


  
  const toggleRow = (label) => {
    setExpandedRows(prevState => {
      if (prevState.includes(label)) {
        return prevState.filter(rowLabel => rowLabel !== label);
      } else {
        return [...prevState, label];
      }
    });
  };
  
  useEffect(() => {
  if (balanceSheetData) {
    setAnnualData(getAnnualData(balanceSheetData));
    setQuarterlyData(getQuarterlyData(balanceSheetData));
  }
  }, [view, balanceSheetData]);

  const getQuarterlyData = (data) => {
    const latestYear = parseInt(data[0]?.REPDTE.slice(0, 4), 10) || 0;
    const earliestYear = Math.max(latestYear - 3, 0);
  
    // Filter the data to only include the years between earliestYear and latestYear (inclusive)
    return data.filter((item) => {
      const itemYear = parseInt(item.REPDTE.slice(0, 4), 10);
      return itemYear >= earliestYear && itemYear <= latestYear;
    });
  };
  
  useEffect(() => {
    if (balanceSheetData) {
      setAnnualData(getAnnualData(balanceSheetData));
    }
  }, [view, balanceSheetData]);

  const getAnnualData = (data) => {
    const annualData = data.filter((item) => item.REPDTE.endsWith('1231'));
    const latestYear = parseInt(annualData[0]?.REPDTE.slice(0, 4), 10) || 0;
    
    // Create an array containing the last 10 years
    const lastTenYears = Array.from({ length: 10 }, (_, i) => latestYear - i);
  
    // Filter the data to only include the years in the lastTenYears array
    return annualData.filter((item) => lastTenYears.includes(parseInt(item.REPDTE.slice(0, 4), 10)));
  };

  useEffect(() => {
    const fetchData = async () => {
        // Construct the single URL using baseUrl and balanceSheet array
        const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A%20${cert}&fields=CERT%2CREPDTE%2C${balanceSheet.join('%2C')}&sort_by=REPDTE&sort_order=DESC&limit=44&offset=0&format=json&download=false&filename=data_file`;
        
        try {
            // Fetch data from the constructed URL
            const response = await axios.get(apiUrl);
            console.log('Response:', response.data);

            const data = response.data.data.map((item) => item.data);
            setBalanceSheetData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [cert, balanceSheet]); // Added balanceSheet as a dependency because it's used inside useEffect

      

const formatCurrency = (value) => {
  return `$${value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
};


  const formatDateToQuarter = (dateString) => {
    // Reformat the date string from 'YYYYMMDD' to 'YYYY-MM-DD'
    const formattedDateString = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
    const date = new Date(formattedDateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
  
    let quarter;
    if (month <= 3) {
      quarter = 'Q1';
    } else if (month <= 6) {
      quarter = 'Q2';
    } else if (month <= 9) {
      quarter = 'Q3';
    } else {
      quarter = 'Q4';
    }
  
    return `${year} ${quarter}`;
  };
  
  
  const currentData = view === 'Annual' ? annualData : quarterlyData;

  const quarters = currentData
    ? currentData.map((item) => (view === 'Annual' ? item.REPDTE.slice(0, 4) : formatDateToQuarter(item.REPDTE)))
    : [];

    const extractValues = (key) => {
      if (key === 'N/A'|| key === null) {
          return quarters.map(() => ({ date: '', value: 'N/A' }));
      }
  
      return currentData
          ? currentData.map((item) => ({
              date: item.REPDTE,
              value: item[key],
          }))
          : [];
  };
  
  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatPercentage = (value) => {
    return value.toFixedTruncate(2) + '%';
  };

  const nonCurrencyLabels = [
    '1. Total Employees (full-time equivalent)',
  ];
  const formatValue = (value, label, valueKey) => {
    if ((value === null || value === 'N/A')&& valueKey!=='N/A') {
        return 'N/A';
    }
    else if (valueKey === 'N/A'){
        return '';
    }
    if (nonCurrencyLabels.includes(label)) {
      return formatNumber(value);
    } else if (label.includes('% Insured (Estimated)')) {
      return formatPercentage(value);
    } else {
      return formatCurrency(label !== ' 1. Total Employees (full-time equivalent)' ? value * 1000 : value);
    }
};



function insertArrowIntoSpace(label, arrowChar) {
  return (
      <>
          <span className="small-arrow">{arrowChar}</span>
          {label}
      </>
  );
}

const TableRow = ({ row, index, expandedRows, toggleRow, view}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [rowPosition, setRowPosition] = useState({ top: 0, left: 0 });
  const isExpanded = expandedRows.includes(row.label);
  const hasChildren = row.children && row.children.length > 0;
  const hideTimeout = useRef();



  const extractedValues = extractValues(row.valueKey);
  let modifiedLabel = row.label;

  useEffect(() => {
    if(view === 'Quarterly') {
        console.log('Extracted Values for Annual View:', row.label, extractedValues);
    }
}, [view, extractedValues, row.label]); 
  // Determine if there's valid data to render a graph.
  const hasGraphData = extractedValues.some(value => value.value !== '' && value.value !== 'N/A' && value.value !== null);


  const greatestChange = calculateLargestQuarterlyJump(extractedValues,row.valueKey);

  


  if (hasChildren) {
    const arrowChar = isExpanded ? "▼" : "▶";
    modifiedLabel = insertArrowIntoSpace(row.label, arrowChar);
  }

  return (
    <>
        <tr
            onMouseEnter={(e) => {
            clearTimeout(hideTimeout.current);
            const rect = e.currentTarget.getBoundingClientRect();
            const containerRect = document.querySelector('.table-container-bs').getBoundingClientRect();
            
            // calculate the middle of the row
            const middleOfRow = rect.top + rect.height / 2;
            // half of the height of the graph
            const halfOfGraphHeight = 0.065 * window.innerHeight; // 13% of viewport height divided by 2
            
            setRowPosition({
              top: middleOfRow - halfOfGraphHeight, 
              left: containerRect.right
            });
            
            hideTimeout.current = setTimeout(() => {
              setHoveredRowIndex(index);
            }, 500);
          }}
        
          onMouseLeave={() => {
            clearTimeout(hideTimeout.current); // Clear timeout to avoid delayed hover effect
            setHoveredRowIndex(null);
          }}
          onClick={() => hasChildren && toggleRow(row.label)}
          style={{ cursor: hasChildren ? 'pointer' : 'default' }}
          >
        <td style={{ ...getIndentation(row.level), backgroundColor: row.index % 2 === 0 ? "#DDE7F4" : "#F1F1F3" }}>
          {modifiedLabel}
        </td>
        <td style={{ textAlign: 'right', backgroundColor: row.index % 2 === 0 ? "#FFFFFF" : "#F1F1F3", minWidth:'175px' }}>
          {greatestChange}
        </td>
        {extractedValues.map((value, idx) => (
          <td key={idx} style={{ textAlign: 'right',backgroundColor: row.index % 2 === 0 ? "#FFFFFF" : "#F1F1F3"  }}>
            {value.value !== '' ? formatValue(value.value, row.label, row.valueKey) : value.value}
          </td>
        ))}
      </tr>
      {
        // Check if the label is not '1. Total Employees (full-time equivalent)'
        // before rendering the graph
        hoveredRowIndex === index && hasGraphData && row.label !== '1. Total Employees (full-time equivalent)' && row.valueKey !== 'N/A' && greatestChange !== 'Inconsistent Reporting' &&(
            <div 
            style={{
              position: 'fixed', 
              top: `${rowPosition.top}px`, 
              left: `${rowPosition.left}px`, 
              zIndex: 9999,
              width: '13%', 
              height: '13%',
            }}
          >
            <LineGraph data={extractedValues} />
          </div>
        )
      }
      {
        hasChildren && expandedRows.includes(row.label) && row.children.map((childRow, childIndex) => (
          <TableRow 
            key={childRow.label} 
            row={childRow}
            index={childIndex + index + 1} 
            expandedRows={expandedRows}
            toggleRow={toggleRow}
            view={view}
          />
        ))
      }
    </>
  );
  
};

const getIndentation = (level) => {
  const indentations = [0, 40, 80, 120, 150]; // Based on the levels you've mentioned
  return { paddingLeft: `${indentations[level]}px` };
}
  return (
    <div 
    className='table-container-bs'
    onMouseLeave={() => {
        hideTimeout.current = setTimeout(() => {
            setHoveredRow(null);
            setShowGraph(false);
        }, 200);
    }}
    
    >
   
    <div className='table-container-bs'>
        
        <table className="table table-hover custom-table-bs">
        <thead>
            <tr>
              <th>Assets, Liabilities, and Capital - Dollar Figures in USD</th>
              <th style={{ textAlign: 'right' }}>
                {view === 'Annual' ? 'Top Annual Shift%' : 'Top Quarterly Shift%'}
              </th>
              {quarters.map((quarter, index) => (
                <th key={index} style={{ textAlign: 'right' }}>{quarter}</th>
              ))}
              
            </tr>
          </thead>
            <tbody>
            {rowData.map((row, index) => (
                <TableRow 
                    row={row} 
                    expandedRows={expandedRows} 
                    toggleRow={toggleRow} 
                    setActiveGraphRow={setActiveGraphRow} 
                    activeGraphRow={activeGraphRow}
                    index={index}
                    hoveredRowIndex={hoveredRowIndex}
                    setHoveredRowIndex={setHoveredRowIndex}
                    view={view}
                />
            ))}
          </tbody>
        </table>
      </div>
    </div>
);
}
export default BalanceSheet;


