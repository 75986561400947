import React, { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import DemographicSearch from './DemographicSearch';
import DemographicsTable from './DemographicsTable';
import './Demographics.css';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDQbgwaEreT2K-xl2KejUiWBb-0lMuA2g8';

function Demographics({cert}) {
  const [selectedCert, setSelectedCert] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [error, setError] = useState(null);

  const handleCertChange = (cert) => {
    setSelectedCert(cert);
  };

  useEffect(() => {
    async function fetchBankDetails() {
      if (!selectedCert) return;

      const url = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A${selectedCert}&fields=ACTIVE%2CBKCLASS%2CCALLFORM%2CCB%2CCBSA%2CCERT%2CCITY%2CEFFDATE%2CESTYMD%2CFDICDBS%2CFDICDBSDESC%2CFDICSUPV%2CFDICSUPVDESC%2CFED%2CFEDDESC%2CFLDOFF%2CINSAGNT1%2CINSDATE%2CMUTUAL%2CNAME%2CNAMEFULL%2CNAMEHCR%2COCCDIST%2COCCDISTDESC%2COFFDOM%2COFFFOR%2COFFOA%2COTSREGNM%2CPARCERT%2CQBPRCOMLDESC%2CREGAGNT%2CREPDTE%2CRSSDHCR%2CRSSDID%2CSPECGRP%2CSPECGRPDESC%2CSTALP%2CSTCNTY%2CSUBCHAPS%2CTRUST%2C%2CZIP&sort_by=REPDTE&sort_order=DESC&offset=0&format=json&download=false&filename=data_file`;

      try {
        const response = await fetch(url);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setBankDetails(data.data[0].data || {});
        setError(null); // Reset error state in case of success
      } catch (error) {
        setError(error);
      }
    }

    fetchBankDetails();
  }, [selectedCert]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const isScriptLoaded = window.google && window.google.maps;

  return (
    <div className="demographics-container" >
      
     {/* 
      <div className="search-bar-container" style={{ 
          position: 'absolute', 
          top: '-50px',  // Adjust this to control the vertical position
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          zIndex: 1000  // Ensuring the search bar is above other elements
      }}>
      </div> */}

      {/* Table in the middle */}
      <div className="table-container" >
        {
          isScriptLoaded
          ? <DemographicsTable selectedCert={cert} bankDetails={bankDetails} googleApiKey={GOOGLE_MAPS_API_KEY} />
          : (
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
              <DemographicsTable selectedCert={cert} bankDetails={bankDetails} googleApiKey={GOOGLE_MAPS_API_KEY} />
            </LoadScript>
          )
        }
      </div>

      {/* Controls (assuming you have some) */}
      {bankDetails && (
        <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* ... Your controls here ... */}
        </div>
      )}
    </div>
  );
}

export default Demographics;
