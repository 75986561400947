import React from 'react';
import { Line } from 'react-chartjs-2';

const PercentageLineGraph = ({ data }) => {

  const formatDataValue = (val) => {
    if (typeof val === 'string') {
      return parseFloat(val.replace(/[$,]/g, ''));
    }
    return val;  // If not a string, return the value as is
  };
  console.log('Dates:', data.map(d => d.date));

  const viewMode = data.every(d => {
    const month = parseInt(d.date.substring(4, 6), 10);
    return month === 12;
  }) ? "annual" : "quarterly";
  
  const formatLabel = (dateStr, viewMode) => {
    const year = dateStr.substring(0, 4);
    const month = parseInt(dateStr.substring(4, 6), 10); // Extract month and convert to number

    if (viewMode === "annual") {
      return `${year.slice(-2)}`;  // Just return the last two digits of the year for annual view
    }
  
    let quarter;
    if (month <= 3) {
      quarter = 'Q1';
    } else if (month <= 6) {
      quarter = 'Q2';
    } else if (month <= 9) {
      quarter = 'Q3';
    } else {
      quarter = 'Q4';
    }
  
    return `${year.slice(-2)} ${quarter}`; // Use last two digits of the year followed by the quarter
};

  

  
  // Determine view mode based on if all dates end with Q4

 

  const formatValue = (val, suffix) => {
    let str = val.toFixed(3); // Get the string representation with up to 3 decimal places
    
    // Remove leading zero if it's in the format "0.xxx"
    if (str.startsWith("0.")) {
        str = str.slice(1);
    }
    
    // Remove unnecessary trailing zeroes
    while (str.includes('.') && (str.endsWith('0') || str.endsWith('.'))) {
        str = str.slice(0, -1);
    }

    return str + suffix;
};

  
const toShortFormat = (num) => {
    if (num === 0) return "0%"; // Handle the zero point

    const absoluteValue = Math.abs(num); // Get the absolute value to handle negative numbers

    // Define the appropriate labels and divisors for each range
    let divisor = 1;
    let label = "%"; // Default label for percentages less than 1,000

    if (absoluteValue < 1e3) { // Less than 1,000
        // No change needed, use the default label and divisor
    } else if (absoluteValue < 1e6) { // 1,000 or more, but less than 1 Million
        label = 'K%'; // Thousands
        divisor = 1e3;
    } else if (absoluteValue < 1e9) { // 1 Million or more, but less than 1 Billion
        label = 'M%'; // Millions
        divisor = 1e6;
    } else if (absoluteValue < 1e12) { // 1 Billion or more, but less than 1 Trillion
        label = 'B%'; // Billions
        divisor = 1e9;
    } else { // 1 Trillion or more
        label = 'T%'; // Trillions
        divisor = 1e12;
    }

    // Convert the number and add the appropriate label
    return (num / divisor).toFixed(2) + label;
};


 
  const chartData = {
    labels: data.map(d => formatLabel(d.date, viewMode)).reverse(),
    datasets: [{
      data: data.map(d => formatDataValue(d.value)).reverse(),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  return (
    <Line 
      data={chartData} 
      options={{
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            type: 'category',
            ticks: {
              font: {
                size: 10
              }
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
              font: {
                size: 10
              },
              callback: function(value) {
                return toShortFormat(value);
              }
            }
          }
        },
        maintainAspectRatio: false
      }}
    />
  );
};

export default PercentageLineGraph;

