import React from 'react';

function ChatCSV() {
    return (
        <iframe 
            src="https://bankrchat.herokuapp.com" 
            width="100%" 
            height = "600px"
            style={{border: "none"}}
            title="My Heroku App">
        </iframe>
    );
}

export default ChatCSV;
