import React, { useState, useEffect } from 'react';
import './DemographicSearch.css';

function DemographicSearch({ onCertChange }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);



  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?filters=ACTIVE%3A1&search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=15&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    return data.data;
  }

  async function handleSearch(event) {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 1) {
      const results = await searchAPI(query);
      setResults(results);
    } else {
      setResults([]);
    }
  }

  function handleResultClick(name, cert) {
    setSearchTerm(name);
    setResults([]);
    onCertChange(cert); // Here we call the passed in prop function with the selected cert
  }

  useEffect(() => {
    async function fetchRandomBank() {
        try {
          const countResponse = await fetch('https://www.bankr.ai/api/commercial-re/');
          const countData = await countResponse.json();
          const recordsPerPage = 25; 
          const totalPages = Math.ceil(countData.count / recordsPerPage);
          const randomPage = Math.floor(Math.random() * totalPages) + 1;
    
          const response = await fetch(`https://www.bankr.ai/api/commercial-re/?page=${randomPage}`);
          const data = await response.json();
          const randomBankData = data.results[Math.floor(Math.random() * data.results.length)];
          const cert = randomBankData.CERT;
    
          onCertChange(cert);
    
        } catch (e) {
          console.error(e);
        }
      }

    setSearchTerm('');
    setResults([]);
    fetchRandomBank(); // Fetch a random bank when the component mounts
  }, []);


  return (
    <div className="app-container-main-dem">
      <div className="search-container-main-dem">
        <input
          type="text"
          placeholder="FDIC Bank Demographic Information"
          value={searchTerm}
          onChange={handleSearch}
        />
        <button onClick={() => { if (results.length > 0) setResults([]); }}>
          &#128269;
        </button>
      </div>
      <div className="results-container-main-dem">
        {results.map((result, index) => {
          const bankNameBeforeComma = result.data.NAME.split(',')[0].trim();
          const backgroundColor = index % 2 === 0 ? 'white' : 'lightgrey';

          return (
            <div
              className="mytext-main-dem"
              key={result.data.ID}
              onClick={() => handleResultClick(result.data.NAME, result.data.ID)}
              style={{
                display: 'block',
                cursor: 'pointer',
                padding: '5px 0',
                backgroundColor: backgroundColor,
              }}
            >
              {bankNameBeforeComma} ID:({result.data.ID})
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DemographicSearch;
