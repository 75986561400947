import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Categories from '../components/Categories'
import { useDispatch, useSelector } from 'react-redux'
import { listCategories } from '../actions/categoryActions'
import Message from '../components/Message'

function HomeScreen() {

  const dispatch = useDispatch();
  const categoryList = useSelector(state => state.categoryList);
  const { error, categories } = categoryList

  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch]);

  return (
    <div>
      <h1>Bank Call Report Data</h1>
      {error ? <Message variant='danger'>{error}</Message>
        :
        <Row>
          {categories.map(category => (
            <Col key={category._id} sm={12} md={6} lg={4} xl={3}>
              <Categories categories={category} />
            </Col>
          ))}
        </Row>
      }
    </div>
  )
}

export default HomeScreen
