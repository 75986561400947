import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './AutoLoansTable.css';
import AutoSearch from './AutoSearch.js';

const AutoLoansTable = ({ style }) => {
  const currentDate = new Date();
  
  
  function getDefaultQuarter() {
    let currentYear = currentDate.getFullYear();
    const month = currentDate.getMonth();

    let defaultQuarter;

    if (month < 3) {  // January, February, March
        defaultQuarter = '0930'; // Q3 of the previous year
        currentYear--;
    } else if (month < 6) {  // April, May, June
        defaultQuarter = '1231'; // Q4 of the previous year
        currentYear--;
    } else if (month < 9) {  // July, August, September
        defaultQuarter = '0331'; // Q1 of the current year
    } else {  // October, November, December
        defaultQuarter = '0630'; // Q2 of the current year
    }

    const endOfSelectedQuarter = new Date(currentYear, parseInt(defaultQuarter.substring(0, 2)) - 1, parseInt(defaultQuarter.substring(2, 4)));
    if ((currentDate - endOfSelectedQuarter) / (1000 * 60 * 60 * 24) < 70) {
        if (defaultQuarter === '0331') {
            defaultQuarter = '1231';
            currentYear--;
        } else if (defaultQuarter === '0630') {
            defaultQuarter = '0331';
        } else if (defaultQuarter === '0930') {
            defaultQuarter = '0630';
        } else if (defaultQuarter === '1231') {
            defaultQuarter = '0930';
        }
    }

    return { year: currentYear, quarter: defaultQuarter };
}


  const { year, quarter } = getDefaultQuarter();
  
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const years = [];
  for (let y = currentDate.getFullYear(); y >= 1998; y--) {
    years.push({ value: y, label: y.toString() });
  }

  const quarters = [
    { value: '0331', label: 'Q1' },
    { value: '0630', label: 'Q2' },
    { value: '0930', label: 'Q3' },
    { value: '1231', label: 'Q4' },
  ];

  const [selectedYear, setSelectedYear] = useState({ value: year, label: year.toString() });
  const [selectedQuarter, setSelectedQuarter] = useState({ value: quarter, label: quarters.find(q => q.value === quarter).label });
  const columns = [
    { "id": "NAME", "title": "Name", "definition": "" },
    { "id": "ASSET", "title": "Total Assets", "definition": "Total Assets" },
    { "id": "EQTOT", "title": "Total Equity", "definition": "Total Equity" },
    { "id": "LNAUTO", "title": "Auto Loans", "definition": "( YTD, $ ) Auto loans to Individuals for household, family and other personal expenditures on a consolidated basis. prior to March 2011, this item was included in other consumer loans (LNCONOTh)" },
    { "id": "AUTOLOAN_EQ_PERC","title": "Auto Loans/Equity %","definition": "The percentage calculation of auto loans over total equity."},
    { "id": "LNAUTOR", "title": "Auto Loans/Total Loans%", "definition": "( YTD, % ) Auto loans to Individuals for household, family and other personal expenditures on a consolidated basis. prior to March 2011, this item was included in other consumer loans (LNCONOTh)" },
    { "id": "CRAUTO", "title": "Auto Loans Recoveries", "definition": "( YTD, $ ) Recoveries of loans to Individuals for household, family and other personal expenditures: auto loans credited to the allowance for loan and lease losses. Quarterly Variable: CARTON" },
    { "id": "DRAUTO", "title": "Auto Loans Charge-Offs", "definition": "( YTD, $ ) Charged-off loans to Individuals for household, family, and other personal expenditures: Auto loans debited to the allowance for loan and lease losses. Quarterly variable: DRAUTOQ" },
    { "id": "DRAUTOR", "title": "Auto Loans Charge-Offs Ratio", "definition": "( YTD, % ) Charged-off loans to Individuals for household, family, and other personal expenditures: Auto loans debited to the allowance for loan and lease losses. Quarterly variable: DRAUTOQ" },
    { "id": "NAAUTO", "title": "Non-accrual Auto Loans", "definition": "( YTD, $ ) non-accrual auto loans to Individuals for household, family and other personal expenditures. Available as of March 2011" },
    { "id": "NAAUTOR", "title": "Non-accrual Auto Loans Ratio", "definition": "( YTD, % ) non-accrual auto loans to Individuals for household, family and other personal expenditures. Available as of March 2011" },
    { "id": "NTAUTO", "title": "Auto Loans - Net Charge Offs", "definition": "( YTD, $ ) Net charged-off loans to Individuals for household, family and other personal expenditures: auto loans debited to the allowance for loan and lease losses. Quarterly Variable: NTAUTO" },
    { "id": "P3AUTO", "title": "30-89 Days P/D Auto Loans", "definition": "( YTD, $ ) Auto loans to Individuals for household, family and other personal expenditures past due 30 through 89 days and still accruing interest. available as of March 2011" },
    { "id": "P3AUTOR", "title": "30-89 Days P/D Auto Loans Ratio", "definition": "( YTD, % ) Auto loans to Individuals for household, family and other personal expenditures past due 30 through 89 days and still accruing interest. available as of March 2011" },
    { "id": "P9AUTO", "title": "90 + Days P/D Auto Loans", "definition": "( YTD, $ ) Auto loans to Individuals for household, family and other personal expenditures past due 90 days or more and still accruing interest. available as of March 2011" },
    { "id": "P9AUTOR", "title": "90 + Days P/D Auto Loans Ratio", "definition": "( YTD, % ) Auto loans to Individuals for household, family and other personal expenditures past due 90 days or more and still accruing interest. available as of March 2011" }
    ]
;
const fetchedREPDTE = `${selectedYear.value}${selectedQuarter.value}`;

  useEffect(() => {
    const fieldIds = columns.map(column => column.id).join('%2C');
    const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=REPDTE%3A${fetchedREPDTE}&fields=${fieldIds}%2CCERT&sort_by=LNAUTO&sort_order=DESC&limit=10000&format=json&download=false&filename=data_file`;
    
    axios.get(apiUrl)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          setData(response.data.data);
          setErrorMessage(''); // Clear previous error messages
        } else if (response.data && response.data.meta && response.data.meta.total === 0) {
          setErrorMessage("The data for the selected quarter isn't available, public data for an FDIC bank becomes available 60 days after the quarter ends");
          setData([]); // Clear old data
        }
      })
      .catch(error => {
        console.error('Error fetching the auto loans data:', error);
      });
  }, [selectedYear, selectedQuarter]);

  const PAGE_SIZE = 25;
  const [currentPage, setCurrentPage] = useState(0);

  const numberOfPages = Math.ceil(data.length / PAGE_SIZE);

  const paginatedData = data.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

  const formatDollarValue = (value) => {
    const trueValue = Math.round(value * 1000);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(trueValue);
    };

  const formatRatioValue = (value) => {
    return parseFloat(value).toFixed(2)+"%";
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  

  return (
    <div style={style} className="auto-loans-container">
      <h2>Auto Loans Data for {selectedYear.label} - {selectedQuarter.label}</h2>
      
      {errorMessage && <div className="error-message">{errorMessage}</div>}
  
      <div className="dropdown-container-auto">
        <label className="dropdown-label-auto">Select a Year:</label>
        <Select 
            className="custom-dropdown-auto"
            options={years} 
            value={selectedYear}
            onChange={value => setSelectedYear(value)}
            placeholder="Select Year"
        />

        <label className="dropdown-label-auto">Select a Quarter:</label>
        <Select 
            className="custom-dropdown-auto"
            options={quarters} 
            value={selectedQuarter}
            onChange={value => setSelectedQuarter(value)}
            placeholder="Select Quarter"
        />
    </div>

      {!errorMessage && (
        <>
          <div className="table-container">
          <table className="table table-hover custom-table-auto custom-table-auto--first">
            <thead>
            <tr>
                {columns.map((col, idx) => (
              <td key={col.id} className={idx === 0 ? 'sticky-column' : ''} title={col.definition}>
                <div className="name-search-wrapper">
                    <span className="name-text">{col.title}</span>
                    {col.id === "NAME" && <AutoSearch REPDTE={fetchedREPDTE} />} 
                </div>
            </td>
                ))}
            </tr>
            </thead>
              <tbody>
                {paginatedData.map(item => (
                    <tr key={item.data.CERT}>
                    {columns.map((col, idx) => (
                    <td key={col.id} className={idx === 0 ? 'sticky-column' : ''}>
                        {col.id === "NAME"
                        ? toTitleCase(item.data[col.id])
                        : (col.id.charAt(col.id.length - 1) === "R"
                            ? formatRatioValue(item.data[col.id]) 
                            : (col.id === "AUTOLOAN_EQ_PERC"
                                ? formatRatioValue((item.data["LNAUTO"] / (item.data["EQTOT"] || 1)) * 100)
                                : formatDollarValue(item.data[col.id])
                                )
                            )
                        }
                    </td>
                    ))}
                    </tr>
                ))}
            </tbody>
            </table>
          </div>
          <div className="pagination-controls">
            <button 
              onClick={() => setCurrentPage(page => Math.max(0, page - 1))}
              disabled={currentPage === 0}
              className= "btn btn-primary btn-sm"
              style={{ fontSize: '0.7rem', paddingTop: '4px',paddingBottom: '4px', backgroundColor:'royalblue'}} 
            >
              Previous
            </button>
            <span>Page {currentPage + 1} of {numberOfPages}</span>
            <button 
              onClick={() => setCurrentPage(page => Math.min(numberOfPages - 1, page + 1))}
              disabled={currentPage === numberOfPages - 1}
              className= "btn btn-primary btn-sm"
              style={{ fontSize: '0.7rem', paddingTop: '4px',paddingBottom: '4px',backgroundColor:'royalblue' }} 
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
);

};

export default AutoLoansTable;
