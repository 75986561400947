import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS,Title,Legend } from 'chart.js';

const CREGraphs = ({ CERT}) => {
  const [EquitychartData, setEquityChartData] = useState({});
  const [AssetchartData, setAssetChartData] = useState({});
  const [ConstructionchartData, setConstructionChartData] = useState({});
  const [CommercialchartData, setCommercialChartData] = useState({});
  const [MultifamilychartData, setMultifamilyChartData] = useState({});
  const [OwnerchartData, setOwnerChartData] = useState({});
  const [NonOwnerchartData, setNonOwnerChartData] = useState({});

  ChartJS.register(Title,Legend);
  
  const formatYAxisValue = (value) => {
    // Take the absolute value for calculation
    let number = Math.abs(value);
    let suffix = '';
  
    if (number >= 1e9) {
      number /= 1e9;
      suffix = 'B';
    } else if (number >= 1e6) {
      number /= 1e6;
      suffix = 'M';
    } else if (number >= 1e3) {
      number /= 1e3;
      suffix = 'K';
    }
  
    // Add back the sign of the original value
    const sign = value < 0 ? '-' : '';
    return sign + '$' + number.toFixed(1) + suffix;
  };
  
  useEffect(() => {
    async function fetchEquityData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=EQTOT%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.EQTOT * 1000);
  
        const EquitychartData = {
          labels: labels,
          datasets: [{
            label: 'Total Equity',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", EquitychartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setEquityChartData(EquitychartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchEquityData();
    }
  }, [CERT]);
  

  const Equityoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Equity', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  useEffect(() => {
    async function fetchAssetData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=ASSET%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.ASSET * 1000);
  
        const AssetchartData = {
          labels: labels,
          datasets: [{
            label: 'Total Assets',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
    
        setAssetChartData(AssetchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchAssetData();
    }
  }, [CERT]);
  

  const Assetoptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Total Assets', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      
      
      
  };

    
  useEffect(() => {
    async function fetchConstructionData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNRECONS%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNRECONS* 1000);
  
        const ConstructionchartData = {
          labels: labels,
          datasets: [{
            label: 'Construction and Land Dev RE LN',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", ConstructionchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setConstructionChartData(ConstructionchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchConstructionData();
    }
  }, [CERT]);
  

  const Constructionoptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'C&L Dev RE LN', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  useEffect(() => {
    async function fetchMultifamilyData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNREMULT%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNREMULT* 1000);
  
        const MultifamilychartData = {
          labels: labels,
          datasets: [{
            label: 'Multifamily RE Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", MultifamilychartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setMultifamilyChartData(MultifamilychartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT ) {
      fetchMultifamilyData();
    }
  }, [CERT]);
  

  const Multifamilyoptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Multifamily RE Loan', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

useEffect(() => {
    async function fetchCommercialData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNRENRES%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNRENRES* 1000);
  
        const CommercialchartData = {
          labels: labels,
          datasets: [{
            label: 'Commercial RE Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", ConstructionchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setCommercialChartData(CommercialchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchCommercialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CERT]);
  

  const Commercialoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'CRE Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

//

useEffect(() => {
    async function fetchOwnerData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNRENROW%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNRENROW* 1000);
  
        const OwnerchartData = {
          labels: labels,
          datasets: [{
            label: 'Owner-Occ CRE Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", OwnerchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setOwnerChartData(OwnerchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchOwnerData();
    }
  }, [CERT]);
  

  const Owneroptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Owner-Occ CRE Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  //

  useEffect(() => {
    async function fetchNonOwnerData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNRENROT%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNRENROT* 1000);
  
        const NonOwnerchartData = {
          labels: labels,
          datasets: [{
            label: 'Non-Owner-Occ CRE Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", NonOwnerchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setNonOwnerChartData(NonOwnerchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchNonOwnerData();
    }
  }, [CERT]);
  

  const NonOwneroptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Non-Owner-Occ CRE Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  const [ForeclosedchartData, setForeclosedChartData] = useState({});

useEffect(() => {
    async function fetchForeclosedData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=ORENRES%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.ORENRES* 1000);
  
        const ForeclosedchartData = {
          labels: labels,
          datasets: [{
            label: 'Foreclosed CRE',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", ForeclosedchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setForeclosedChartData(ForeclosedchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchForeclosedData();
    }
  }, [CERT]);
  

  const Foreclosedoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Foreclosed CRE', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

const [PastDue3090chartData, setPastDue3090ChartData] = useState({});

useEffect(() => {
    async function fetchPastDue3090Data() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=P3RENRES%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.P3RENRES* 1000);
  
        const PastDue3090chartData = {
          labels: labels,
          datasets: [{
            label: 'Due 30-90D CRE LN',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", PastDue3090chartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setPastDue3090ChartData(PastDue3090chartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchPastDue3090Data();
    }
  }, [CERT]);
  

  const PastDue3090options = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Due 30-90D CRE LN', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

const [PastDue90PluschartData, setPastDue90PlusChartData] = useState({});

useEffect(() => {
    async function fetchPastDue90PlusData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=P9RENRES%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.P9RENRES* 1000);
  
        const PastDue90PluschartData = {
          labels: labels,
          datasets: [{
            label: 'Due 90D+ CRE LN',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", PastDue90PluschartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setPastDue90PlusChartData(PastDue90PluschartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT) {
      fetchPastDue90PlusData();
    }
  }, [CERT]);
  

  const PastDue90Plusoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Due 90D+ CRE LN', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  return (
    <div>
      {EquitychartData && EquitychartData.labels ? 
        <div><Bar data={EquitychartData} options={Equityoptions} /></div>
        : <p>Loading Equity chart...</p>
      }
      {AssetchartData && AssetchartData.labels ? 
        <div><Bar data={AssetchartData} options={Assetoptions} /></div>
        : <p>Loading Asset Loans chart...</p>
      }
      {CommercialchartData && CommercialchartData.labels ? 
        <div><Bar data={CommercialchartData} options={Commercialoptions} /></div>
        : <p>Loading Commercial RE Loans chart...</p>
      }
      {ConstructionchartData && ConstructionchartData.labels ? 
        <div><Bar data={ConstructionchartData} options={Constructionoptions} /></div>
        : <p>Loading Construction and Land Dev RE Loans chart...</p>
      }
      {MultifamilychartData && MultifamilychartData.labels ? 
        <div><Bar data={MultifamilychartData} options={Multifamilyoptions} /></div>
        : <p>Loading Multifamilys chart...</p>
      }
      {OwnerchartData && OwnerchartData.labels ? 
        <div><Bar data={OwnerchartData} options={Owneroptions} /></div>
        : <p>Loading Owner Occpied CRE LN chart...</p>
      }
      {NonOwnerchartData && NonOwnerchartData.labels ? 
        <div><Bar data={NonOwnerchartData} options={NonOwneroptions} /></div>
        : <p>Loading Non Owner Occpied CRE LN chart...</p>
      }
      {ForeclosedchartData && ForeclosedchartData.labels ? 
        <div><Bar data={ForeclosedchartData} options={Foreclosedoptions} /></div>
        : <p>Loading Foreclosed CRE chart...</p>
      }
      {PastDue3090chartData && PastDue3090chartData.labels ? 
        <div><Bar data={PastDue3090chartData} options={PastDue3090options} /></div>
        : <p>Loading PastDue3090 CRE chart...</p>
      }

    {PastDue90PluschartData && PastDue90PluschartData.labels ? 
        <div><Bar data={PastDue90PluschartData} options={PastDue90Plusoptions} /></div>
        : <p>Loading PastDue90Plus CRE chart...</p>
    }

    
    </div>
  );
  
  
};

export default CREGraphs;
