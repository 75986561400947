import React, {useState} from 'react';
import './Panels.css'
import Loader from '../Loader'
import CREGraphs from './CREGraphs'; // Import the CREGraphs component
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const CategoryPanel = ({ onCategorySelect, selectedBanksData,setIsLoading,onCategoryChange }) => {

  const categories = [
    { name: 'Commercial Real Estate', imageUrl: 'https://bankrai-bucket.s3.amazonaws.com/4.png' },
    /*{ name: 'Unrealized Gains and Losses', imageUrl: 'https://bankrai-bucket.s3.amazonaws.com/6.png' },*/
    // Add more categories as needed
  ];

  console.log('This should be the Selected Data:', selectedBanksData)
  const handleCategoryClick = async (categoryName) => { // Declare the function as async
    if (categoryName === 'Commercial Real Estate') {
          // Construct the URL for the API call
          // Modify this as per your actual API and data requirements
          setIsLoading(true); // Start loading
          const CREMapping = [
            { "id": "ASSET", "title": "Total Assets", "definition": "Total Assets" },
            { "id": "EQTOT", "title": "Total Equity", "definition": "Total Equity" },
            // Loans for CRE - Overview
            { "id": "LNRENRES", "title": "Commercial Real Estate Loans" },
            { "id": "LNRENRES/EQTOT", "title": "Commercial RE Loans / Equity" },
            { "id": "LNRECONS", "title": "Construction and Land Development Real Estate Loans" },
            { "id": "LNRECONS/EQTOT", "title": "Construction Loans / Equity" },
            { "id": "LNREMULT", "title": "Multifamily Residential Real Estate Loans" },
            { "id": "LNRERES", "title": "1-4 Family Residential Real Estate Loans" },
            { "id": "LNREAG", "title": "Farmland Real Estate Loans" },
            { "id": "LNCOMRE", "title": "Commercial Real Estate Loans not Secured by Real Estate" },
            { "id": "LNRENROW", "title": "Commercial Real Estate Owner-Occupied" },
            { "id": "LNRENROT", "title": "Commercial Real Estate Other Non-Owner-Occupied" },
            { "id": "ORENRES", "title": "Foreclosed / Repossessed CRE" },
            { "id": "RATAIOLOANS", "title": "Non-Performing Real Estate Loans to Total Real Estate Loans" },
            { "id": "P3RENRES", "title": "Commercial Real Estate Past Due 30 - 89 Days" },	
            { "id": "P9RENRES", "title": "Commercial Real Estate Past Due 90+ Days" },	
            { "id": "P3RECONSR", "title": "Construction and Land Development, Past Due 30 - 89 Days" },
            { "id": "P3REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 30 - 89 Days" },
            { "id": "P9RECONS", "title": "Construction and Land Development, Past Due 90+ Days" },
            { "id": "P9REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 90+ Days" }
        
        ];
          
        try {
          const response = await fetch('/api/generate_insight/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ selectedBanksData, financialMapping: CREMapping }),
              
          });

          if (!response.ok) {
              // If the response status code is not OK, handle it as an error
              const contentType = response.headers.get("content-type");
              if (contentType && contentType.indexOf("application/json") !== -1) {
                  // If response is JSON, parse it and throw an error
                  const errorData = await response.json();
                  throw new Error(errorData.error || 'Server returned an error');
              } else {
                  // If response is not JSON, just read it as text
                  const errorText = await response.text();
                  throw new Error(errorText || 'Server returned an error');
              }
          }

          const responseData = await response.json();
          if (responseData && responseData.result) {
              onCategorySelect(responseData.result);
              setIsLoading(false);
          } else {
              console.error('Invalid response format:', responseData);
          }
      } catch (error) {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error:', error);
      }
      onCategoryChange(categoryName);

  }
};


  return (
    <div className="category-panel">
        {categories.map((category, index) => (
            <button key={index} className="category-button" onClick={() => handleCategoryClick(category.name)}>
                <img src={category.imageUrl} alt={category.name} className="category-icon" />
                <span className="category-name">{category.name}</span>
            </button>
        ))}
    </div>
  );
};


const ReportPanel = ({ reportData, loading }) => {

  if (loading) {
    return <Loader />; // Show the loader while loading
  }

  if (!reportData) {
    return <div>Click a button to Generate a Banking Report</div>;
  }

  const formattedText = reportData.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="report-panel">
      <h5>Commercial Real Estate Report</h5>
      <div>{formattedText}</div>
    </div>
  );
};


const GraphPanel = ({ cert, selectedCategory }) => {
  return (
      <div>
          {selectedCategory === 'Commercial Real Estate' && <CREGraphs CERT={cert} />}
      </div>
  );
};


export { CategoryPanel, ReportPanel, GraphPanel };