import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraph = ({ data }) => {

  const formatDataValue = (val) => {
    if (typeof val === 'string') {
      return parseFloat(val.replace(/[$,]/g, ''));
    }
    return val;  // If not a string, return the value as is
  };
  console.log('Dates:', data.map(d => d.date));

  const viewMode = data.every(d => {
    const month = parseInt(d.date.substring(4, 6), 10);
    return month === 12;
  }) ? "annual" : "quarterly";
  
  const formatLabel = (dateStr, viewMode) => {
    const year = dateStr.substring(0, 4);
    const month = parseInt(dateStr.substring(4, 6), 10); // Extract month and convert to number

    if (viewMode === "annual") {
      return `${year.slice(-2)}`;  // Just return the last two digits of the year for annual view
    }
  
    let quarter;
    if (month <= 3) {
      quarter = 'Q1';
    } else if (month <= 6) {
      quarter = 'Q2';
    } else if (month <= 9) {
      quarter = 'Q3';
    } else {
      quarter = 'Q4';
    }
  
    return `${year.slice(-2)} ${quarter}`; // Use last two digits of the year followed by the quarter
};

  

  
  // Determine view mode based on if all dates end with Q4

 

  const formatValue = (val, suffix) => {
    let str = val.toFixed(3); // Get the string representation with up to 3 decimal places
    
    // Remove leading zero if it's in the format "0.xxx"
    if (str.startsWith("0.")) {
        str = str.slice(1);
    }
    
    // Remove unnecessary trailing zeroes
    while (str.includes('.') && (str.endsWith('0') || str.endsWith('.'))) {
        str = str.slice(0, -1);
    }

    return str + suffix;
};

  
const toShortFormat = (num) => {
  if (num === 0) return "0"; // Handle the zero point

  const value = num * 1000; // Convert to actual value considering your data scale

  if (Math.abs(value) < 1e6) { 
      return (value / 1e3).toFixed(0) + 'K'; // Convert to thousands up to one decimal place
  } else if (Math.abs(value) < 1e9) { 
      return (value / 1e6).toFixed(0) + 'M'; // Convert to millions up to one decimal place
  } else if (Math.abs(value) < 1e12) { 
      return (value / 1e9).toFixed(0) + 'B'; // Convert to billions up to one decimal place
  } else {
      return (value / 1e12).toFixed(0) + 'T'; // Convert to trillions up to one decimal place
  }
};

 
  const chartData = {
    labels: data.map(d => formatLabel(d.date, viewMode)).reverse(),
    datasets: [{
      data: data.map(d => formatDataValue(d.value)).reverse(),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  return (
    <Line 
      data={chartData} 
      options={{
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            type: 'category',
            ticks: {
              font: {
                size: 10
              }
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
              font: {
                size: 10
              },
              callback: function(value) {
                return toShortFormat(value);
              }
            }
          }
        },
        maintainAspectRatio: false
      }}
    />
  );
};

export default LineGraph;


