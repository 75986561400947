import React, { useState, useEffect } from 'react';
import BankrSearch from './BankrSearch';
import { CategoryPanel, ReportPanel, GraphPanel } from './Panels';
import './BankrAI.css';

function BankrAI() {
  const [selectedBanksData, setSelectedBanksData] = useState([]);
  const [isSearchComplete, setIsSearchComplete] = useState(false);
  const [reportData, setReportData] = useState(null); 
  const [isLoading, setIsLoading] = useState(false); 
  const [selectedCategory, setSelectedCategory] = useState('');
  const [cert, setCert] = useState(null);

  useEffect(() => {
    if (selectedBanksData.length > 0 && selectedBanksData[0].cert) {
      setCert(selectedBanksData[0].cert);
    }
  }, [selectedBanksData]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const onSelectionComplete = (selectedBanks) => {
    console.log("Received Selected Banks in Parent: ", selectedBanks); 
    setSelectedBanksData(selectedBanks);
    setIsSearchComplete(true);
  };

  const handleReportData = async (data) => {
    setIsLoading(true);
    // Simulate fetching report data (Replace this with your actual fetching logic)
    await new Promise(resolve => setTimeout(resolve, 2000)); 
    setReportData(data);
    setIsLoading(false);
  };

  const getBankNames = () => {
    return selectedBanksData
      .map(bank => bank.name)
      .join(', ');
  };

  return (
    <div className="bankrapp">
      {!isSearchComplete ? (
        <BankrSearch onSelectionComplete={onSelectionComplete} />
      ) : (
        <>
          <div className="header-container">
            <h2>Generate a Report for: {getBankNames()}</h2>
          </div>
          <div className="panel-container">
            <div className="left-panel">
              <CategoryPanel    
                onCategorySelect={handleReportData} 
                selectedBanksData={selectedBanksData} 
                setIsLoading={setIsLoading} 
                onCategoryChange={handleCategoryChange} 
              />
            </div>
            <div className="center-panel">
              <ReportPanel reportData={reportData} loading={isLoading} />
            </div>
            <div className="right-panel">
              <GraphPanel 
                cert={cert}
                selectedCategory={selectedCategory} 
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BankrAI;
