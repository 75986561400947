import React, { useState, useEffect } from 'react';
import './SearchBar.css'
import { useNavigate , useLocation } from 'react-router-dom';


function SearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const [selectedCert, setSelectedCert] = useState(null);
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [selectedBanks, setSelectedBanks] = useState([]);

 

  useEffect(() => {
    setSearchTerm('');
    setSelectedCert(null);
  }, [location]);


  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?filters=ACTIVE%3A1&search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=15&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    return data.data;

  }

  async function handleSearch(event) {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 1) {
      const results = await searchAPI(query);
      setResults(results);
    } else {
      setResults([]);
    }
  }
  function handleResultClick(name, cert) {
    setSearchTerm(name);
    setResults([]);
    setSelectedCert(cert);
    
  }
  useEffect(() => {
    if (selectedCert) {
      navigate(`/summary`, { state: { bankName: searchTerm, bankId: selectedCert } });
    }
  }, [selectedCert, searchTerm, navigate]);
  
  function handleButtonClick() {
    if (selectedCert) {
      navigate(`/summary`, { state: { bankName: searchTerm, bankId: selectedCert } });
    }
  }
  

  return (
    <div className="app-container-main">
      <div className={`search-container-main${expanded ? ' expanded' : ''}`}>
        <input
          type="text"
          placeholder="FDIC Bank Financials"
          value={searchTerm}
          onChange={handleSearch}
        />
        <button onClick={handleButtonClick}>&#128269;</button>
      </div>
      <div className="results-container-main">
        {results.map((result, index) => {
          const bankNameBeforeComma = result.data.NAME.split(',')[0].trim();
          const backgroundColor = index % 2 === 0 ? 'white' : 'lightgrey';
  
          return (
            <div
              className="mytext-main"
              key={result.data.ID}
              onClick={() => handleResultClick(result.data.NAME, result.data.ID)}
              style={{
                display: 'block',
                cursor: 'pointer',
                padding: '5px 0',
                backgroundColor: backgroundColor,
              }}
            >
              {bankNameBeforeComma} ID:({result.data.ID})
            </div>
          );
        })}
      </div>
    </div>
  );
  
}

export default SearchBar;