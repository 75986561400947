import React, { useState,useEffect } from 'react';
import axios from 'axios';
import './UnrealizedSearch.css';


function UnrealizedSearch(props) {
  const { quarter } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const quarterMapping = {
    '12-31': 'Q4',
    '09-30': 'Q3',
    '06-30': 'Q2',
    '03-31': 'Q1',
};

  function getLast20Quarters(startQuarter) {
    const quarters = [];
    let [year, month] = startQuarter.split('-').map(Number);

    for (let i = 0; i < 20; i++) {
        let day;

        if (month === 3 || month === 12) {
            day = 31;
        } else {
            day = 30;
        }

        const formattedMonth = month.toString().padStart(2, '0');
        quarters.push(`${year}-${formattedMonth}-${day}`);
        console.log(`Generated Quarter: ${year}-${formattedMonth}-${day}`); // Log here

        month -= 3;

        if (month <= 0) {
            month += 12;
            year--;
        }
    }

    return quarters;
}

  
  
  

async function handleBankSearch(name, id) {
  const quarters = getLast20Quarters(quarter);
  const bankDataArray = [];

    try {
      const bankDataURL = `/api/quloss/?CALLYMD=&CERT=${id}&ordering=-CALLYMD`;
      console.log("Fetching Bank Data from URL:", bankDataURL); // Log the URL
      const response = await axios.get(bankDataURL);
      const bankData =response.data.results.slice(0, 20);

      bankDataArray.push(...bankData);
      console.log(`Bank Data:`, bankDataArray);

  

    } catch (error) {
      console.error('Error fetching data for quarter:', error);
    }


  const yyyymmdd = quarters[0].replaceAll('-', ''); // Just using the first quarter's format
  const equityRequestURL = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A${id}&fields=${yyyymmdd}%2CEQ&sort_by=CALLYMD&sort_order=DESC&limit=110&offset=0&agg_limit=0&format=json&download=false&filename=data_file`;
  const securitiesRequestURL = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A${id}&fields=CERT%2C${yyyymmdd}%2CSC%2CASSET&sort_by=CALLYMD&sort_order=DESC&limit=110&offset=0&format=json&download=false&filename=data_file`;
  
  console.log("Fetching Equity Data from URL:", equityRequestURL); // Log the URL
  console.log("Fetching Securities Data from URL:", securitiesRequestURL); // Log the URL
  
  const results = await Promise.all([
    axios.get(equityRequestURL), 
    axios.get(securitiesRequestURL)
  ]);
  
  const equityData = results[0].data.data.map(item => item.data.EQ || 0.00);
  const securitiesData = results[1].data.data.map(item => ({
    SC: item.data.SC || 0.00,
    ASSET: item.data.ASSET || 0.00
}));
  // Process the arrays to create a toast message
  function formatQuarter(date) {
    const [year, month, day] = date.split('-');
    return `${quarterMapping[`${month}-${day}`]} ${year}`;
}

  const headers = quarters.map(q => `<th class="custom-font">${formatQuarter(q)}</th>`).join('');

  const rows = [
      `<tr><td class="sticky-metric-usearch custom-font-us">Unrealized Gains/Losses</td><td class="custom-font-us">${bankDataArray.map(data => formatCurrency(data?.unrealized_gl*1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">Total Equity</td><td class="custom-font-us">${equityData.slice(0, 20).map(EQ => formatCurrency(EQ * 1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">Total Securities</td><td class="custom-font-us">${securitiesData.slice(0, 20).map(entry => formatCurrency(entry.SC * 1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">Total Assets</td><td class="custom-font-us">${securitiesData.slice(0, 20).map(entry => formatCurrency(entry.ASSET * 1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">HTM</td><td class="custom-font-us">${bankDataArray.map(data => formatCurrency((data?.SCHF - data?.SCHA) * 1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">AFS</td><td class="custom-font-us">${bankDataArray.map(data => formatCurrency((data?.SCAF - data?.SCAA) * 1000)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">Unrealized GL/Total Securities%</td><td class="custom-font-us">${bankDataArray.map((data, idx) => formatPercentage(data?.unrealized_gl, securitiesData[idx].SC)).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr><td class="sticky-metric-usearch custom-font-us">Unrealized GL/Equity%</td><td class="custom-font-us">${bankDataArray.map((data, idx) => formatPercentage(data?.unrealized_gl, equityData[idx])).join('</td><td class="custom-font-us">')}</td></tr>`,
      `<tr>
      <td class="sticky-metric-usearch custom-font-us">Unrealized GL/Assets%</td>
          ${bankDataArray.map((data, idx) => {
              const assetValue = securitiesData[idx]?.ASSET;
              return `<td class="custom-font-us">${formatPercentage(data?.unrealized_gl, assetValue)}</td>`;
          }).join('')}
      </tr>`
  


  ].join('');

  const toastMessage = `
  <div style="overflow-x: auto;">
      <table class="table table-hover custom-table-usearch" style="width: 100%; height: 100%;">
          <thead class="custom-font-us">
              <tr>
                  <th class="sticky-metric-usearch custom-font">Metric</th>
                  ${headers}
              </tr>
          </thead>
          <tbody class="custom-font-us">
              ${rows}
          </tbody>
      </table>
  </div>
  `;

  setToastMessage(toastMessage);
  setShowToast(true);
}

const formatPercentage = (numerator, denominator) => {
  if (denominator === 0) return '0.00%';  // Prevent division by zero
  const percentage = (numerator / denominator) * 100;
  const truncatedPercentage = Math.floor(percentage * 100) / 100;  // Truncate to 2 decimal places
  return `${truncatedPercentage.toFixed(2)}%`;  // Ensure two decimal places are always shown
};



  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (highlightedIndex < results.length - 1) {
        setHighlightedIndex(prevIndex => prevIndex + 1);
      } else {
        setHighlightedIndex(0); // Loop back to the first result
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (highlightedIndex > 0) {
        setHighlightedIndex(prevIndex => prevIndex - 1);
      } else {
        setHighlightedIndex(results.length - 1); // Loop back to the last result
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedIndex >= 0) {
        const selectedResult = results[highlightedIndex];
        handleResultClick(selectedResult.data.NAME, selectedResult.data.ID);
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [results, highlightedIndex]);
  

  const handleEscapePress = (event) => {
    if (event.key === "Escape") {
        if (showToast) {
            closeToast();
        } else {
            // Handle any other actions you want when Escape is pressed and the toast is not shown
            // For example, you might want to clear the search term or reset other states
        }
    }
};

useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleEscapePress);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
        document.removeEventListener("keydown", handleEscapePress);
    };
}, [showToast]); // Depend on the showToast state so the effect re-runs when showToast changes




  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=4&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    return data.data;
  }

  async function handleSearch(event) {
    const query = event.target.value;
    setSearchTerm(query);
    setSelectedResult(null); // Reset selectedResult when input changes

    if (query.length > 1) {
        const results = await searchAPI(query);
        setResults(results);
    } else {
        setResults([]);
    }
}


  
  useEffect(() => {
    if (selectedBankId && showToast) {
      handleBankSearch(selectedResult?.data?.NAME || searchTerm, selectedBankId);
    }
  }, [quarter, selectedBankId, selectedResult, searchTerm, showToast]);
  
  

  function formatCurrency(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  
    const formattedParts = formatter.formatToParts(amount);
  
    if (amount < 0) {
      return formattedParts.reduce((acc, part) => {
        if (part.type === 'minusSign') {
          return `(${acc}`;
        } else if (part.type === 'currency' || part.type === 'integer' || part.type === 'decimal' || part.type === 'fraction' || part.type === 'group') {
          return `${acc}${part.value}`;
        }
        return acc;
      }, '') + ')';
    }
  
    return formatter.format(amount);
  }
  
  function handleResultClick(fullName, id, index) {
    const matchingResult = results.find(result => result.data.ID === id);
    setSelectedResult(matchingResult); // Set selectedResult here
    setSearchTerm(fullName);
    setSelectedBankId(id);
    setResults([]);
    setHighlightedIndex(index);
    setToastMessage('Loading...');
    setShowToast(true);
}

  
  

  async function handleClick() {
    if (!searchTerm) {
      setToastMessage('Please type a name first');
      setShowToast(true);
      return;
    }
  
    if (selectedBankId) {
      const newResults = await searchAPI(searchTerm);
      const matchingResult = newResults.find(
        (result) => searchTerm.toLowerCase() === result.data.NAME.toLowerCase()
      );
  
      if (matchingResult) {
        const { NAME, ID } = matchingResult.data;
        await handleBankSearch(NAME, ID, quarter);
      } else {
        setToastMessage('Result not found');
        setShowToast(true);
      }
    } else {
      const newResults = await searchAPI(searchTerm);
      const matchingResult = newResults.find(
        (result) => searchTerm.toLowerCase() === result.data.NAME.toLowerCase()
      );
  
      if (matchingResult) {
        const { NAME, ID } = matchingResult.data;
        await handleBankSearch(NAME, ID, quarter);
      } else {
        setToastMessage('Result not found');
        setShowToast(true);
      }
    }
  }

  function closeToast() {
    setShowToast(false);
    setSearchTerm(''); // Clear the search bar
}



  return (
    <div className="app-container-ugl">
      <div className="search-container-ugl" style={{ position: 'relative' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search for an FDIC bank"
          style={{ 
          border: '1px solid black',
          position: 'relative',
          minWidth: '175px', // Set a fixed width
          padding: '2px', // Adjust padding
      
          }}
        />
        <button onClick={handleClick}>&#128269;</button>

    {showToast && (
    <div
        className="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            minWidth: '1000px',
            maxWidth: '80%',
            zIndex: 1000,
            backgroundColor: 'white',
            color: 'royalblue',
            fontSize: '11px',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
        }}
    >
        <div className="toast-header ugl">
            {/* Check if selectedResult and selectedResult.data are defined before accessing NAME */}
            <strong className="me-auto">{selectedResult && selectedResult.data && selectedResult.data.NAME ? selectedResult.data.NAME.split(',')[0] : 'Bank'}</strong>
            <button
                type="button"
                className="btn-close ms-2 mb-1"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={closeToast}
            >
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div
            className="toast-body ugl"
            dangerouslySetInnerHTML={{ __html: toastMessage }}
            style={{
                overflowWrap: 'break-word',
                overflowY: 'auto',
                maxHeight: '275px',
                padding: '15px'
            }}
        ></div>
    </div>
    )}    


      </div>
    <div className="results-container-ugl" style={
      // Update the results-container style
        {
          fontSize: '11px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          maxWidth: '255px',
          position: 'absolute', // Keep this to position it right below the input field
          backgroundColor: 'white', // Keep this to provide a solid background
          zIndex: 10, // Keep this to ensure it displays above other elements
          boxSizing: 'border-box', // Add this to include border and padding in the width calculation
          marginLeft: '-30px', // Add this to align the suggestions box with the search input
        }
        
    }
    onMouseLeave={() => setHighlightedIndex(-1)}
    >
         {results.map((result, index) => {
          const fullName = result.data.NAME;
          const displayName = fullName.split(',')[0];
          const isHighlighted = index === highlightedIndex;
          return (
            <div
              className={`result-item-ugl ${isHighlighted ? 'highlighted' : ''}`}
              key={result.data.ID}
              onClick={() => handleResultClick(fullName, result.data.ID, index)}  // Pass index to handleResultClick
              onMouseOver={() => setHighlightedIndex(index)} // Update highlightedIndex on mouse over
              style={{ display: 'block', cursor: 'pointer', padding: '5px 0', backgroundColor: isHighlighted ? 'blue' : 'transparent'}}
            >
              {displayName} ID:({result.data.ID})
            </div>
          );
        })}
    </div>
  </div>
  );
  
  
}

export default UnrealizedSearch;

