import React, { useState, useEffect } from 'react';
import './BankrSearch.css'
import {useLocation } from 'react-router-dom';


function BankrSearch({ onSelectionComplete }) { // onSelectionComplete is received as a prop here
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedCert, setSelectedCert] = useState(null);
  const location = useLocation();
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [searchText, setSearchText] = useState('');



  useEffect(() => {
    setSearchTerm('');
    setSelectedCert(null);
  }, [location]);


  const searchAPI = async (query) => {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?filters=ACTIVE%3A1&search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=5&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    console.log("API Response: ", data); // Log the API response
    return data.data;
  };
  

  const handleSearch = async (event) => {
    const query = event.target.value;
    setSearchText(query);

    setSearchTerm(query);
    if (query.length > 1) {
      const apiResults = await searchAPI(query);
      console.log(apiResults); // This will log your API results to the console
      setResults(apiResults);
    } else {
      setResults([]);
    }
  };
  

  const handleResultClick = (name, cert) => {
    if (selectedBanks.length < 3 && !selectedBanks.some(bank => bank.cert === cert)) {
      const newSelectedBanks = [...selectedBanks, { name, cert }];
      setSelectedBanks(newSelectedBanks);
      console.log("Updated Selected Banks: ", newSelectedBanks); // Check the updated state
    }
    setSearchTerm('');
    setResults([]);
  };
  
  const removeBank = (cert) => {
    setSelectedBanks(prevBanks => prevBanks.filter(bank => bank.cert !== cert));
  };

  const handleNextClick = () => {
    // Call the onSelectionComplete function passed from the parent component
    onSelectionComplete(selectedBanks);
    // Set search as completed to change the view
  };

  return (
    <div className="app-container-main-bankr">
    <h1 className="title-bankr">Generate a GPT Banking Report for:</h1>
    <div className="search-container-main-bankr">
      <input
        type="text"
        className="search-input-bankr"
        placeholder="Enter bank name"
        value={searchTerm}
        onChange={handleSearch}
      />
      <span className="search-icon-bankr">&#128269;</span> {/* Replace with SVG/icon if needed */}
    </div>
    {searchText && results.length > 0 && (
      <div className="results-container-main-bankr">
        {results.map((result, index) => (
            <div
            key={result.ID} // Use result.ID here
            onClick={() => handleResultClick(result.data.NAME, result.data.ID)} // Use result.NAME and result.ID here
            >
            <h5>{result.data.NAME}</h5>
            </div>
        ))}
        </div>
    )}

        <div className="selected-banks-container-bankr">
        {selectedBanks.map((bank, index) => (
            <div className="selected-bank-bankr" key={index}>
            <h5>{bank.name}<span>{`\u00A0`}{`\u00A0`}</span></h5>
            <button onClick={() => removeBank(bank.cert)}>X</button>
            </div>
        ))}
        </div>
      {selectedBanks.length > 0 && (
        <button className="next-button-bankr" onClick={handleNextClick}>
          Next
        </button>
      )}
    </div>
  );
  
}

export default BankrSearch;