import React, { useState, useEffect,useRef } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import './DemographicsTable.css';

const mapContainerStyle = {
    width: '100%',
    height: '100%'
};


let addressCache = {};

async function getCoordinates(address, city, state, zip, apiKey) {
    const addressString = `${address}, ${city}, ${state}, ${zip}`;
    
    // Check cache first
    if (addressCache[addressString]) {
        return addressCache[addressString];
    }

    try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressString)}&key=${apiKey}`);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch geocode data. Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.status !== "OK") {
            throw new Error(data.error_message || "Unknown error occurred while fetching geocode data.");
        }

        if (data.results[0] && data.results[0].geometry && data.results[0].geometry.location) {
            const location = data.results[0].geometry.location;
            
            // Cache the result
            addressCache[addressString] = [location.lat, location.lng];
            
            return [location.lat, location.lng];
        }

        return null;
    } catch (error) {
        console.error("Failed to fetch exact coordinates:", error);
        throw error;  // Throwing error to handle it in the caller function.
    }
}




const variableToAPIKeyMap = {
    'Status': 'ACTIVE',
    'Address': 'ADDRESS',
    'Bank Class': 'BKCLASS',
    'FFIEC Call Form Type': 'CALLFORM',
    'FDIC Community Bank': 'CB',
    'Core Based Statistical Area (CBSA)': 'CBSA',
    'Certificate #': 'CERT',
    'City': 'CITY',
    'Last Structure Change Effective Date': 'EFFDATE',
    'Established Date': 'ESTYMD',
    'FDIC Geographic Region Code': 'FDICDBS',
    'FDIC Geographic Region': 'FDICDBSDESC',
    'FDIC Supervisory Region Code': 'FDICSUPV',
    'FDIC Supervisory Region': 'FDICSUPVDESC',
    'Federal Reserve District Code': 'FED',
    'Federal Reserve District': 'FEDDESC',
    'FDIC Field Office Code': 'FLDOFF',
    'Primary Federal Regulator Code': 'INSAGNT1',
    'Date of Deposit Insurance': 'INSDATE',
    'Mutual (Yes/No)': 'MUTUAL',
    'Short Name': 'NAME',
    'Full Name': 'NAMEFULL',
    'Bank Holding Company Name': 'NAMEHCR',
    'OCC District Code': 'OCCDIST',
    'Office of the Comptroller of the Currency District': 'OCCDISTDESC',
    'Number of Domestic Offices': 'OFFDOM',
    'Number of Foreign Offices': 'OFFFOR',
    'Offices in Insured Other Areas': 'OFFOA',
    'OTS Region Name (for thrifts)': 'OTSREGNM',
    'Directly Owned by Another Bank (CERT)': 'PARCERT',
    'FDIC Quarterly Banking Profile Region': 'QBPRCOMLDESC',
    'Primary Federal Regulator (Institution)': 'REGAGNT',
    'Report Date': 'REPDTE',
    'RSSDID - High Regulatory Holder': 'RSSDHCR',
    'Federal Reserve ID Number': 'RSSDID',
    'Asset Concentration Hierarchy': 'SPECGRP',
    'Asset Concentration Hierarchy Description': 'SPECGRPDESC',
    'State Code': 'STALP',
    'State and County Number': 'STCNTY',
    'Subchapter S Corporations': 'SUBCHAPS',
    'Trust Powers': 'TRUST',
    'Web Address' : 'WEBADDR', 
    'Zip Code': 'ZIP',
    

  };

  function DemographicsTable({ selectedCert, googleApiKey }) {
    const [bankDetails, setBankDetails] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [isMarkerLoaded, setIsMarkerLoaded] = useState(false);
    const [mapKey, setMapKey] = useState(Date.now()); // Using timestamp as a unique key

    const handleMapLoad = () => {
        setIsMapLoaded(true);
    };
    
    const handleMarkerLoad = () => {
        setIsMarkerLoaded(true);
    };
    
    // Reset states when coordinates change
    useEffect(() => {
        setIsMapLoaded(false);
        setIsMarkerLoaded(false);
        setMapKey(Date.now());  // Update key to force re-render of map
    }, [coordinates]);
    
    useEffect(() => {
        async function fetchBankDetails() {
            // Check if selectedCert is not provided; if so, exit early
            if (!selectedCert) {
                console.warn("SelectedCert is not provided. Exiting data fetch early.");
                setIsLoading(false);
                return;
            }
    
            setIsLoading(true);
            setError(null);
    
            try {
                // Fetching bank details
                const url = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A${selectedCert}&fields=ACTIVE%2CBKCLASS%2CCALLFORM%2CCB%2CCBSA%2CCERT%2CCITY%2CEFFDATE%2CESTYMD%2CFDICDBS%2CFDICDBSDESC%2CFDICSUPV%2CFDICSUPVDESC%2CFED%2CFEDDESC%2CFLDOFF%2CINSAGNT1%2CINSDATE%2CMUTUAL%2CNAME%2CNAMEFULL%2CNAMEHCR%2COCCDIST%2COCCDISTDESC%2COFFDOM%2COFFFOR%2COFFOA%2COTSREGNM%2CPARCERT%2CQBPRCOMLDESC%2CREGAGNT%2CREPDTE%2CRSSDHCR%2CRSSDID%2CSPECGRP%2CSPECGRPDESC%2CSTALP%2CSTCNTY%2CSUBCHAPS%2CTRUST%2C%2CZIP&sort_by=REPDTE&sort_order=DESC&offset=0&format=json&download=false&filename=data_file`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const bankData = data.data[0].data || {};
                console.log("Bank Details:", bankData);
    
                setBankDetails(bankData);
    
                // Fetching exact address
                const addressUrl = `https://banks.data.fdic.gov/api/institutions?filters=CERT%3A${selectedCert}&fields=ADDRESS%2CWEBADDR&offset=0&format=json&download=false&filename=data_file`;
                const addressResponse = await fetch(addressUrl);
                if (!addressResponse.ok) {
                    throw new Error(`HTTP error when fetching address! status: ${addressResponse.status}`);
                }
                const addressData = await addressResponse.json();
                console.log("Address Data:", addressData);
    
                if (addressData.data && addressData.data.length > 0) {
                    bankData.ADDRESS = addressData.data[0].data.ADDRESS;
                    bankData.WEBADDR = addressData.data[0].data.WEBADDR;  // Added this line to include the web address
                    setBankDetails(bankData);
    
                    // Fetching coordinates
                    const coords = await getCoordinates(bankData.ADDRESS, bankData.CITY, bankData.STALP, bankData.ZIP, googleApiKey);
                    console.log("Coordinates:", coords);
                    setCoordinates(coords);
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }
    
        fetchBankDetails();
    }, [selectedCert, googleApiKey]);
    

    function formatValue(title, value) {
        if (value == null || value === '') {
            return 'N/A';
        }

        const dateFields = [
            'Last Structure Change Effective Date', 
            'Established Date', 
            'Date of Deposit Insurance', 
            'Report Date'
        ];
    
        if (dateFields.includes(title)) {
            // Ensure the value is a string
            const dateString = String(value);
    
            // Check that the string is of the format YYYYMMDD before proceeding
            if (dateString.length === 8 && !isNaN(dateString)) {
                const year = dateString.substring(0, 4);
                const month = dateString.substring(4, 6);
                const day = dateString.substring(6, 8);
                const date = new Date(year, month - 1, day);
                const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
                return new Intl.DateTimeFormat('en-US', options).format(date);
            } else {
                return 'Invalid Date Format';
            }
        }
        
        if (title === 'Web Address') {
            if (value.startsWith('http://') || value.startsWith('https://')) {
                return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>;
            } else {
                return value;  // Display the URL as text if it doesn't start with http:// or https://
            }
        }

        switch (title) {
            case 'Status':
                return value === '1' ? 'Closed' : 'Active';
            case 'FDIC Community Bank':
            case 'Directly Owned by Another Bank (CERT)':
                return value === '1' ? 'Yes' : 'No';
            case 'Mutual (Yes/No)':
                return value === '1' ? 'Yes' : 'No';
            case 'Trust Powers':
                return value === '1' ? 'Trust Power Granted' : 'No Trust Power Granted';
            default:
                return value;
        }

    }

   /* if (isLoading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }*/

    return (
          <div className="split-view-container">
           {/* {isLoading && <p>Loading...</p>}
            
    {error && <p>Error: {error.message}</p>} */}
    
            {bankDetails ? (
                <>
            <div className="table-container-dem">
                        <table className="custom-table-dem table-hover">
                            <thead>
                                <tr>
                                    <th style={{minWidth: "250px"}}>Demographic Information</th>
                                    <th style={{ textAlign: 'left' }}>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(variableToAPIKeyMap).map((title, index) => (
                                    <tr key={index} className="table-row-data-dem">
                                        <td className="item-cell"><div className="ellipsis">{title}</div></td>
                                        <td className="item-cell" style={{ textAlign: 'left' }}>
                                            <div className="ellipsis">
                                                {formatValue(title, bankDetails[variableToAPIKeyMap[title]])}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {coordinates && (
            <div  div className="map-container">
                <GoogleMap 
                    key={mapKey}
                    mapContainerStyle={mapContainerStyle} 
                    center={{ lat: coordinates[0], lng: coordinates[1] }} 
                    zoom={13}
                    onLoad={handleMapLoad}
                >
                    {isMapLoaded && (
                        <Marker
                            position={{ lat: coordinates[0], lng: coordinates[1] }}
                            onLoad={handleMarkerLoad}
                        >
                        {isMarkerLoaded && (
                            <InfoWindow 
                            position={{ lat: coordinates[0], lng: coordinates[1] }}
                            options={{ pixelOffset: new window.google.maps.Size(0,-40) }}
                            >
                            <div>
                                {bankDetails.NAME}<br />
                                {bankDetails.ADDRESS}<br />
                                {bankDetails.CITY}, {bankDetails.STALP} {bankDetails.ZIP}
                            </div>
                        </InfoWindow>
                        )}
                        </Marker>
                    )}
                </GoogleMap>
            </div>
        )}
                </>
            ) : null}
        </div>
    );
}
export default DemographicsTable;






    