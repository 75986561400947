import Header from './components/Header';
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';
import HomeScreen from './screens/HomeScreen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CategoryScreen from './screens/CategoryScreen';
import SummaryScreen from './screens/SummaryScreen';
import ChatCSV from './components/ChatCSV';
import './configs/chartConfig';

function App() {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact/>
            <Route path="/categories/:id" element={<CategoryScreen/>} />
            <Route path="/summary" element={<SummaryScreen />} />
            <Route path="/ai" element={<ChatCSV/>} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
