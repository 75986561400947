export const incomeStatement = ['INTINC', 'ILNDOM', 'ILNFOR', 'ILS', 'ICHBAL', 'ISC', 'ITRADE', 'IFREPO', 'IOTHII', 'EINTEXP', 'EDEPDOM', 'EDEPFOR', 'EFREPP', 'EOTHINT', 'NIM', 'ELNATR', 'NONII', 'IFIDUC', 'ISERCHG', 'IGLTRAD', 'IGLRTEX', 'IGLFXEX', 'IGLEDEX', 'IGLCMEX', 'IGLCREX', 'ADDNONII', 'IINVFEE', 'IVENCAP', 'ISERFEE', 'ISECZ', 'IINSCOM', 'IINSUND', 'IINSOTH', 'NETGNSLN', 'NETGNSRE', 'NETGNAST', 'IOTNII', 'NONIX', 'ESAL', 'EPREMAGG', 'ADDNONINTEXP', 'EAMINTAN', 'EINTOTH', 'EINTGW', 'EOTHNINT', 'PTAXNETINC', 'IGLSEC', 'ITAX', 'IBEFXTR', 'EXTRA', 'NETINBM', 'NETIMIN', 'NETINC', 'NTLNLS', 'NTRE', 'NTRENUS', 'NTREOFFDOM', 'NTRECONS', 'NTREAG', 'NTRERES', 'NTRERSFM', 'NTRERSF2', 'NTRELOC', 'NTREMULT', 'NTRENRES', 'NTREFOR', 'NTDEP', 'NTDEPNUS', 'NTAG', 'NTCI', 'NTCINUS', 'NTCON', 'NTCRCD', 'NTAUTO', 'NTCONOTH', 'NTFORGV', 'NTOTHER', 'NTLS', 'NTAGSM', 'DRLNLS', 'DRRE', 'DRRENUS', 'DRREOFFDOM', 'DRRECONS', 'DRREAG', 'DRRERES', 'DRRERSFM', 'DRRERSF2', 'DRRELOC', 'DRREMULT', 'DRRENRES', 'DRREFOR', 'DRDEP', 'DRDEPNUS', 'DRAG', 'DRCI', 'DRCINUS', 'DRCON', 'DRCRCD', 'DRAUTO', 'DRCONOTH', 'DRFORGV', 'DROTHER', 'DRLS', 'DRAGSM', 'CRLNLS', 'CRRE', 'CRRENUS', 'CRREOFFDOM', 'CRRECONS', 'CRREAG', 'CRRERES', 'CRRERSFM', 'CRRERSF2', 'CRRELOC', 'CRREMULT', 'CRRENRES', 'CRREFOR', 'CRDEP', 'CRDEPNUS', 'CRAG', 'CRCI', 'CRCINUS', 'CRCON', 'CRCRCD', 'CRAUTO', 'CRCONOTH', 'CRFORGV', 'CROTHER', 'CRLS', 'CRAGSM', 'EQCDIV', 'EQCDIVP', 'EQCDIVC', 'EQCSTKRX', 'NOIJ'];

export const rowData = [
    {
      label: '1. Total Interest Income',
      valueKey: 'INTINC',
      index: 1,
      level: 0,
      children: [
        {
          label: 'Domestic Office Loans',
          valueKey: 'ILNDOM',
          index: 2,
          level: 1,
        },
        {
          label: 'Foreign Office Loans',
          valueKey: 'ILNFOR',
          index: 3,
          level: 1,
        },
        {
          label: 'Lease Financing Receivables',
          valueKey: 'ILS',
          index: 4,
          level: 1,
        },
        {
          label: 'Balances Due from Depository Institutions',
          valueKey: 'ICHBAL',
          index: 5,
          level: 1,
        },
        {
          label: 'Securities',
          valueKey: 'ISC',
          index: 6,
          level: 1,
        },
        {
          label: 'Trading Accounts',
          valueKey: 'ITRADE',
          index: 7,
          level: 1,
        },
        {
          label: 'Federal Funds Sold',
          valueKey: 'IFREPO',
          index: 8,
          level: 1,
        },
        {
          label: 'Other Interest Income',
          valueKey: 'IOTHII',
          index: 9,
          level: 1,
        }
        ],
        },
    {
      label: '2. Total Interest Expense',
      valueKey: 'EINTEXP',
      index: 10,
      level: 0,
      children: [
        {
          label: 'Domestic Office Deposits',
          valueKey: 'EDEPDOM',
          index: 11,
          level: 1,
        },
        {
          label: 'Foreign Office Deposits',
          valueKey: 'EDEPFOR',
          index: 12,
          level: 1,
        },
        {
          label: 'Federal Funds Purchased',
          valueKey: 'EFREPP',
          index: 13,
          level: 1,
        },
        {
          label: 'Other Interest Expense',
          valueKey: 'EOTHINT',
          index: 14,
          level: 1,
        }
        ],
        },
    {
      label: '3. Net Interest Income',
      valueKey: 'NIM',
      index: 15,
      level: 0,
    },
    {
      label: '4. Provision For Credit Losses',
      valueKey: 'ELNATR',
      index: 16,
      level: 0,
    },
    {
      label: '5. Total Noninterest Income',
      valueKey: 'NONII',
      index: 17,
      level: 0,
      children: [
        {
          label: 'Fiduciary Activities',
          valueKey: 'IFIDUC',
          index: 18,
          level: 1,
        },
        {
          label: 'Service Charges on Deposit Accounts',
          valueKey: 'ISERCHG',
          index: 19,
          level: 1,
        },
        {
          label: 'Trading Account Gains & Fees',
          valueKey: 'IGLTRAD',
          index: 20,
          level: 1,
          children: [
            {
              label: 'Interest Rate Exposures',
              valueKey: 'IGLRTEX',
              index: 21,
              level: 2,
            },
            {
              label: 'Foreign Exchange Exposures',
              valueKey: 'IGLFXEX',
              index: 22,
              level: 2,
            },
            {
              label: 'Equity Security and Index Exposures',
              valueKey: 'IGLEDEX',
              index: 23,
              level: 2,
            },
            {
              label: 'Commodity and Other Exposures',
              valueKey: 'IGLCMEX',
              index: 24,
              level: 2,
            },
            {
              label: 'Credit Exposures',
              valueKey: 'IGLCREX',
              index: 25,
              level: 2,
            }
            ],
        },
        {
          label: 'Additional Noninterest Income',
          valueKey: 'ADDNONII',
          index: 26,
          level: 1,
          children: [
            {
              label: 'Investment Banking, Advisory, Brokerage, and Underwriting Fees',
              valueKey: 'IINVFEE',
              index: 27,
              level: 2,
            },
            {
              label: 'Venture Capital Revenue',
              valueKey: 'IVENCAP',
              index: 28,
              level: 2,
            },
            {
              label: 'Net Servicing Fees',
              valueKey: 'ISERFEE',
              index: 29,
              level: 2,
            },
            {
              label: 'Net Securitization Income',
              valueKey: 'ISECZ',
              index: 30,
              level: 2,
            },
            {
              label: 'Insurance Commission Fees and Income',
              valueKey: 'IINSCOM',
              index: 31,
              level: 2,
              children: [
                {
                  label: 'Insurance Underwriting Income',
                  valueKey: 'IINSUND',
                  index: 32,
                  level: 3,
                },
                {
                  label: 'Other Insurance Commissions and Fees',
                  valueKey: 'IINSOTH',
                  index: 33,
                  level: 3,
                }
                ],
                        },
            {
              label: 'Net Gains (Losses) on Sales of Loans',
              valueKey: 'NETGNSLN',
              index: 34,
              level: 2,
            },
            {
              label: 'Net Gains (Losses) on Sales of Other Real Estate Owned',
              valueKey: 'NETGNSRE',
              index: 35,
              level: 2,
            },
            {
              label: 'Net Gains (Losses) on Sales of Other Assets (Excluding Securities)',
              valueKey: 'NETGNAST',
              index: 36,
              level: 2,
            },
            {
              label: 'Other Noninterest Income',
              valueKey: 'IOTNII',
              index: 37,
              level: 2,
            }
            ],
        }
        ],
        },
    {
      label: '6. Total Noninterest Expense',
      valueKey: 'NONIX',
      index: 38,
      level: 0,
      children: [
        {
          label: 'Salaries and Employee Benefits',
          valueKey: 'ESAL',
          index: 39,
          level: 1,
        },
        {
          label: 'Premises and Equipment Expense',
          valueKey: 'EPREMAGG',
          index: 40,
          level: 1,
        },
        {
          label: 'Additional Noninterest Expense',
          valueKey: 'ADDNONINTEXP',
          index: 41,
          level: 1,
          children: [
            {
              label: 'Total Amortization Expense and Goodwill Impairment Losses',
              valueKey: 'EAMINTAN',
              index: 42,
              level: 2,
              children: [
                {
                  label: 'Amortization Expense and Impairment Losses for Other Intangible Losses',
                  valueKey: 'EINTOTH',
                  index: 43,
                  level: 3,
                },
                {
                  label: 'Goodwill Impairment Losses',
                  valueKey: 'EINTGW',
                  index: 44,
                  level: 3,
                }
                ],
                        },
            {
              label: 'Other Noninterest Expense',
              valueKey: 'EOTHNINT',
              index: 45,
              level: 2,
            }
            ],
        }
        ],
        },
    {
      label: '7. Pre-tax Net Operating Income',
      valueKey: 'PTAXNETINC',
      index: 46,
      level: 0,
    },
    {
      label: '8. Securities Gains (Losses)',
      valueKey: 'IGLSEC',
      index: 47,
      level: 0,
    },
    {
      label: '9. Applicable Income Taxes',
      valueKey: 'ITAX',
      index: 48,
      level: 0,
    },
    {
      label: '10. Income Before Extraordinary Items',
      valueKey: 'IBEFXTR',
      index: 49,
      level: 0,
    },
    {
      label: '11. Discontinued Operations (Extraordinary Gains - Net)',
      valueKey: 'EXTRA',
      index: 50,
      level: 0,
    },
    {
      label: '12. Net Income Attributable to Bank and Noncontrolling Interests',
      valueKey: 'NETINBM',
      index: 51,
      level: 0,
    },
    {
      label: '13. Net Income Attributable to Noncontrolling Interests',
      valueKey: 'NETIMIN',
      index: 52,
      level: 0,
    },
    {
      label: '14. Net Income Attributable to Bank',
      valueKey: 'NETINC',
      index: 53,
      level: 0,
    },
    {
      label: '15. Net Charge-Offs',
      valueKey: 'NTLNLS',
      index: 54,
      level: 0,
      children: [
        {
          label: 'Loans Secured by Real Estate, Total',
          valueKey: 'NTRE',
          index: 55,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'NTRENUS',
              index: 56,
              level: 2,
            },
            {
              label: 'Real Estate Loans in Domestic Offices',
              valueKey: 'NTREOFFDOM',
              index: 57,
              level: 2,
              children: [
                {
                  label: 'Construction and Land Development',
                  valueKey: 'NTRECONS',
                  index: 58,
                  level: 3,
                },
                {
                  label: 'Secured by Farmland',
                  valueKey: 'NTREAG',
                  index: 59,
                  level: 3,
                },
                {
                  label: 'Secured by 1-4 Family Residential Properties',
                  valueKey: 'NTRERES',
                  index: 60,
                  level: 3,
                  children: [
                    {
                      label: '    Secured by First Liens',
                      valueKey: 'NTRERSFM',
                      index: 61,
                      level: 4,
                    },
                    {
                      label: '    Secured by Junior Liens',
                      valueKey: 'NTRERSF2',
                      index: 62,
                      level: 4,
                    },
                    {
                      label: '    Home Equity Lines of Credit',
                      valueKey: 'NTRELOC',
                      index: 63,
                      level: 4,
                    }
                    ],
                },
                {
                  label: 'Secured by Multifamily Residential Properties',
                  valueKey: 'NTREMULT',
                  index: 64,
                  level: 3,
                },
                {
                  label: 'Secured by Nonfarm Nonresidential Properties',
                  valueKey: 'NTRENRES',
                  index: 65,
                  level: 3,
                }
                ],
             },
            {
              label: 'Real Estate Loans in Foreign Offices',
              valueKey: 'NTREFOR',
              index: 66,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Depository Institutions',
          valueKey: 'NTDEP',
          index: 67,
          level: 1,
          children: [
            {
              label: 'To Foreign Banks',
              valueKey: 'NTDEPNUS',
              index: 68,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Finance Agricultural Production and Other Loans to Farmers',
          valueKey: 'NTAG',
          index: 69,
          level: 1,
        },
        {
          label: 'Commercial and Industrial Loans',
          valueKey: 'NTCI',
          index: 70,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'NTCINUS',
              index: 71,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Individuals',
          valueKey: 'NTCON',
          index: 72,
          level: 1,
          children: [
            {
              label: 'Credit Cards',
              valueKey: 'NTCRCD',
              index: 73,
              level: 2,
            },
            {
              label: 'Automobile Loans',
              valueKey: 'NTAUTO',
              index: 74,
              level: 2,
            },
            {
              label: 'Other Consumer Loans',
              valueKey: 'NTCONOTH',
              index: 75,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Foreign Governments and Official Institutions',
          valueKey: 'NTFORGV',
          index: 76,
          level: 1,
        },
        {
          label: 'Other Loans',
          valueKey: 'NTOTHER',
          index: 77,
          level: 1,
        },
        {
          label: 'Lease Financing Receivables',
          valueKey: 'NTLS',
          index: 78,
          level: 1,
        },
        {
          label: 'Loans to Finance Agricultural Prod and Other Loans to Farmers (Included Above)',
          valueKey: 'NTAGSM',
          index: 79,
          level: 1,
        },
        {
          label: 'Total Charge-Offs',
          valueKey: 'DRLNLS',
          index: 80,
          level: 1,
        },
        {
          label: 'Loans Secured by Real Estate, Total',
          valueKey: 'DRRE',
          index: 81,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'DRRENUS',
              index: 82,
              level: 2,
            },
            {
              label: 'Real Estate Loans in Domestic Offices',
              valueKey: 'DRREOFFDOM',
              index: 83,
              level: 2,
              children: [
                {
                  label: 'Construction and Land Development',
                  valueKey: 'DRRECONS',
                  index: 84,
                  level: 3,
                },
                {
                  label: 'Secured by Farmland',
                  valueKey: 'DRREAG',
                  index: 85,
                  level: 3,
                },
                {
                  label: 'Secured by 1-4 Family Residential Properties',
                  valueKey: 'DRRERES',
                  index: 86,
                  level: 3,
                  children: [
                    {
                      label: '    Secured by First Liens',
                      valueKey: 'DRRERSFM',
                      index: 87,
                      level: 4,
                    },
                    {
                      label: '    Secured by Junior Liens',
                      valueKey: 'DRRERSF2',
                      index: 88,
                      level: 4,
                    },
                    {
                      label: '    Home Equity Lines of Credit',
                      valueKey: 'DRRELOC',
                      index: 89,
                      level: 4,
                    }
                    ],
                },
                {
                  label: 'Secured by Multifamily Residential Properties',
                  valueKey: 'DRREMULT',
                  index: 90,
                  level: 3,
                },
                {
                  label: 'Secured by Nonfarm Nonresidential Properties',
                  valueKey: 'DRRENRES',
                  index: 91,
                  level: 3,
                }
                ],
            },
            {
              label: 'Real Estate Loans in Foreign Offices',
              valueKey: 'DRREFOR',
              index: 92,
              level: 2,
            }
            ],
                },
        {
          label: 'Loans to Depository Institutions',
          valueKey: 'DRDEP',
          index: 93,
          level: 1,
          children: [
            {
              label: 'To Foreign Banks',
              valueKey: 'DRDEPNUS',
              index: 94,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Finance Agricultural Production and Other Loans to Farmers',
          valueKey: 'DRAG',
          index: 95,
          level: 1,
        },
        {
          label: 'Commercial and Industrial Loans',
          valueKey: 'DRCI',
          index: 96,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'DRCINUS',
              index: 97,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Individuals',
          valueKey: 'DRCON',
          index: 98,
          level: 1,
          children: [
            {
              label: 'Credit Cards',
              valueKey: 'DRCRCD',
              index: 99,
              level: 2,
            },
            {
              label: 'Automobile Loans',
              valueKey: 'DRAUTO',
              index: 100,
              level: 2,
            },
            {
              label: 'Other Consumer Loans',
              valueKey: 'DRCONOTH',
              index: 101,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Foreign Governments and Official Institutions',
          valueKey: 'DRFORGV',
          index: 102,
          level: 1,
        },
        {
          label: 'Other Loans',
          valueKey: 'DROTHER',
          index: 103,
          level: 1,
        },
        {
          label: 'Lease Financing Receivables',
          valueKey: 'DRLS',
          index: 104,
          level: 1,
        },
        {
          label: 'Loans to Finance Agricultural Prod and Other Loans to Farmers (Included Above)',
          valueKey: 'DRAGSM',
          index: 105,
          level: 1,
        },
        {
          label: 'Total Recoveries',
          valueKey: 'CRLNLS',
          index: 106,
          level: 1,
        },
        {
          label: 'Loans Secured by Real Estate, Total',
          valueKey: 'CRRE',
          index: 107,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'CRRENUS',
              index: 108,
              level: 2,
            },
            {
              label: 'Real Estate Loans in Domestic Offices',
              valueKey: 'CRREOFFDOM',
              index: 109,
              level: 2,
              children: [
                {
                  label: 'Construction and Land Development',
                  valueKey: 'CRRECONS',
                  index: 110,
                  level: 3,
                },
                {
                  label: 'Secured by Farmland',
                  valueKey: 'CRREAG',
                  index: 111,
                  level: 3,
                },
                {
                  label: 'Secured by 1-4 Family Residential Properties',
                  valueKey: 'CRRERES',
                  index: 112,
                  level: 3,
                  children: [
                    {
                      label: '    Secured by First Liens',
                      valueKey: 'CRRERSFM',
                      index: 113,
                      level: 4,
                    },
                    {
                      label: '    Secured by Junior Liens',
                      valueKey: 'CRRERSF2',
                      index: 114,
                      level: 4,
                    },
                    {
                      label: '    Home Equity Lines of Credit',
                      valueKey: 'CRRELOC',
                      index: 115,
                      level: 4,
                    }
                    ],
                },
                {
                  label: 'Secured by Multifamily Residential Properties',
                  valueKey: 'CRREMULT',
                  index: 116,
                  level: 3,
                },
                {
                  label: 'Secured by Nonfarm Nonresidential Properties',
                  valueKey: 'CRRENRES',
                  index: 117,
                  level: 3,
                }
                ],
            },
            {
              label: 'Real Estate Loans in Foreign Offices',
              valueKey: 'CRREFOR',
              index: 118,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Depository Institutions',
          valueKey: 'CRDEP',
          index: 119,
          level: 1,
          children: [
            {
              label: 'To Foreign Banks',
              valueKey: 'CRDEPNUS',
              index: 120,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Finance Agricultural Production and Other Loans to Farmers',
          valueKey: 'CRAG',
          index: 121,
          level: 1,
        },
        {
          label: 'Commercial and Industrial Loans',
          valueKey: 'CRCI',
          index: 122,
          level: 1,
          children: [
            {
              label: 'To Non-U.S. Addressees',
              valueKey: 'CRCINUS',
              index: 123,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Individuals',
          valueKey: 'CRCON',
          index: 124,
          level: 1,
          children: [
            {
              label: 'Credit Cards',
              valueKey: 'CRCRCD',
              index: 125,
              level: 2,
            },
            {
              label: 'Automobile Loans',
              valueKey: 'CRAUTO',
              index: 126,
              level: 2,
            },
            {
              label: 'Other Consumer Loans',
              valueKey: 'CRCONOTH',
              index: 127,
              level: 2,
            }
            ],
        },
        {
          label: 'Loans to Foreign Governments and Official Institutions',
          valueKey: 'CRFORGV',
          index: 128,
          level: 1,
        },
        {
          label: 'Other Loans',
          valueKey: 'CROTHER',
          index: 129,
          level: 1,
        },
        {
          label: 'Lease Financing Receivables',
          valueKey: 'CRLS',
          index: 130,
          level: 1,
        },
        {
          label: 'Loans to Finance Agricultural Prod and Other Loans to Farmers (Included Above)',
          valueKey: 'CRAGSM',
          index: 131,
          level: 1,
        }
        ],
        },
    {
      label: '16. Cash Dividends',
      valueKey: 'EQCDIV',
      index: 132,
      level: 0,
      children: [
        {
          label: 'Cash Dividends Declared on Preferred Stock',
          valueKey: 'EQCDIVP',
          index: 133,
          level: 1,
        },
        {
          label: 'Cash Dividends Declared on Common Stock',
          valueKey: 'EQCDIVC',
          index: 134,
          level: 1,
        }
        ],
        },
    {
      label: '17. Net Additions to Capital Stock',
      valueKey: 'EQCSTKRX',
      index: 135,
      level: 0,
    },
    {
      label: '18. Net Operating Income',
      valueKey: 'NOIJ',
      index: 136,
      level: 0,
    },
    ];
  