import React from 'react'
import { Navbar, Nav, Container,Row, NavDropdown } from 'react-bootstrap'
import {LinkContainer}from 'react-router-bootstrap'
import './Header.css'
import SearchBar from './SearchBar'
function Header() {
  return (
    <header>
        <Navbar className="navbar flex-nowrap reduced-height" variant="dark" expand="lg" collapseOnSelect>
            <Container className="container-fluid">
            <LinkContainer to="/">
                <Navbar.Brand>Bankr.ai</Navbar.Brand>
            </LinkContainer>
            {/*
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                <LinkContainer to="/ai" >
                    <Nav.Link>
                    <i className="fa-solid fa-robot"  >AI</i>
                    </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/subscribe">
                    <Nav.Link>
                    <i className="fa-solid fa-id-card">Subscribe</i>
                    </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                    <Nav.Link>
                    <i className="fa-solid fa-arrow-right-to-bracket">Login</i>
                    </Nav.Link>
                </LinkContainer>
                </Nav>
                </Navbar.Collapse> */}
            <div style={{ marginLeft: "auto" }} >
                <SearchBar/>
            </div>
            </Container>
        </Navbar>
        </header>

  )
}

export default Header