import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import UnrealizedSearch from './UnrealizedSearch';
import './UnrealizedTable.css';


const UnrealizedTable = ({style}) => {
  const getLatestCompletedQuarterAndYear = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 60);  // Subtract 60 days to account for the data release delay
  
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1; // Months are zero-based
  
    // Determine the last completed quarter
    let quarterEnd;
    if (currentMonth <= 3) {
      quarterEnd = '12-31';
      currentYear -= 1; // go to the previous year since we are in the first quarter of the current year
    } else if (currentMonth <= 6) {
      quarterEnd = '03-31';
    } else if (currentMonth <= 9) {
      quarterEnd = '06-30';
    } else {
      quarterEnd = '09-30';
    }
  
    return {
      latestYear: currentYear,
      latestQuarter: quarterEnd,
    };
  }
  
  const [title, setTitle] = useState('');
  const { latestYear, latestQuarter } = getLatestCompletedQuarterAndYear();
  const [quarter, setQuarter] = useState(`${latestYear}-${latestQuarter}`);
  
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [showGains, setShowGains] = useState(true);
  const [currentOrder, setCurrentOrder] = useState('none');
  const [sortedData, setSortedData] = useState(data);
  const [rankedData, setRankedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);



  const years = [];
  for (let year = latestYear; year >= 1998; year--) {
    years.push({ value: year, label: year });
  }

  const quarters = [
    { value: '12-31', label: 'Q4' },
    { value: '09-30', label: 'Q3' },
    { value: '06-30', label: 'Q2' },
    { value: '03-31', label: 'Q1' },
  ];
  

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);

  useEffect(() => {
    if (fetchedData.length === 0 || !quarter) return;

    const fetchNamesEquityAndSecurities = async () => {
  const requestPromises = fetchedData.map(async (row) => {
    const cert = row.CERT;
    const yyyymmdd = quarter.replaceAll('-', '');
    const fieldsParam = `${yyyymmdd}%2CEQ%2CSC%2CASSET`;  // Include both EQ and SC fields

    const nameRequest = axios.get(`https://banks.data.fdic.gov/api/institutions?filters=CERT%3A${cert}&fields=NAME&limit=10&offset=0&format=json&download=false&filename=data_file`);
    const equityAndSecuritiesRequest = axios.get(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${cert}&fields=${fieldsParam}&sort_by=CALLYMD&sort_order=DESC&limit=110&offset=0&agg_limit=0&format=json&download=false&filename=data_file`);

    const results = await Promise.all([nameRequest, equityAndSecuritiesRequest]);

    // Cut off text after comma in NAME field
    const institutionName = results[0].data.data[0]?.data?.NAME || `FDIC CERT:${row.CERT}`;
    const institutionNameWithoutComma = institutionName.split(',')[0];

    return {
      ...row,
      NAME: institutionNameWithoutComma,
      EQ: results[1].data.data[0]?.data?.EQ || 0.00,
      SC: results[1].data.data[0]?.data?.SC || 0.00,
      ASSET: results[1].data.data[0]?.data?.ASSET || 0.00,  // Accessing the same result object for both EQ and SC
    };
  });

  const updatedData = await Promise.all(requestPromises);
  setData(updatedData);
};

    
    fetchNamesEquityAndSecurities();
  }, [fetchedData, quarter]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const fetchData = async (showGains, page = 1) => {
    if (!quarter) return;
  
    try {
      setError(null)
      const ordering = showGains ? 'unrealized_gl' : '-unrealized_gl';
      const response = await axios.get(`/api/quloss/?CALLYMD=${quarter}&ordering=${ordering}&page=${page}&page_size=25`);

        
      if(response.data.count === 0){
        setError('Data for the selected quarter is not yet available data for an earlier quarter might be displayed. You can select an earlier quarter.');
        return;
      }
  
      if (Array.isArray(response.data)) {
        setFetchedData(response.data.slice(0, 25));
        setTotalPages(Math.ceil(response.data.length / 25));
      } else if (Array.isArray(response.data.results)) {
        setFetchedData(response.data.results.slice(0, 25));
        setTotalPages(Math.ceil(response.data.count / 25));
      } else {
        console.error('Unexpected API response structure:', response.data);
      }
      setCurrentPage(page);
      setShowGains(showGains);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Data for the selected quarter ${quarter} is not yet available, data for an earlier quarter might be displayed. You can select an earlier quarter.`);
    
    }
  };
  
  

  const handleColumnClick = () => {
    setShowGains(!showGains);
    fetchData(!showGains);
  };

  const formatCurrency = (number) => {
    if (number === null) {
      return '';
    }
  
    const isNegative = number < 0;
    const absoluteNumber = Math.abs(number);
    const formattedNumber = (absoluteNumber * 1000).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    return isNegative ? `($${formattedNumber})` : `$${formattedNumber}`;
  };
  
  const handleESColumnClick = (columnKey) => {
    const rows = [...sortedData];

    if (currentOrder === 'none' || currentOrder === 'desc') {
      rows.sort((a, b) => parseFloat(a[columnKey]) - parseFloat(b[columnKey]));
      setCurrentOrder('asc');
    } else if (currentOrder === 'asc') {
      rows.sort((a, b) => parseFloat(b[columnKey]) - parseFloat(a[columnKey]));
      setCurrentOrder('desc');
    } else {
      setSortedData(data);
      setCurrentOrder('none');
      return;
    }

    setSortedData(rows);
  };

  const computeRank = () => {
    const sortedByUnrealized = [...sortedData].sort((a, b) => {
      return showGains
        ? parseFloat(a.unrealized_gl) - parseFloat(b.unrealized_gl)
        : parseFloat(b.unrealized_gl) - parseFloat(a.unrealized_gl);
    });

    const offset = (currentPage - 1) * 25;

    const newRankedData = sortedData.map((row) => {
      const rank = sortedByUnrealized.findIndex((item) => item.CERT === row.CERT) + 1 + offset;
      return { ...row, rank };
    });

    setRankedData(newRankedData);
  };

  useEffect(() => {
    computeRank();
  }, [sortedData]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchData(showGains, currentPage - 1);
    }
  };
  

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchData(showGains, currentPage + 1);
    }
  };
  
  useEffect(() => {
    if (quarter) {
      fetchData(showGains);
    }
  }, [quarter]);
  
  useEffect(() => {
    if (!selectedYear || !selectedQuarter) return;
  
    setQuarter(`${selectedYear.value}-${selectedQuarter.value}`);
  }, [selectedYear, selectedQuarter]);

  const handleShortcutClick = (year, quarter) => {
    setSelectedYear({ value: year, label: year });
    setSelectedQuarter({ value: quarter, label: `Q${quarters.findIndex((item) => item.value === quarter) + 1}` });
    setQuarter(`${year}-${quarter}`);
  };
  
  const getShortcutQuarters = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 60);  // Subtract 60 days to account for the data release delay

    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;  // Months are zero-based

    // Determine the last completed quarter based on the adjusted date
    if (currentMonth <= 3) {
        currentMonth = 12;
        currentYear -= 1;
    } else if (currentMonth <= 6) {
        currentMonth = 3;
    } else if (currentMonth <= 9) {
        currentMonth = 6;
    } else {
        currentMonth = 9;
    }

    // Define the last day of each quarter
    const quarterEnds = {
        3: '03-31',
        6: '06-30',
        9: '09-30',
        12: '12-31',
    };

    // Generate the quarters
    const shortcutQuarters = [];
    for (let i = 0; i < 4; i++) {
        shortcutQuarters.unshift({ // Use unshift to add to the beginning of the array
            year: currentYear,
            quarter: quarterEnds[currentMonth],
        });
        if (currentMonth === 3) {
            currentMonth = 12;
            currentYear -= 1;
        } else {
            currentMonth -= 3;
        }
    }

    return shortcutQuarters;
}

  const shortQ = getShortcutQuarters();
  useEffect(() => {
    if (!quarter) {
      setTitle('');
      return;
    }
  
    const year = quarter.split('-')[0];
    const q = quarters.find((q) => q.value === quarter.split('-')[1] + '-' + quarter.split('-')[2]);
    const quarterLabel = q ? q.label : '';
  
    setTitle(`Unrealized (Losses)/Gains Ranked for ${year} - ${quarterLabel}`);
  }, [quarter]);
  
  const formatPercentage = (numerator, denominator) => {
    if (denominator === 0) return '0.00%';  // Prevent division by zero
    const percentage = (numerator / denominator) * 100;
    const truncatedPercentage = Math.floor(percentage * 100) / 100;  // Truncate to 2 decimal places
    return `${truncatedPercentage.toFixed(2)}%`;  // Ensure two decimal places are always shown
  };
  
return (
  <div style={style}>
      <h2 style={{marginBottom: '20px', opacity: title ? 1 : 0, transition: 'opacity 0.4s ease-in-out' }}>{title}</h2>
     <div className="segment-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
        {shortQ.reverse().map((q, index) => {
          const isSelected = `${q.year}-${q.quarter}` === quarter;
          return (
            <button
              className="btn btn-outline-primary btn-sm"
              key={index}
              onClick={() => handleShortcutClick(q.year, q.quarter)}
              style={{
                borderRadius: '25px',
                border: '1px solid royalblue',
                backgroundColor: isSelected ? 'royalblue' : 'white',
                color: isSelected ? 'white' : 'royalblue',
                marginLeft: '0.5rem',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              {q.year} {quarters.find((item) => item.value === q.quarter).label}
            </button>
          );
        })}
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }} className="dropdowns-container">
        <span>Select a year: </span>
        <Select
          classNamePrefix="custom-select"
          options={years}
          onChange={(selectedOption) => setSelectedYear(selectedOption)}
          placeholder=""
         
        />
        <span  style={{marginLeft:'5px'}}>quarter:</span>
        <Select
          classNamePrefix="custom-select"
          options={quarters}
          onChange={(selectedOption) => setSelectedQuarter(selectedOption)}
          placeholder=""
        />
      </div>
    </div>
    {data.length > 0 && (
      <div className='table-responsive'>
          {error && (
            <div>
              <span style={{ color: 'red',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
              }}>
                {error}</span>
            </div>
          )}
      <table class="table table-hover custom-table" style={{transition: ' transform 0.3s ease-in-out, opacity 0.3s ease-in-out'}}>
        <thead>
        <tr>
          <th>Rank</th>
          <th style={{ textAlign: 'left' }}>
              <div class="name-search-container">
                  <div style={{ marginTop: '29px' }}>Name</div>
                  <div style={{ position: 'relative', top: '13px', marginLeft: '10px' }}>  {/* Adjusted style */}
                      <UnrealizedSearch quarter={quarter} />
                  </div>
              </div>
          </th>
          <th onClick={handleColumnClick} style={{ cursor: 'pointer' }}>
            Unrealized {showGains ? '(Losses)' : 'Gains'}
            <span className={`sort-indicator ${currentOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'}`}></span>
          </th>
          <th onClick={() => handleESColumnClick('EQ')} style={{ cursor : 'pointer' }}>
            Total Equity
            <span className={`sort-indicator ${currentOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'}`}></span>
          </th>
          <th  onClick={() => handleESColumnClick('SC')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
            Total Securities
            <span className={`sort-indicator ${currentOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'}`}></span>
          </th>
          <th style={{ fontWeight: 'bold' , textAlign :'left' }}>Total Assets</th>
          <th style={{ fontWeight: 'bold', textAlign :'left' }}>HTM</th>
          <th style={{ fontWeight: 'bold' , textAlign :'left' }}>AFS</th>
          <th style={{textAlign :'left' }}>Unrealized {showGains ? '(Losses)' : 'Gains'}/Total Securities%</th>
          <th style={{textAlign :'left' }}>Unrealized {showGains ? '(Losses)' : 'Gains'}/Bank Equity%</th>
          <th style={{textAlign :'left' }}>Unrealized {showGains ? '(Losses)' : 'Gains'}/Total Assets%</th>
        </tr> 
          </thead>
        <tbody>
          {rankedData.map((row) => {
            const HTM = row.SCHF - row.SCHA;
            const AFS = row.SCAF - row.SCAA;
            const unrealized_gl_percentage_securities = formatPercentage(row.unrealized_gl, row.SC);
            const unrealized_gl_percentage_equity = formatPercentage(row.unrealized_gl, row.EQ);
            const unrealized_gl_percentage_assets = formatPercentage(row.unrealized_gl, row.ASSET);
            return (
              <tr key={row.CERT}>
                <td className="table-row-data" data-label="Header 1">{row.rank}</td>
                <td className="table-row-data" style={{textAlign: 'left'}} data-label="Header 2"><span title={`FDIC ID: ${row.CERT}`}>{row.NAME}</span></td>
                <td className="table-row-data" data-label="Header 3">{formatCurrency(row.unrealized_gl)}</td>
                <td className="table-row-data" data-label="Header 4">{formatCurrency(row.EQ)}</td>
                <td className="table-row-data" data-label="Header 5">{formatCurrency(row.SC)}</td>
                <td className="table-row-data" data-label="Header 6">{formatCurrency(row.ASSET)}</td>
                <td className="table-row-data" data-label="Header 6">{formatCurrency(HTM)}</td>
                <td className="table-row-data" data-label="Header 7">{formatCurrency(AFS)}</td>
                <td className="table-row-data" data-label="Unrealized GL/Total Securities%">{unrealized_gl_percentage_securities}</td>
                <td className="table-row-data" data-label="Unrealized GL/Equity%">{unrealized_gl_percentage_equity}</td>
                <td className="table-row-data" data-label="Unrealized GL/Assets%">{unrealized_gl_percentage_assets}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    
    )}
    {data.length > 0 && (
          <div style = {{marginTop: '25px'}}>
          <tr>
            <td colSpan={7} style={{ textAlign: 'right', paddingRight: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  style={{ fontSize: '0.7rem', marginRight: '1rem', paddingTop: '4px', paddingBottom: '4px', marginTop: '-22px', backgroundColor:'royalblue'}}
                >
                  Previous Page
                </button>
                <span style={{ fontSize: '16px', marginRight: '1rem', marginTop: '-10px' }}>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{ fontSize: '0.7rem', paddingTop: '4px', paddingBottom: '4px', marginTop: '-22px', backgroundColor:'royalblue' }}
                >
                  Next Page
                </button>
              </div>
            </td>
          </tr>
        </div>
    )}
  </div>
);
};
export default UnrealizedTable;