import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Card, ListGroupItem } from 'react-bootstrap';
import Rating from '../components/Rating';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listCategoryDetails } from '../actions/categoryActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import UnrealizedTable from '../components/UnrealizedComponents/UnrealizedTable';
import CommercialTable from '../components/CommercialComponents/CommercialTable';
import SummaryGreeting from '../components/SummaryComponents/SummaryGreeting';
import ConsumerLoans from '../components/ConsumerComponents/ConsumerLoans';
import AutoLoansTable from '../components/AutoComponents/AutoLoansTable'
import ViewerSearch from '../components/ReportComponents/ViewerSearch';
import GraphSearch from '../components/BarGraphComponents/GraphSearch';
import BankrAI from '../components/BankrAI/BankrAI';


function CategoryScreen() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const categoryDetails = useSelector((state) => state.categoryDetails);
    const { loading, error, category } = categoryDetails;
  
    useEffect(() => {
      dispatch(listCategoryDetails(id));
    }, [id]);

    const renderComponent = () => {
        switch (id) {
            case '1':
                return <UnrealizedTable />;
            case '2':
                return <CommercialTable />;  
            case '3':
                return <SummaryGreeting />;
            case '4':
              return <AutoLoansTable />;  
            case '5':
                return <ConsumerLoans />;
            case '6':
              return <GraphSearch />;
            case '7':
              return <BankrAI />;  
            case '8':
                return <ViewerSearch/>
            

            default:
                return null;
        }
    };

    return (
        <div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : category ? (
            <>
              {/*<Row>
                <Col md={6}>
                  <Image src={category.image} alt={category.name} fluid />
                </Col>
                <Col md={3}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h3>{category.name}</h3>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Rating value={category.rating} text={`${category.numReviews} reviews`} color={'#f8e825'} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Price: ${category.price}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Description: {category.description}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={3}>
                  <Card>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <Row>
                          <Col>Price:</Col>
                          <Col>
                            <strong>${category.price}</strong>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>Status:</Col>
                          <Col>
                            <strong>{category.countInStock > 0 ? 'In Stock' : 'Out of stock'}</strong>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Button className="btn-block" disabled={category.countInStock === 0} type="button">
                          Add to Cart
                        </Button>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                 </Col>
                </Row>*/}
              <div>
               {renderComponent()}
              </div>
            </>
          ) : null}
         
        </div>
      );
}

export default CategoryScreen;
