import React, { useState, useEffect } from 'react';
import PdfToC from './PdfToc';
import { Worker, Viewer, ViewMode} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import './ViewerSearch.css'
import Select from 'react-select';

function ViewerSearch({ onCertChange = () => {} }) {

  const currentDate = new Date();

  const quarters = [
    { value: '0331', label: 'Q1' },
    { value: '0630', label: 'Q2' },
    { value: '0930', label: 'Q3' },
    { value: '1231', label: 'Q4' },
  ];

  function getDefaultQuarter() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    
    const month = currentDate.getMonth();

    let defaultQuarter;
    if (month < 3) {  // January, February, March
      defaultQuarter = '0930'; // Q3 of the previous year
      currentYear--; // Decrement the year for Q3 of the previous year
    } else if (month < 6) {  // April, May, June
      defaultQuarter = '1231'; // Q4 of the previous year
      currentYear--;
    } else if (month < 9) {  // July, August, September
      defaultQuarter = '0331'; // Q1 of the current year
    } else {  // October, November, December
      defaultQuarter = '0630'; // Q2 of the current year
    }

    return { year: currentYear, quarter: defaultQuarter };
}

const { year, quarter } = getDefaultQuarter();



 
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [cert, setCert] = useState(null);
  const [date, setDate] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [loadName, setLoadName] = useState(null);
  const [pdfToc, setPdfToc] = useState([]);  // Add this line
  const pageNumber = 1;  // Add this line
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const years = [];
  for (let y = currentDate.getFullYear(); y >= 1998; y--) {
    years.push({ value: y, label: y.toString() });
  }
  const [selectedYear, setSelectedYear] = useState({ value: year, label: year.toString() });
  const [selectedQuarter, setSelectedQuarter] = useState({ value: quarter, label: quarters.find(q => q.value === quarter).label });
  
  const REPDTE_string = quarter + selectedYear.label 

  useEffect(() => {
    const selectedYear = { value: year, label: year.toString() };
    const selectedQuarter = { value: quarter, label: quarters.find(q => q.value === quarter).label };
  
    const REPDTE_string = quarter + selectedYear.label;
  
    updatePdfUrl(cert, REPDTE_string);
  }, [year, quarter]);

  const { jumpToPage } = pageNavigationPluginInstance;

  useEffect(() => {
    if (cert && date) {
      const apiUrl = `/api/proxy_pdf/${encodeURIComponent(cert)}/${encodeURIComponent(date)}/`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const pdfBase64 = data.pdf_data;
          const pdfBlob = new Blob([new Uint8Array(atob(pdfBase64).split("").map(function(c) {
              return c.charCodeAt(0);
          }))], {
              type: "application/pdf"
          });
  
          const blobUrl = URL.createObjectURL(pdfBlob);
          console.log("Setting iframe URL to:", blobUrl);
          setIframeUrl(blobUrl);
  
          // You now also have data.pdf_toc which you can use to display the table of contents
          console.log("Table of Contents:", data.pdf_toc);
          setPdfToc(data.pdf_toc);  // Add this line

        })
        .catch((error) => {
          console.error("Failed to fetch PDF:", error);
        });
    } else {
      console.log("Cert or date is not defined");
    }
  }, [cert, date]);
  

  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?filters=ACTIVE%3A1&search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=15&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    return data.data;
  }

  async function handleSearch(event) {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 1) {
      const results = await searchAPI(query);
      setResults(results);
    } else {
      setResults([]);
    }
  }

  function handleResultClick(name, cert, quarterValue, yearValue) {
    const REPDTE_string2 = quarterValue + yearValue;
    setSearchTerm(name);
    setResults([]);
    updatePdfUrl(cert, REPDTE_string2);
    onCertChange(cert);
    setCert(cert);  // Update cert in state
    setDate(REPDTE_string2); // Update date in state
    setLoadName(name)
  }
 
  function handleDateChange(cert, quarterValue, yearValue) {
    const REPDTE_string1 = quarterValue + yearValue;
    console.log(REPDTE_string1);
    updatePdfUrl(cert, REPDTE_string1);
    onCertChange(cert);
    setCert(cert);  // Update cert in state
    setDate(REPDTE_string1); // Update date in state
}
  
  const convertDate = (date) => {
    return date.substring(4, 8) + date.substring(0, 2) + date.substring(2, 4);
  }

  useEffect(() => {
    async function fetchRandomBank(REPDTE) {
      const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=REPDTE%3A${REPDTE}&fields=%2CCERT%2CRSSDID%2CNAME&sort_by=CERT&sort_order=DESC&limit=10000&format=json&download=false&filename=data_file`;
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.data && data.data.length > 0) {
          const randomIndex = Math.floor(Math.random() * data.data.length);
          const randomBankData = data.data[randomIndex].data;
          const cert = randomBankData.CERT;

          // Execute your existing logic
          onCertChange(cert);
          const formattedDate = REPDTE.substring(4, 8) + REPDTE.substring(0, 2) + REPDTE.substring(2, 4);
          const name = randomBankData.NAME
          updatePdfUrl(cert, formattedDate); // Date is converted back to MMDDYYYY
          setCert(cert);  // Update cert in state
          setDate(formattedDate); // Update date in state
          setLoadName(name)
        }
      } catch (e) {
        console.error(e);
      }
    }

    // Execute your existing logic
    setSearchTerm('');
    setResults([]);

    const initialDate = REPDTE_string;  // Replace with your initial date in MMDDYYYY
    const REPDTE = convertDate(initialDate);  // Convert date to YYYYMMDD
    fetchRandomBank(REPDTE); // Fetch a random bank when the component mounts
  }, []);

  function updatePdfUrl(cert, date) {
    const url = `https://cdr.ffiec.gov/Public/ViewFacsimileDirect.aspx?ds=call&idType=fdiccert&id=${cert}&date=${date}`;
    setPdfUrl(url);
    setCert(cert); // Update cert in state
    setDate(date); // Update date in state
  }

  useEffect(() => {
    console.log("Current Page Number:", pageNumber);
  }, [pageNumber]);
  return (
    <div className="app-container-main-pdf">
      <h2>FFIEC Call Report for {loadName} {selectedYear.label} - {selectedQuarter.label}</h2>
      <div className="search-container-main-pdf">
      <div className="search-input-wrapper">
        <input
          type="text"
          placeholder="FFIEC Call Report"
          value={searchTerm}
          onChange={handleSearch}
        />
        <button onClick={() => { if (results.length > 0) setResults([]); }}>
          &#128269;
        </button>
        <div className="results-container-main-pdf">
            {results.map((result, index) => {
              const bankNameBeforeComma = result.data.NAME.split(',')[0].trim();
              return (
                <div
                    className="mytext-main-pdf"
                    key={result.data.ID}
                    onClick={() => handleResultClick(result.data.NAME, result.data.ID, selectedQuarter.value, selectedYear.value)}
                    style={{
                        display: 'block',
                        cursor: 'pointer',
                        padding: '5px 0',
                        backgroundColor: 'white',
                    }}
                >

                  {bankNameBeforeComma} ID:({result.data.ID})
                </div>
              );
            })}
          </div>
        </div>
        <div className="dropdown-container-auto">
        <label className="dropdown-label-auto">Select a Year:</label>
        <Select 
          className="custom-dropdown-auto"
          options={years} 
          value={selectedYear}
          onChange={value => {
              setSelectedYear(value);
              handleDateChange(cert, selectedQuarter.value, value.value);
          }}
          placeholder="Select Year"
      />

      <label className="dropdown-label-auto">Select a Quarter:</label>
      <Select 
          className="custom-dropdown-auto"
          options={quarters} 
          value={selectedQuarter}
          onChange={value => {
              setSelectedQuarter(value);
              handleDateChange(cert, value.value, selectedYear.value);
          }}
          placeholder="Select Quarter"
      />
    </div>
      </div>
      <div className="content-section">
        <div className="sidebar">
         
        </div>
        <div className="pdf-wrapper">
          <div className="pdf-toc-wrapper">
            <PdfToC toc={pdfToc} jumpToPage={jumpToPage} />
          </div>
          <div className="pdf-viewer-wrapper">
            {iframeUrl && (
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                    defaultScale={1.2}
                    fileUrl={iframeUrl}
                    initialPage={0}
                    plugins={[pageNavigationPluginInstance]}
                    viewMode = {ViewMode.SinglePage}
                  />
              </Worker>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
}  
export default ViewerSearch;