export const performanceRatios= [
    'NTINCL', 'NTINCHPP', 'INTINCY', 'INTEXPY', 'NIMY', 'NONIIAY', 'NONIXAY', 'ELNATRY', 'NOIJY', 'ROA', 'ROAPTX', 
    'ROE', 'ROEINJR', 'NTLNLSR', 'NTRER', 'NTRECOSR', 'NTRENRSR', 'NTREMULR', 'NTRERESR', 'NTRELOCR', 'NTREOTHR', 
    'IDNTCIR', 'IDNTCONR', 'IDNTCRDR', 'IDNTCOOR', 'NTAUTOPR', 'NTCONOTR', 'NTALLOTHR', 'NTCOMRER', 'ELNANTR', 
    'IDERNCVR', 'EEFFR', 'ASTEMPM', 'EQCDIVNTINC', 'ERNASTR', 'LNATRESR', 'LNRESNCR', 'NPERFV', 'NCLNLSR', 'NCRER', 
    'NCRECONR', 'NCRENRER', 'NCREMULR', 'NCRERESR', 'NCRELOCR', 'NCREREOR', 'IDNCCIR', 'IDNCCONR', 'IDNCCRDR', 
    'IDNCCOOR', 'IDNCATOR', 'IDNCCOTR', 'IDNCOTHR', 'NCCOMRER', 'IDNCGTPR', 'LNLSNTV', 'LNLSDEPR', 'IDLNCORR', 
    'DEPDASTR', 'EQV', 'RBC1AAJ', 'CBLRIND', 'IDT1CER', 'IDT1RWAJR', 'RBCRWAJ'
  ];

export const rowData = [
    {
      label: '1. % of Unprofitable Institutions',
      valueKey: 'NTINCL',
      index: 1,
      level: 0
    },
    {
      label: '2. % of Institutions with Earnings Gains',
      valueKey: 'NTINCHPP',
      index: 2,
      level: 0
    },
    {
      label: '3. Performance Ratios (%, annualized)',
      valueKey: 'N/A',
      index: 3,
      level: 0,
      children: [
      {
        label: 'Yield on Earning Assets',
        valueKey: 'INTINCY',
        index: 4,
        level: 1
      },
      {
        label: 'Cost of Funding Earning Assets',
        valueKey: 'INTEXPY',
        index: 5,
        level: 1
      },
      {
        label: 'Net Interest Margin',
        valueKey: 'NIMY',
        index: 6,
        level: 1
      },
      {
        label: 'Noninterest Income to Average Assets',
        valueKey: 'NONIIAY',
        index: 7,
        level: 1
      },
      {
        label: 'Noninterest Expense to Average Assets',
        valueKey: 'NONIXAY',
        index: 8,
        level: 1
      },
      {
        label: 'Credit Loss Provision to Assets',
        valueKey: 'ELNATRY',
        index: 9,
        level: 1
      },
      {
        label: 'Net Operating Income to Assets',
        valueKey: 'NOIJY',
        index: 10,
        level: 1
      },
      {
        label: 'Return on Assets',
        valueKey: 'ROA',
        index: 11,
        level: 1
      },
      {
        label: 'Pretax Return on Assets',
        valueKey: 'ROAPTX',
        index: 12,
        level: 1
      },
      {
        label: 'Return on Equity',
        valueKey: 'ROE',
        index: 13,
        level: 1
      },
      {
        label: 'Retained Earnings to Average Equity (YTD only)',
        valueKey: 'ROEINJR',
        index: 14,
        level: 1
      },
      {
        label: 'Net Charge-Offs to Loans and Leases',
        valueKey: 'NTLNLSR',
        index: 15,
        level: 1,
        children: [
        {
          label: 'Total Real Estate Loans',
          valueKey: 'NTRER',
          index: 16,
          level: 2,
          children: [
          {
            label: 'Construction and Development',
            valueKey: 'NTRECOSR',
            index: 17,
            level: 3
          },
          {
            label: 'Nonfarm Nonresidential',
            valueKey: 'NTRENRSR',
            index: 18,
            level: 3
          },
          {
            label: 'Multifamily Residential Real Estate',
            valueKey: 'NTREMULR',
            index: 19,
            level: 3,
            children: [
            {
              label: '1-4 Family Residential',
              valueKey: 'NTRERESR',
              index: 20,
              level: 4
            },
            {
              label: 'Home Equity',
              valueKey: 'NTRELOCR',
              index: 21,
              level: 4
            },
            {
              label: 'All Other 1-4 Family',
              valueKey: 'NTREOTHR',
              index: 22,
              level: 4
            },
            ],
          },
          ],
        },
        {
          label: 'Commercial & Industrial Loans',
          valueKey: 'IDNTCIR',
          index: 23,
          level: 2
        },
        {
          label: 'Loans to Individuals',
          valueKey: 'IDNTCONR',
          index: 24,
          level: 2,
          children: [
          {
            label: 'Credit Cards & Related Plans',
            valueKey: 'IDNTCRDR',
            index: 25,
            level: 3
          },
          {
            label: 'Other Loans to Individuals',
            valueKey: 'IDNTCOOR',
            index: 26,
            level: 3,
            children: [
            {
              label: 'Automobile Loans',
              valueKey: 'NTAUTOPR',
              index: 27,
              level: 4
            },
            {
              label: 'Other Consumer Loans',
              valueKey: 'NTCONOTR',
              index: 28,
              level: 4
            },
            ],
          },
          ],
        },
        {
          label: 'All other Loans & Leases (Including Farm)',
          valueKey: 'NTALLOTHR',
          index: 29,
          level: 2
        },
        {
          label: 'Memo: Commercial Real Estate Loans Not Secured by Real Estate',
          valueKey: 'NTCOMRER',
          index: 30,
          level: 2
        },
        ],
      },
      {
        label: 'Loan and Lease Loss Provision to Net Charge-Offs',
        valueKey: 'ELNANTR',
        index: 31,
        level: 1
      },
      {
        label: 'Earnings Coverage of Net Loan Charge-Offs(x)',
        valueKey: 'IDERNCVR',
        index: 32,
        level: 1
      },
      {
        label: 'Efficiency Ratio',
        valueKey: 'EEFFR',
        index: 33,
        level: 1
      },
      {
        label: 'Assets Per Employee ($Millions)',
        valueKey: 'ASTEMPM',
        index: 34,
        level: 1
      },
      {
        label: 'Cash Dividends to Net Income (YTD Only)',
        valueKey: 'EQCDIVNTINC',
        index: 35,
        level: 1
      },
      ],
    },
    {
      label: '4. Condition Ratios (%)',
      valueKey: 'N/A',
      index: 36,
      level: 0,
      children: [
      {
        label: 'Earning Assets to total Assets',
        valueKey: 'ERNASTR',
        index: 37,
        level: 1
      },
      {
        label: 'Loss Allowance to Loans and Leases',
        valueKey: 'LNATRESR',
        index: 38,
        level: 1
      },
      {
        label: 'Loss Allowance to Noncurrent Loans and Leases',
        valueKey: 'LNRESNCR',
        index: 39,
        level: 1
      },
      {
        label: 'Noncurrent Assets Plus Other Real Estate Owned to Assets',
        valueKey: 'NPERFV',
        index: 40,
        level: 1
      },
      {
        label: 'Noncurrent Loans to Loans',
        valueKey: 'NCLNLSR',
        index: 41,
        level: 1,
        children: [
        {
          label: 'All Real Estate Loans',
          valueKey: 'NCRER',
          index: 42,
          level: 2,
          children: [
          {
            label: 'Construction and Development',
            valueKey: 'NCRECONR',
            index: 43,
            level: 3
          },
          {
            label: 'Nonfarm Nonresidential',
            valueKey: 'NCRENRER',
            index: 44,
            level: 3
          },
          {
            label: 'Multifamily Residential Real Estate',
            valueKey: 'NCREMULR',
            index: 45,
            level: 3
          },
          {
            label: '1-4 Family Residential',
            valueKey: 'NCRERESR',
            index: 46,
            level: 3,
            children: [
            {
              label: 'Home Equity',
              valueKey: 'NCRELOCR',
              index: 47,
              level: 4
            },
            {
              label: 'All Other 1-4 Family',
              valueKey: 'NCREREOR',
              index: 48,
              level: 4
            },
            ],
          },
          ],
        },
        {
          label: 'Commercial & Industrial Loans',
          valueKey: 'IDNCCIR',
          index: 49,
          level: 2
        },
        {
          label: 'Loans to Individuals',
          valueKey: 'IDNCCONR',
          index: 50,
          level: 2,
          children: [
          {
            label: 'Credit Cards & Related Plans',
            valueKey: 'IDNCCRDR',
            index: 51,
            level: 3
          },
          {
            label: 'Other Loans to Individuals',
            valueKey: 'IDNCCOOR',
            index: 52,
            level: 3,
            children: [
            {
              label: 'Automobile Loans',
              valueKey: 'IDNCATOR',
              index: 53,
              level: 4
            },
            {
              label: 'Other Consumer Loans',
              valueKey: 'IDNCCOTR',
              index: 54,
              level: 4
            },
            ],
          },
          ],
        },
        {
          label: 'All other Loans & Leases (Including Farm)',
          valueKey: 'IDNCOTHR',
          index: 55,
          level: 2
        },
        {
          label: 'Memo: Commercial Real Estate Loans Not Secured by Real Estate',
          valueKey: 'NCCOMRER',
          index: 56,
          level: 2
        },
        {
          label: 'Noncurrent Loans Which are Wholly or Partially Guaranteed by the U.S. Government',
          valueKey: 'IDNCGTPR',
          index: 57,
          level: 2
        },
        ],
      },
      {
        label: 'Net Loans and Leases to Assets',
        valueKey: 'LNLSNTV',
        index: 58,
        level: 1
      },
      {
        label: 'Net Loans and Leases to Deposits',
        valueKey: 'LNLSDEPR',
        index: 59,
        level: 1
      },
      {
        label: 'Net Loans and Leases to Core Deposits',
        valueKey: 'IDLNCORR',
        index: 60,
        level: 1
      },
      {
        label: 'Domestic Deposits to Total Assets',
        valueKey: 'DEPDASTR',
        index: 61,
        level: 1
      },
      {
        label: 'Equity Capital to Assets',
        valueKey: 'EQV',
        index: 62,
        level: 1
      },
      {
        label: 'Leverage (Core Capital) Ratio',
        valueKey: 'RBC1AAJ',
        index: 63,
        level: 1
      },
      {
        label: 'Common Equity Tier 1 Capital Ratio',
        valueKey: 'IDT1CER',
        index: 64,
        level: 1
      },
      {
        label: 'Tier 1 Risk-Based Capital Ratio',
        valueKey: 'IDT1RWAJR',
        index: 65,
        level: 1
      },
      {
        label: 'Total Risk-Based Capital Ratio',
        valueKey: 'RBCRWAJ',
        index: 66,
        level: 1
      },
      ],
    },
  ];