/*
import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import './CommercialTable.css';
import CommercialSearch from './CommercialSearch';
import FilterDropdown from './FilterDropdown';
import { createPopper } from '@popperjs/core';


const CommercialTable = ({ style }) => {
  const [data, setData] = useState([]);
  const [year, setYear] = useState('2023');
  const [quarter, setQuarter] = useState('09-30');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 25; // Set the number of items per page
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useRef(null);

  useEffect(() => {
    if (referenceElement.current && popperElement) {
      createPopper(referenceElement.current, popperElement, {
        placement: 'bottom-start',  // this places the dropdown below the reference element
      });
    }
  }, [popperElement]);

  const fetchData = async (year, quarter, page) => {
    try {
      const response = await axios.get(`/api/commercial-re/?REPDTE=${year}-${quarter}&order_by_ratio_commercial=-ratio_commercial&page=${page}&${filterType}=${filterValue}`);
      const commercialData = response.data.results;
      const count = response.data.count; // Fetch the total count from the API response
      setTotalCount(count); 

      const requestPromises = commercialData.map(async (row) => {
        const cert = row.CERT;
        const nameRequest = axios.get(`https://banks.data.fdic.gov/api/institutions?filters=CERT%3A${cert}&fields=NAME&limit=10&offset=0&format=json&download=false&filename=data_file`);
        const financialsRequest = axios.get(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${cert}&fields=CERT%2CEQTOT%2CREPDTE%2CP3RECONSR%2CP9RECONS%2CLNREMULT%2CP3REMULT%2CP9REMULT%2CLNRECONS%2CLNRENRES&sort_by=REPDTE&sort_order=DESC&limit=156&offset=0&format=json&download=false&filename=data_file`);
        const [nameResult, financialsResult] = await Promise.all([nameRequest, financialsRequest]);
        const institutionName = nameResult.data.data[0]?.data?.NAME || `FDIC CERT:${row.CERT}`;
        const institutionNameWithoutComma = institutionName.split(',')[0];
        const financialsData = financialsResult.data.data[0]?.data;
        return {
          ...row,
          NAME: institutionNameWithoutComma,
          ...financialsData,
        };
      });

      const updatedData = await Promise.all(requestPromises);
      setData(updatedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatCurrency = (value) => {
    if (!value) return '$0';
    const newValue = value * 1000;
    const formattedCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(newValue);
  
    // Remove the decimal part
    return formattedCurrency.replace(/(\.\d{2})/, '');
  };
  

  useEffect(() => {
    fetchData(year, quarter, currentPage);
  }, [year, quarter, currentPage]);
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleQuarterChange = (event) => {
    setQuarter(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  
  useEffect(() => {
    fetchData(year, quarter, currentPage);
  }, [year, quarter, currentPage, filterType, filterValue]);
  const years = Array.from({ length: 2023 - 2001 + 1 }, (_, i) => 2001 + i);

  const quarterMapping = {
    '12-31': 'Q4',
    '09-30': 'Q3',
    '06-30': 'Q2',
    '03-31': 'Q1',
  };

  const renderEmptyRows = (numRows) => {
    const remainingRows = 25 - numRows;
    const emptyRows = [];
  
    for (let i = 0; i < remainingRows; i++) {
      emptyRows.push(
        <tr key={`empty-row-${i}`} style={{height: '28px'}}>
          <td colSpan="13"></td>
        </tr>
      );
    }
  
    return emptyRows;
  };

  return (
    <div style={style}>
      <h2>Exposure to Commercial Real Estate {year} - {quarterMapping[quarter]}</h2>
      <div className="filter-container">
        <div className="filter-item">
          <label htmlFor="year">Select year:</label>
          <select className="nav-link dropdown-toggle show" id="year" value={year} onChange={handleYearChange}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="quarter">Select quarter:</label>
          <select className="nav-link dropdown-toggle show" id="quarter" value={quarter} onChange={handleQuarterChange}>
            {Object.entries(quarterMapping).map(([quarterValue, quarterDisplay]) => (
              <option key={quarterValue} value={quarterValue}>
                {quarterDisplay}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item filter-dropdown">
          <FilterDropdown
            filterType={filterType}
            setFilterType={setFilterType}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        </div>
      </div>
      {data.length > 0 && (
          <>
              <div className="table-container">
                  <table className='table table-hover custom-table-cre'>
                  
              <thead>
                <tr>
                  <th className='sticky-column-com' style={{maxWidth: '275px'}}>
                  <div className="name-text" style={{ display: 'flex', alignItems: 'center' }}>
                        Name
                        <div className="scaled-down" style={{ marginLeft:'-18px' }}>
                            <CommercialSearch quarter={`${year}-${quarter}`} />
                        </div>
                    </div>
                  </th>
                  <th>Total Assets</th>
                  <th>Total Equity</th>
                  <th>Commercial RE Loans</th>
                  <th>Commercial RE Loans / Equity%</th>
                  <th>Construction RE Loans</th>
                  <th>Construction RE Loans / Equity%</th>
                  <th>30-90 Days Construction RE P/D</th>
                  <th>90+ Days Construction RE P/D</th>
                  <th>Multifamily RE Loans</th>
                  <th>30-90 Multifamily RE LN P/D</th>
                  <th>90+ Days Multifamily RE LN P/D</th>
                  <th>Non Performing RE Loans / Total RE Loans</th>
                </tr>
              </thead>
                      <tbody>
                        {data.map((row) => (
                          <tr key={row.CERT}>
                            <td className="sticky-column-com" style={{maxWidth: '275px'}}>
                              <div className="ellipsis">{row.NAME}</div>
                            </td>
                            <td className = "number-cell">{formatCurrency(row.ASSET)}</td>
                            <td className = "number-cell"> {formatCurrency(row.EQTOT)}</td>
                            <td className = "number-cell">{formatCurrency(row.LNRENRES)}</td>
                            <td className = "number-cell">{(row.LNRENRES/row.EQTOT)}%</td>
                            <td className = "number-cell">{formatCurrency(row.LNRECONS)}</td>
                            <td className = "number-cell">{(row.RATIOCONSTRUCTION)}%</td>
                            <td className = "number-cell">{formatCurrency(row.P3RECONSR)}</td>
                            <td className = "number-cell">{formatCurrency(row.P9RECONS)}</td>
                            <td className = "number-cell">{formatCurrency(row.LNREMULT)}</td>
                            <td className = "number-cell">{formatCurrency(row.P3REMULT)}</td>
                            <td className = "number-cell">{formatCurrency(row.P9REMULT)}</td>
                            <td className = "number-cell">{(row.RATIOLOANS)}</td>
                          </tr>
                        ))}
                      </tbody>
                      {renderEmptyRows(data.length)}
                  </table>
                
              </div>
              <div>   
                    <button  
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className= "btn btn-primary btn-sm pagination-button"
                        style={{ backgroundColor:'royalblue' }}
                    >
                        Previous
                    </button>
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button  
                        onClick={handleNextPage}
                        disabled={data.length < 25}
                        className= "btn btn-primary btn-sm pagination-button"
                        style={{ backgroundColor:'royalblue' }}

                    >
                        Next
                    </button>
               </div>
          </>
      )}
  </div>
); 
}
export default CommercialTable;
*/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import CommercialSearch from './CommercialSearch';


const CommercialTable = ({ style }) => {
  
  const currentDate = new Date();
  const [sortColumn, setSortColumn] = useState('LNRENRES');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [isRatioSort, setIsRatioSort] = useState(false);


  function getDefaultQuarter() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const month = currentDate.getMonth();
    let defaultQuarter;

    if (month < 3) {  // January, February, March
        defaultQuarter = '0930'; // Q3 of the previous year
        currentYear--;
    } else if (month < 6) {  // April, May, June
        defaultQuarter = '1231'; // Q4 of the previous year
        currentYear--;
    } else if (month < 9) {  // July, August, September
        defaultQuarter = '0331'; // Q1 of the current year
    } else {  // October, November, December
        defaultQuarter = '0630'; // Q2 of the current year
    }

    const endOfSelectedQuarter = new Date(currentYear, parseInt(defaultQuarter.substring(0, 2)) - 1, parseInt(defaultQuarter.substring(2, 4)));
    if ((currentDate - endOfSelectedQuarter) / (1000 * 60 * 60 * 24) < 70) {
        if (defaultQuarter === '0331') {
            defaultQuarter = '1231';
            currentYear--;
        } else if (defaultQuarter === '0630') {
            defaultQuarter = '0331';
        } else if (defaultQuarter === '0930') {
            defaultQuarter = '0630';
        } else if (defaultQuarter === '1231') {
            defaultQuarter = '0930';
        }
    }

    return { year: currentYear, quarter: defaultQuarter };
}


const { year, quarter } = getDefaultQuarter();
  
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const years = [];
  for (let y = currentDate.getFullYear(); y >= 1998; y--) {
    years.push({ value: y, label: y.toString() });
  }

  const quarters = [
    { value: '0331', label: 'Q1' },
    { value: '0630', label: 'Q2' },
    { value: '0930', label: 'Q3' },
    { value: '1231', label: 'Q4' },
  ];

  const [selectedYear, setSelectedYear] = useState({ value: year, label: year.toString() });
  const [selectedQuarter, setSelectedQuarter] = useState({ value: quarter, label: quarters.find(q => q.value === quarter).label });
  const columns = [
    { "id": "NAME", "title": "Name", "definition": "" },
    { "id": "ASSET", "title": "Total Assets", "definition": "Total Assets" },
    { "id": "EQTOT", "title": "Total Equity", "definition": "Total Equity" },
    // Loans for CRE - Overview
    { "id": "LNRENRES", "title": "Commercial Real Estate Loans" },
    { "id": "LNRENRES/EQTOT", "title": "Commercial RE Loans / Equity" },
    { "id": "LNRECONS", "title": "Construction and Land Development Real Estate Loans" },
    { "id": "LNRECONS/EQTOT", "title": "Construction Loans / Equity" },
    { "id": "LNREMULT", "title": "Multifamily Residential Real Estate Loans" },
    { "id": "LNRERES", "title": "1-4 Family Residential Real Estate Loans" },
    { "id": "LNREAG", "title": "Farmland Real Estate Loans" },
    { "id": "LNCOMRE", "title": "Commercial Real Estate Loans not Secured by Real Estate" },
    { "id": "LNRENROW", "title": "Commercial Real Estate Owner-Occupied" },
    { "id": "LNRENROT", "title": "Commercial Real Estate Other Non-Owner-Occupied" },
    { "id": "ORENRES", "title": "Foreclosed / Repossessed CRE" },
    { "id": "RATAIOLOANS", "title": "Non-Performing Real Estate Loans to Total Real Estate Loans" },
    { "id": "P3RENRES", "title": "Commercial Real Estate Past Due 30 - 89 Days" },	
    { "id": "P9RENRES", "title": "Commercial Real Estate Past Due 90+ Days" },	
    { "id": "P3RECONSR", "title": "Construction and Land Development, Past Due 30 - 89 Days" },
    { "id": "P3REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 30 - 89 Days" },
    { "id": "P9RECONS", "title": "Construction and Land Development, Past Due 90+ Days" },
    { "id": "P9REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 90+ Days" }

    
];

const fetchedREPDTE = `${selectedYear.value}${selectedQuarter.value}`;

const sortData = (data, column, order) => {
  return [...data].sort((a, b) => {
    // Adjust how you access the values for sorting
    const key = column.replace('/EQTOT', '_EQTOT');
    const valueA = a.data[key];
    const valueB = b.data[key];

    // Compare values; no need for toLowerCase as they are numeric
    if (order === 'ASC') {
      return valueA > valueB ? 1 : (valueA < valueB ? -1 : 0);
    } else {
      return valueA < valueB ? 1 : (valueA > valueB ? -1 : 0);
    }
  });
};


const handleColumnClick = (columnId) => {
  if (columnId === "NAME") {
    // Do nothing if the NAME column is clicked
    return;
  }

  const isRatioColumn = ["LNRENRES/EQTOT", "LNRECONS/EQTOT", "RATAIOLOANS"].includes(columnId);

  if (isRatioColumn) {
    // Frontend sorting for ratio columns
    const newOrder = sortOrder === 'DESC' ? 'ASC' : 'DESC';
    const sortedData = sortData(data, columnId.replace('/EQTOT', '_EQTOT'), newOrder);
    setData(sortedData);
    setSortOrder(newOrder);
    setIsRatioSort(true);
  } else {
    // Backend sorting for other columns
    const newOrder = sortColumn === columnId && sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setSortColumn(columnId);
    setSortOrder(newOrder);
    setIsRatioSort(false);
  }
};




useEffect(() => {
  if (!isRatioSort) {
    // Fetch data from API only if it's not a ratio sort
    const fieldIds = columns.map(column => column.id).join('%2C');
    const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=REPDTE%3A${fetchedREPDTE}&fields=${fieldIds}%2CCERT%2CNARE%2CP9RE%2CLNRE&sort_by=${sortColumn}&sort_order=${sortOrder}&limit=10000&format=json&download=false&filename=data_file`;
    console.log(apiUrl);
    axios.get(apiUrl)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const updatedData = calculateAndAppendRatios(response.data.data);
          setData(updatedData);
          setErrorMessage('');
        } else if (response.data && response.data.meta && response.data.meta.total === 0) {
          setErrorMessage("Data not available for the selected quarter");
          setData([]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setErrorMessage("Error fetching data");
        setData([]);
      });
  }
}, [selectedYear, selectedQuarter, sortColumn, sortOrder, isRatioSort]);

  const PAGE_SIZE = 25;
  const [currentPage, setCurrentPage] = useState(0);

  const numberOfPages = Math.ceil(data.length / PAGE_SIZE);

  const paginatedData = data.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

  const formatDollarValue = (value) => {
    if (value == null || isNaN(value)) return '$0';
    const trueValue = Math.round(value * 1000);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(trueValue);
  };
  
  const formatRatioValue = (value) => {
    if (value === Infinity || value === -Infinity || isNaN(value)) {
      return 'N/A'; // Return 'N/A' for undefined or NaN values
    }
    return parseFloat(value).toFixed(2) + "%";
  };
  
  
  
  const toTitleCase = (str) => {
    if (typeof str !== 'string') return '';
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  
  // Calculate Ratios and Append to Data
  const calculateAndAppendRatios = (data) => {
    return data.map(item => {
      const EQTOT = item.data["EQTOT"] || 0;
      const LNRENRES_EQTOT = EQTOT === 0 ? Infinity : (item.data["LNRENRES"] / EQTOT) * 100;
      const LNRECONS_EQTOT = EQTOT === 0 ? Infinity : (item.data["LNRECONS"] / EQTOT) * 100;
      const RATAIOLOANS = (item.data["LNRE"] === 0) ? Infinity : ((item.data["NARE"] + item.data["P9RE"]) / item.data["LNRE"]) * 100;
  
      return { ...item, data: { ...item.data, LNRENRES_EQTOT, LNRECONS_EQTOT, RATAIOLOANS } };
    });
  };

  return (
    <div style={style} className="auto-loans-container">
        <h2>Commercial Real Estate Data for {selectedYear.label} - {selectedQuarter.label}</h2>
        
        {errorMessage && <div className="error-message">{errorMessage}</div>}
    
        <div className="dropdown-container-auto">
            <label className="dropdown-label-auto">Select a Year:</label>
            <Select 
                className="custom-dropdown-auto"
                options={years} 
                value={selectedYear}
                onChange={value => setSelectedYear(value)}
                placeholder="Select Year"
            />

            <label className="dropdown-label-auto">Select a Quarter:</label>
            <Select 
                className="custom-dropdown-auto"
                options={quarters} 
                value={selectedQuarter}
                onChange={value => setSelectedQuarter(value)}
                placeholder="Select Quarter"
            />
        </div>

        {!errorMessage && (
            <>
                <div className="table-container">
                    <table className="table table-hover custom-table-auto custom-table-auto--first">
                    <thead>
                        <tr>
                            {columns.map((col, idx) => (
                                <th key={col.id}
                                    className={idx === 0 ? 'sticky-column' : ''}
                                    title={col.definition}
                                    onClick={() => handleColumnClick(col.id)}
                                    style={{ fontSize: '11px', cursor:'pointer' }}>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <span style={{ marginRight: '15px' }}>{col.title}</span>
                                        {col.id === "NAME" && <CommercialSearch REPDTE={fetchedREPDTE} />}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                        <tbody>
                        {paginatedData.map(item => (
                        <tr key={item.data.CERT}>
                              {columns.map((col, idx) => (
                                <td key={col.id} className={idx === 0 ? 'sticky-column' : ''}>
                                    {(() => {
                                        // Check if the column is 'NAME'
                                        if (col.id === "NAME") {
                                            return toTitleCase(item.data[col.id]);
                                        } else if (col.id.includes('/EQTOT') || col.id === "RATAIOLOANS") {
                                            // Handle ratio columns
                                            return formatRatioValue(item.data[col.id.replace('/EQTOT', '_EQTOT')]);
                                        } else {
                                            // Handle other columns as dollar values
                                            return formatDollarValue(item.data[col.id]);
                                        }
                                    })()}
                                </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                </div>
                <div className="pagination-controls">
                    <button 
                        onClick={() => setCurrentPage(page => Math.max(0, page - 1))}
                        disabled={currentPage === 0}
                        className= "btn btn-primary btn-sm"
                        style={{ fontSize: '0.7rem', paddingTop: '4px', paddingBottom: '4px', backgroundColor:'royalblue'}} 
                    >
                        Previous
                    </button>
                    <span>Page {currentPage + 1} of {numberOfPages}</span>
                    <button 
                        onClick={() => setCurrentPage(page => Math.min(numberOfPages - 1, page + 1))}
                        disabled={currentPage === numberOfPages - 1}
                        className= "btn btn-primary btn-sm"
                        style={{ fontSize: '0.7rem', paddingTop: '4px', paddingBottom: '4px', backgroundColor:'royalblue' }} 
                    >
                        Next
                    </button>
                </div>
            </>
        )}
    </div>
);

};

export default CommercialTable;
