import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Rating from './Rating'
import './Categories.css';

function Categories({categories}) {
  return (
    <Card className = "my-3 p-3 rounded card-custom card-separation">
        <Link to ={`/categories/${categories._id}`}>
            <Card.Img src = {categories.image}/> 
        </Link>
        <Card.Body>
            <Link to ={`/categories/${categories._id}`} style={{textDecoration:'none'}} >
                <Card.Title as ="div" >
                    <strong>{categories.name}</strong>
                </Card.Title>
            </Link>
            {/*<Card.Text as = "div">
                <div className = "my-3">
                    {categories.rating} from {categories.numReviews} reviews
                    <Rating value = {categories.rating} text= {`${categories.numReviews} reviews`}
                    color= {'#f8e825'}
                    />
                </div>
              </Card.Text>
            
            <Card.Text as = "h3">
                ${categories.price}
             </Card.Text>*/}
            
        </Card.Body>
    </Card>
  )
}

export default Categories