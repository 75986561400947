import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS,Title,Legend } from 'chart.js';
import axios from 'axios';

const BarGraph = ({ CERT, REPDTE }) => {
  const [EquitychartData, setEquityChartData] = useState({});
  const [AutochartData, setAutoChartData] = useState({});
  const [IndividualchartData, setIndividualChartData] = useState({});
  const [DepositchartData, setDepositChartData] = useState({});
  const [UnrealizedchartData, setUnrealizedChartData] = useState({});
  const [CommercialchartData, setCommercialChartData] = useState({});




  ChartJS.register(Title,Legend);
  
  const formatYAxisValue = (value) => {
    // Take the absolute value for calculation
    let number = Math.abs(value);
    let suffix = '';
  
    if (number >= 1e9) {
      number /= 1e9;
      suffix = 'B';
    } else if (number >= 1e6) {
      number /= 1e6;
      suffix = 'M';
    } else if (number >= 1e3) {
      number /= 1e3;
      suffix = 'K';
    }
  
    // Add back the sign of the original value
    const sign = value < 0 ? '-' : '';
    return sign + '$' + number.toFixed(1) + suffix;
  };
  
  useEffect(() => {
    async function fetchEquityData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=EQTOT%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.EQTOT * 1000);
  
        const EquitychartData = {
          labels: labels,
          datasets: [{
            label: 'Total Equity',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", EquitychartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setEquityChartData(EquitychartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT && REPDTE) {
      fetchEquityData();
    }
  }, [CERT, REPDTE]);
  

  const Equityoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Equity', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  useEffect(() => {
    async function fetchAutoData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNAUTO%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNAUTO * 1000);
  
        const AutochartData = {
          labels: labels,
          datasets: [{
            label: 'Auto Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
    
        setAutoChartData(AutochartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT && REPDTE) {
      fetchAutoData();
    }
  }, [CERT, REPDTE]);
  

  const Autooptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Auto Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      
      
      
  };

    
  useEffect(() => {
    async function fetchIndividualData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNCON%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNCON* 1000);
  
        const IndividualchartData = {
          labels: labels,
          datasets: [{
            label: 'Consumer Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", IndividualchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setIndividualChartData(IndividualchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT && REPDTE) {
      fetchIndividualData();
    }
  }, [CERT, REPDTE]);
  

  const Individualoptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Consumer Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  useEffect(() => {
    async function fetchDepositData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=DEP%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.DEP* 1000);
  
        const DepositchartData = {
          labels: labels,
          datasets: [{
            label: 'Deposits',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", DepositchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setDepositChartData(DepositchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT && REPDTE) {
      fetchDepositData();
    }
  }, [CERT, REPDTE]);
  

  const Depositoptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Deposits', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  useEffect(() => {
    async function fetchUnrealizedData() {
        try {
            const response = await axios.get(`/api/quloss/?CALLYMD=&CERT=${CERT}&ordering=-CALLYMD`);
            const results = response.data.results.slice(0, 12).reverse();

            const labels = results.map(item => item.CALLYMD);
            const data = results.map(item => (item.unrealized_gl*1000));

            const unrealizedChartData = {
                labels: labels,
                datasets: [{
                    label: 'Unrealized Gains/Losses',
                    data: data,
                    backgroundColor: data.map(value => value >= 0 ? 'rgba(54, 162, 235, 0.2)' : 'rgba(255, 99, 132, 0.2)'), // Specific blue color for positive
                    borderColor: data.map(value => value >= 0 ? 'rgba(54, 162, 235, 1)' : 'rgba(255, 99, 132, 1)'), // Specific blue color for positive
                    borderWidth: 1
                }]
            };

            console.log("Chart Data: ", unrealizedChartData); // Log the chart data

            setUnrealizedChartData(unrealizedChartData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    if (CERT) {
        fetchUnrealizedData();
    }
}, [CERT]);

const Unrealizedoptions = {
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Unrealized G/L', // Label for Y axis
            },
            ticks: {
                callback: formatYAxisValue
            }
        },
        x: {
            title: {
                display: true,
                text: 'Reporting Period', // Label for X axis
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            labels: {
                generateLabels: function(chart) {
                    // Custom legend labels
                    return [
                        {
                            text: 'Gains',
                            fillStyle: 'rgba(54, 162, 235, 0.2)', // Blue color for gains
                        },
                        {
                            text: 'Losses',
                            fillStyle: 'rgba(255, 99, 132, 0.2)', // Red color for losses
                        }
                    ];
                }
            }
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
    },
};
useEffect(() => {
    async function fetchCommercialData() {
      try {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=LNRENRES%2CREPDTE&sort_by=REPDTE&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file`);
        const json = await response.json();
        
        // Get only the last 12 items
        const lastTwelveItems = json.data.slice(-12);
  
        const labels = lastTwelveItems.map(item => item.data.REPDTE);
        const data = lastTwelveItems.map(item => item.data.LNRENRES* 1000);
  
        const CommercialchartData = {
          labels: labels,
          datasets: [{
            label: 'Commercial RE Loans',
            data: data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        };
  
        console.log("Chart Data: ", IndividualchartData); // Log the chart data
        console.log("labels: ", labels); // Log the chart data

        setCommercialChartData(CommercialchartData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    if (CERT && REPDTE) {
      fetchCommercialData();
    }
  }, [CERT, REPDTE]);
  

  const Commercialoptions = {
    responsive: true, // This makes the chart responsive
    maintainAspectRatio: false, // Optional: set to false to remove fixed aspect ratio
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'CRE Loans', // Label for Y axis
        },
        ticks: {
            callback: formatYAxisValue
          }
      },
      x: {
        title: {
          display: true,
          text: 'Reporting Period', // Label for X axis
        }
      }
    },
    plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
    
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },    
  };

  return (
    <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '50px', marginTop:'75px' }}>
      {EquitychartData && EquitychartData.labels ? 
        <div><Bar data={EquitychartData} options={Equityoptions} /></div>
        : <p>Loading Equity chart...</p>
      }
      {AutochartData && AutochartData.labels ? 
        <div><Bar data={AutochartData} options={Autooptions} /></div>
        : <p>Loading Auto Loans chart...</p>
      }
      {IndividualchartData && IndividualchartData.labels ? 
        <div><Bar data={IndividualchartData} options={Individualoptions} /></div>
        : <p>Loading Individual Loans chart...</p>
      }
      {DepositchartData && DepositchartData.labels ? 
        <div><Bar data={DepositchartData} options={Depositoptions} /></div>
        : <p>Loading Deposits chart...</p>
      }
      {UnrealizedchartData && UnrealizedchartData.labels ? 
        <div><Bar data={UnrealizedchartData} options={Unrealizedoptions} /></div>
        : <p>Loading Unrealized GL chart...</p>
      }
        {CommercialchartData && CommercialchartData.labels ? 
         <div><Bar data={CommercialchartData} options={Commercialoptions} /></div>
      : <p>Loading Commercial RE Loans chart...</p>
    }
    </div>
  );
  
  
};

export default BarGraph;
