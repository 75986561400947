import React from 'react';
import './PdfToc.css'

const PdfToC = ({ toc, jumpToPage }) => {
  const handleRowClick = (page) => {
    console.log(`Row clicked. Going to page ${page}`);
    jumpToPage(page, { scrollToTop: true });
  };

  return (
    <div className="pdf-toc">
      <table className="table table-hover custom-table-pdf">
        <thead>
          <tr>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
        {toc.map((item, index) => (
          <tr key={index} onClick={() => handleRowClick(item.page)}>
            <td>
              {item.title.replace(/\(Form Type - \w+\)/g, '').trim()}
              {(!item.title.includes("Cover Page") && item.title !== "Table of Contents") && `......Page ${item.page}`}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default PdfToC;