import { createStore, combineReducers, applyMiddleware } from 'redux';
import { configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { categoryListReducer , categoryDetailsReducer } from './reducers/categoryReducers';

const reducer = combineReducers({
  categoryList: categoryListReducer, // Add the reducer here
  categoryDetails : categoryDetailsReducer, // Add the reducer here
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
