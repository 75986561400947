/*
import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import './CommercialSearch.css';

function CommercialSearch(props) {
  const { quarter } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [financialsDataList, setFinancialsDataList] = useState([]);
  const tableRef = useRef(null);  // Reference to the table element
  const [toastHeight, setToastHeight] = useState(null);  // State to hold the toast height
  const someExtraHeight = 20;  // Additional height to account for padding, borders, etc.

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  // Reset highlightedIndex when results change
  useEffect(() => {
    setHighlightedIndex(-1);
  }, [results]);

  // ... existing code

  // New function to handle keyboard events
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (highlightedIndex < results.length - 1) {
        setHighlightedIndex(prevIndex => prevIndex + 1);
      } else {
        setHighlightedIndex(0); // Loop back to the first result
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (highlightedIndex > 0) {
        setHighlightedIndex(prevIndex => prevIndex - 1);
      } else {
        setHighlightedIndex(results.length - 1); // Loop back to the last result
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedIndex >= 0) {
        const selectedResult = results[highlightedIndex];
        handleResultClick(selectedResult.data.NAME, selectedResult.data.ID);
      }
    }
  };
  
  

  useEffect(() => {
    // Add keydown event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [highlightedIndex, results]);

  useEffect(() => {
    // Check if the table has been rendered and is not null
    if(tableRef.current) {
      const tableHeight = tableRef.current.offsetHeight;

      // Set the toast height
      // You might want to add some extra height to account for padding, borders, etc.
      const newToastHeight = tableHeight + someExtraHeight;
      
      // Now set this height in state, which can be used to set the height of the toast body
      setToastHeight(newToastHeight);
    }
  }, [tableRef.current, someExtraHeight]);

  const handleEscapePress = (event) => {
    if (event.key === "Escape" && showToast) {
        closeToast();
    }
  };
  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleEscapePress);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
        document.removeEventListener("keydown", handleEscapePress);
    };
  }, [showToast]);  // Depend on the showToast state so the effect re-runs when showToast changes




  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=4&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    return data.data;
  }

  async function handleSearch(event) {
    const query = event.target.value;
    setSearchTerm(query);
    setSelectedResult(null); // Reset selectedResult when input changes

    if (query.length > 1) {
      const results = await searchAPI(query);
      setResults(results);
    } else {
      setResults([]);
    }
  }

  function clearSearch() {
    setSearchTerm('');
    setSelectedBankId(null);
  }

  async function handleBankSearch(name, id) {
    try {
        const response = await axios.get(`/api/commercial-re/?REPDTE=${quarter}&CERT=${id}`);
        const commercialData = response.data.results[0];
  
        if (!commercialData) {
            setToastMessage(`Bank not found for the quarter (${quarter}). It may have closed.`);
            setShowToast(true);
            return;
        }
  
        const cert = commercialData.CERT;
        const nameRequest = axios.get(`https://banks.data.fdic.gov/api/institutions?filters=CERT%3A${cert}&fields=NAME&limit=10&offset=0&format=json&download=false&filename=data_file`);
        const financialsRequest = axios.get(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${cert}&fields=CERT%2CREPDTE%2CP3RECONSR%2CP9RECONS%2CLNREMULT%2CP3REMULT%2CP9REMULT%2CASSET%2CEQTOT%2CLNCOMRE%2CLNRECONS%2CP9RE%2CNARE%2CLNRE&sort_by=REPDTE&sort_order=DESC&limit=20&offset=0&format=json&download=false&filename=data_file`);
        const [nameResult, financialsResult] = await Promise.all([nameRequest, financialsRequest]);
        const institutionName = nameResult.data.data[0]?.data?.NAME || `FDIC CERT:${commercialData.CERT}`;
        const institutionNameWithoutComma = institutionName.split(',')[0];
        const financialsData = financialsResult.data.data.map(item => ({
            ...item.data,
            NAME: institutionNameWithoutComma
        }));
  
        setFinancialsDataList(financialsData);
        
        const toastMessage = generateToastMessage(financialsData);
        setToastMessage(toastMessage);
        setShowToast(true);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

function generateToastMessage(dataList) {
  const quarterMapping = {
      '1231': 'Q4 ',
      '0930': 'Q3 ',
      '0630': 'Q2 ',
      '0331': 'Q1 ',
  };

  const transformDateToQuarter = (date) => {
      const quarterEnd = date.slice(-4);  // Extract MMDD from YYYYMMDD
      return quarterMapping[quarterEnd] + date.slice(0, 4);  // Get the quarter and append the year
  }

  const headers = dataList.map(data => transformDateToQuarter(data.REPDTE)).join('</th><th class="custom-font">');

  const rows = [
      `<tr><td class="sticky-metric custom-font">Total Assets</td><td class="custom-font">${dataList.map(data => formatCurrency(data.ASSET * 1000)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Total Equity</td><td class="custom-font">${dataList.map(data => formatCurrency(data.EQTOT * 1000)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Commercial RE Loans</td><td class="custom-font">${dataList.map(data => formatCurrency(data.LNCOMRE * 1000)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Commercial RE Loans / Equity%</td><td class="custom-font">${dataList.map(data => `${(data.EQTOT !== 0 ? Math.round((data.LNCOMRE / data.EQTOT) * 100 * 1000) / 1000 : 0)}%`).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Construction RE Loans</td><td class="custom-font">${dataList.map(data => formatCurrency(data.LNRECONS * 1000)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Construction RE Loans / Equity%</td><td class="custom-font">${dataList.map(data => `${(data.EQTOT !== 0 ? Math.round((data.LNRECONS / data.EQTOT) * 100 * 1000) / 1000 : 0)}%`).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">30-90 Days Construction RE P/D</td><td class="custom-font">${dataList.map(data => formatCurrency(data.P3RECONSR * 1000 || 0)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">90+ Days Construction RE P/D</td><td class="custom-font">${dataList.map(data => formatCurrency(data.P9RECONS * 1000 || 0)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Multifamily RE Loans</td><td class="custom-font">${dataList.map(data => formatCurrency(data.LNREMULT * 1000 || 0)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">30-90 Multifamily RE Ln P/D</td><td class="custom-font">${dataList.map(data => formatCurrency(data.P3REMULT * 1000 || 0)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">90+ Days Multifamily RE Ln P/D</td><td class="custom-font">${dataList.map(data => formatCurrency(data.P9REMULT * 1000 || 0)).join('</td><td class="custom-font">')}</td></tr>`,
      `<tr><td class="sticky-metric custom-font">Non Performing RE Loans / Total RE Loans</td><td class="custom-font">${dataList.map(data => `${(data.LNRE !== 0 ? Math.round(((data.P9RE + data.NARE) / data.LNRE) * 1000) / 1000 : 0)}`).join('</td><td class="custom-font">')}</td></tr>`
  ].join('');

  return `
  <div style="overflow-x: auto; height: 100%;">
      <table class="table table-hover" style="width: 100%; height: 89%;">
          <thead class="custom-font" ref={tableRef}>
              <tr>
                  <th class="sticky-metric custom-font" >Metric</th>
                  <th class="custom-font">${headers}</th>
              </tr>
          </thead>
          <tbody class="custom-font">
              ${rows}
          </tbody>
      </table>
  </div>
  `;
}

  useEffect(() => {
    if (selectedBankId && showToast) {
      handleBankSearch(selectedResult?.data?.NAME || searchTerm, selectedBankId);
    }
  }, [quarter, selectedBankId, selectedResult, searchTerm, showToast]);

  function formatCurrency(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,  // Added this to avoid decimals
        maximumFractionDigits: 0   // Added this to avoid decimals
    });

    const formattedParts = formatter.formatToParts(amount);

    if (amount < 0) {
        return formattedParts.reduce((acc, part) => {
            if (part.type === 'minusSign') {
                return `(${acc}`;
            } else if (part.type === 'currency' || part.type === 'integer' || part.type === 'group') {
                return `${acc}${part.value}`;
            }
            return acc;
        }, '') + ')';
    }

    return formatter.format(amount);
}

  function handleResultClick(fullName, id) {
    setSearchTerm(fullName); // Keep the selected bank name in the search bar
    setSelectedBankId(id);
    setResults([]); // Clear the suggestions
    setShowToast(true); // Show the toast
  }

  async function handleClick() {
    if (!searchTerm) {
      setToastMessage('Please type a name first');
      setShowToast(true);
      return;
    }

    if (selectedBankId) {
      const newResults = await searchAPI(searchTerm);
      const matchingResult = newResults.find(
        (result) => searchTerm.toLowerCase() === result.data.NAME.toLowerCase()
      );

      if (matchingResult) {
        const { NAME, ID } = matchingResult.data;
        await handleBankSearch(NAME, ID, quarter);
      } else {
        setToastMessage('Result not found');
        setShowToast(true);
      }
    } else {
      const newResults = await searchAPI(searchTerm);
      const matchingResult = newResults.find(
        (result) => searchTerm.toLowerCase() === result.data.NAME.toLowerCase()
      );

      if (matchingResult) {
        const { NAME, ID } = matchingResult.data;
        await handleBankSearch(NAME, ID, quarter);
      } else {
        setToastMessage('Result not found');
        setShowToast(true);
      }
    }
  }

  function closeToast() {
    setShowToast(false);
    setSearchTerm(''); // Clear the search bar
    setSelectedBankId(null);  // Reset the selectedBankId for consistency
  }

  return (
    <div className="app-container-cre">
      <div className="search-container-cre" style={{ position: 'relative' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search for an FDIC bank"
          style={{ 
            border: '1px solid black',
            position: 'relative',
            width: '170px', 
            padding: '5px'
          }}
        />
        <button onClick={handleClick}>&#128269;</button>

        {showToast && (
            <div
              className="toast show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                minWidth: '500%', 
                zIndex: 1000,
                backgroundColor: 'white',
                color: 'royalblue',
                fontSize: '1rem',
                whiteSpace: 'pre-line'
              }}
              
            >
              <div className="toast-header">
              <strong className="me-auto">{financialsDataList[0]?.NAME || 'Bank Name'}</strong>
                <button
                    type="button"
                    className="btn-close ms-2 mb-1"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                    onClick={closeToast}
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div
                  className="toast-body"
                  style={{
                    '--toast-height': `${toastHeight}px`
                  }}
                  dangerouslySetInnerHTML={{ __html: toastMessage }}
                ></div>
            </div>
        )}
      </div>
      <div className="results-container-cre" style={{
          fontSize: '12.94px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          minWidth: '300px',
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: 10,
          boxSizing: 'border-box',
          top: 'calc(100% + 5px)',
          left: '-40px'
        }}>
         {results.map((result, index) => {
          const fullName = result.data.NAME;
          const displayName = fullName.split(',')[0];
          return (
            <div
              className={`result-item-cre ${highlightedIndex === index ? 'highlighted' : ''}`}
              key={result.data.ID}
              onClick={() => handleResultClick(fullName, result.data.ID)}
              style={{ display: 'block', cursor: 'pointer', padding: '5px 0' }}
              onMouseOver={() => setHighlightedIndex(index)}  // Set highlighted index on mouse over
              onMouseLeave={() => setHighlightedIndex(-1)} 
            >
              {displayName} ID:({result.data.ID})
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CommercialSearch;

*/

import React, { useState, useEffect } from 'react';
import './CommercialSearch.css'
function CommercialSearch({ REPDTE }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [financialData, setFinancialData] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [hasValidSearchTerm, setHasValidSearchTerm] = useState(false);
    const [selectedBankID, setSelectedBankID] = useState(null);

    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    // Reset highlightedIndex when results change
    useEffect(() => {
      setHighlightedIndex(-1);
    }, [results]);
  

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (highlightedIndex < results.length - 1) {
          setHighlightedIndex(prevIndex => prevIndex + 1);
        } else {
          setHighlightedIndex(0); // Loop back to the first result
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (highlightedIndex > 0) {
          setHighlightedIndex(prevIndex => prevIndex - 1);
        } else {
          setHighlightedIndex(results.length - 1); // Loop back to the last result
        }
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (highlightedIndex >= 0) {
          const selectedResult = results[highlightedIndex];
          handleResultClick(selectedResult.data.NAME, selectedResult.data.ID);
        }
      }
    };
    
    

    const quarterMapping = {
        '1231': 'Q4 ',
        '0930': 'Q3 ',
        '0630': 'Q2 ',
        '0331': 'Q1 ',
  };
  

    useEffect(() => {
        const handleEscapePress = (event) => {
            if (event.key === "Escape" && showToast) {
                handleToastClose();
            }
        };
    
        // Adding the event listener when the component mounts
        document.addEventListener("keydown", handleEscapePress);
    
        // Removing the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleEscapePress);
        };
    }, [showToast]); // The effect hook depends on the showToast state
    
  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=4&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    console.log("Search API Data:", data);  // <-- Add this line
    return data.data;
    }

    async function fetchFinancialDataForSearchTerm() {
        const searchResults = await searchAPI(searchTerm);
        if (searchResults && searchResults.length > 0) {
            fetchFinancialData(searchResults[0].data.ID);
        }
    }
    
    const financialMapping = [
      { "id": "ASSET", "title": "Total Assets", "definition": "Total Assets" },
      { "id": "EQTOT", "title": "Total Equity", "definition": "Total Equity" },
      // Loans for CRE - Overview
      { "id": "LNRENRES", "title": "Commercial Real Estate Loans" },
      { "id": "LNRENRES/EQTOT", "title": "Commercial RE Loans / Equity" },
      { "id": "LNRECONS", "title": "Construction and Land Development Real Estate Loans" },
      { "id": "LNRECONS/EQTOT", "title": "Construction Loans / Equity" },
      { "id": "LNREMULT", "title": "Multifamily Residential Real Estate Loans" },
      { "id": "LNRERES", "title": "1-4 Family Residential Real Estate Loans" },
      { "id": "LNREAG", "title": "Farmland Real Estate Loans" },
      { "id": "LNCOMRE", "title": "Commercial Real Estate Loans not Secured by Real Estate" },
      { "id": "LNRENROW", "title": "Commercial Real Estate Owner-Occupied" },
      { "id": "LNRENROT", "title": "Commercial Real Estate Other Non-Owner-Occupied" },
      { "id": "ORENRES", "title": "Foreclosed / Repossessed CRE" },
      { "id": "RATAIOLOANS", "title": "Non-Performing Real Estate Loans to Total Real Estate Loans" },
      { "id": "P3RENRES", "title": "Commercial Real Estate Past Due 30 - 89 Days" },	
      { "id": "P9RENRES", "title": "Commercial Real Estate Past Due 90+ Days" },	
      { "id": "P3RECONSR", "title": "Construction and Land Development, Past Due 30 - 89 Days" },
      { "id": "P3REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 30 - 89 Days" },
      { "id": "P9RECONS", "title": "Construction and Land Development, Past Due 90+ Days" },
      { "id": "P9REMULT", "title": "Secured By Multi-family Residential Properties, Past Due 90+ Days" }
  
  ];
    async function fetchFinancialData(CERT) {
      // Extract field ids, excluding ratio columns
      const fieldIds = financialMapping
          .filter(field => !field.id.includes('/EQTOT') && field.id !== 'RATAIOLOANS') // Exclude ratio columns and RATAIOLOANS
          .map(field => field.id)
          .join('%2C'); // URL encoded comma
  
      // Construct the API URL
      const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=${fieldIds}%2CREPDTE%2CP9RE%2CNARE%2CLNRE&sort_by=REPDTE&sort_order=DESC&limit=20&format=json&download=false&filename=data_file`;

  
      // Fetch data
      const response = await fetch(apiUrl);
      const data = await response.json();
        console.log("Financial Data:", data);
  
        
        // Attempt to find the relevant data using trim() with a guard for undefined values
        const relevantDataIndex = data.data.findIndex(item => item.data && item.data.REPDTE && item.data.REPDTE.trim() === REPDTE.trim());

        let displayData = [];
        if (relevantDataIndex !== -1) {
            displayData = data.data.slice(relevantDataIndex, relevantDataIndex + 20);  // Grab the relevant data plus the next 19 items
            setFinancialData(displayData);  // 'financialData' will now be an array
            setShowToast(true);
        } else {
            console.log("Relevant data not found.");
            setShowToast(false);
        }

        
    }
    async function handleSearch(event) {
        const query = event.target.value;
        setSearchTerm(query);
        setHasValidSearchTerm(false);  // <-- Reset to false when user manually changes the search term
    
        if (query.length > 1) {
            const results = await searchAPI(query);
            setResults(results);
        } else {
            setResults([]);
        }
    }
    

    function handleResultClick(fullName, CERT) {
        setSearchTerm(fullName);
        setResults([]);
        setHasValidSearchTerm(true);  
        setSelectedBankID(CERT);  // <-- Store the selected bank's ID
        fetchFinancialData(CERT);
    }
    

    async function handleSearchButtonClick() {
        if(hasValidSearchTerm && selectedBankID) {
            fetchFinancialData(selectedBankID);
        } else {
            fetchFinancialDataForSearchTerm();
        }
    }
    

const formatDollarValue = (value) => {
    const trueValue = Math.round(value * 1000);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(trueValue);
};

const formatRatioValue = (value) => {
    return parseFloat(value).toFixed(2) + "%";
};

const formatDataValue = (id, entry) => {

  if (id === "NAME") {
    return toTitleCase(entry.data[id]);
  } else if (id === "LNRENRES/EQTOT" || id === "LNRECONS/EQTOT") {
    const loan = id === "LNRENRES/EQTOT" ? entry.data["LNRENRES"] : entry.data["LNRECONS"];
    return formatRatioValue((loan / (entry.data["EQTOT"] || 1)) * 100);
  } else if (id === "RATAIOLOANS") {
    const NARE = entry.data["NARE"] || 0;
    const P9RE = entry.data["P9RE"] || 0;
    const LNRE = entry.data["LNRE"] || 1; // Use 1 to avoid division by zero

    console.log(`NARE: ${NARE}, P9RE: ${P9RE}, LNRE: ${LNRE}`);

    return formatRatioValue(((NARE + P9RE) / LNRE) * 100);
  } else {
    return formatDollarValue(entry.data[id]);
  }
};




function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}


  function handleToastClose() {
    setShowToast(false);
    setSearchTerm('');  // Clears the search bar
}

function toAllCaps(str) {
    return str.toUpperCase();
}

return (
<div className="app-container">
        <div className="search-toast-container">
            <div className="search-container" style={{ position: 'relative', display: 'flex', border: '1px solid black', borderRadius: '20px' }}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown} 
                    placeholder="Search for an FDIC bank"
                    style={{ 
                        border: 'none',
                        width: '170px',
                        padding: '5px',
                        borderRadius: '20px 0 0 20px',
                        outline: 'none'
                    }}
                />
                <button 
                    onClick={handleSearchButtonClick}
                    style={{ 
                        border: 'none',
                        background: 'transparent',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        color: 'blue',
                        borderRadius: '0 20px 20px 0'
                    }}>
                    &#128269;
                </button>
            </div>
        {/* Basic toast display */}
        {showToast && financialData && (
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{ zIndex: 1000,backgroundColor: 'white'}}>
                <div className="toast-header">
                    <strong className="me-auto">{financialData[0].data.NAME}</strong>
                    <button type="button" className="btn-close ms-2 mb-1" onClick={handleToastClose} aria-label="Close">
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover custom-table-autosearch">
                    <thead style={{ fontSize: '5px !important'}}>
                        <tr>
                        <th className="sticky-column-autosearch header-font-size">{toAllCaps('Metric')}</th>
                            {financialData.map(entry => {
                                const quarterPrefix = quarterMapping[entry.data.REPDTE.slice(4)] || '';
                                const year = entry.data.REPDTE.slice(0, 4);
                                return <th key={entry.data.REPDTE} className="header-font-size">{quarterPrefix + year}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    {financialMapping.map(({ id, title }, index) => (
                        <tr key={id} className={index % 2 === 0 ? 'alternate-row' : ''}>
                            <td className="first-column-caps sticky-column-autosearch" style={{ whiteSpace: 'nowrap',fontSize:'11px', fontWeight:'bold' }}>
                                {toAllCaps(title)}
                            </td>
                            {financialData.map(entry => (
                                <td key={entry.REPDTE + '-' + id} >
                                    {formatDataValue(id, entry)}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
            </div>
        )}

        </div>
        <div className="results-container" style={{
                fontSize: '0.7rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                minWidth: '255px',
                position: 'absolute',
                marginLeft: '-40px',
                backgroundColor: 'white',
                zIndex: 10,
                boxSizing: 'border-box',
                marginTop: '5px',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)'
            }}>
            {results.map((result,index) => {
                const fullName = result.data.NAME;
                const displayName = fullName.split(',')[0];
                return (
                    <div
                        className={`result-item-auto ${highlightedIndex === index ? 'highlighted' : ''}`}
                        key={result.data.ID}
                        onClick={() => handleResultClick(fullName, result.data.ID)}
                        style={{ 
                            display: 'block',
                            cursor: 'pointer',
                            padding: '5px 10px',
                            transition: 'background-color 0.3s',
                            ':hover': {
                                backgroundColor: 'lightgrey'
                            }
                        }}
                        onMouseOver={() => setHighlightedIndex(index)}  // Set highlighted index on mouse over
                        onMouseLeave={() => setHighlightedIndex(-1)} 
                    >
                        {displayName} ID:({result.data.ID})
                    </div>
                );
            })}
        </div>
    </div>
);



}

export default CommercialSearch;
