import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './ConsumerLoans.css';
import ConsumerSearch from './ConsumerSearch';

const ComsumerLoans = ({ style }) => {
  
  const currentDate = new Date();
  const [loading, setLoading] = useState(true);

  function getDefaultQuarter() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const month = currentDate.getMonth();
    let defaultQuarter;

    if (month < 3) {  // January, February, March
        defaultQuarter = '0930'; // Q3 of the previous year
        currentYear--;
    } else if (month < 6) {  // April, May, June
        defaultQuarter = '1231'; // Q4 of the previous year
        currentYear--;
    } else if (month < 9) {  // July, August, September
        defaultQuarter = '0331'; // Q1 of the current year
    } else {  // October, November, December
        defaultQuarter = '0630'; // Q2 of the current year
    }

    const endOfSelectedQuarter = new Date(currentYear, parseInt(defaultQuarter.substring(0, 2)) - 1, parseInt(defaultQuarter.substring(2, 4)));
    if ((currentDate - endOfSelectedQuarter) / (1000 * 60 * 60 * 24) < 70) {
        if (defaultQuarter === '0331') {
            defaultQuarter = '1231';
            currentYear--;
        } else if (defaultQuarter === '0630') {
            defaultQuarter = '0331';
        } else if (defaultQuarter === '0930') {
            defaultQuarter = '0630';
        } else if (defaultQuarter === '1231') {
            defaultQuarter = '0930';
        }
    }

    return { year: currentYear, quarter: defaultQuarter };
}


const { year, quarter } = getDefaultQuarter();
  
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const years = [];
  for (let y = currentDate.getFullYear(); y >= 1998; y--) {
    years.push({ value: y, label: y.toString() });
  }

  const quarters = [
    { value: '0331', label: 'Q1' },
    { value: '0630', label: 'Q2' },
    { value: '0930', label: 'Q3' },
    { value: '1231', label: 'Q4' },
  ];

  const [selectedYear, setSelectedYear] = useState({ value: year, label: year.toString() });
  const [selectedQuarter, setSelectedQuarter] = useState({ value: quarter, label: quarters.find(q => q.value === quarter).label });
  const columns = [
    { "id": "NAME", "title": "Name", "definition": "" },
    { "id": "ASSET", "title": "Total Assets", "definition": "Total Assets" },
    { "id": "EQTOT", "title": "Total Equity", "definition": "Total Equity" },
    // Loans to Individuals - Overview
    { "id": "LNCON", "title": "Total Loans to Individuals" },
    { "id": "CONSLOAN_EQ_PERC","title": "Total Loans to Individuals/Equity %","definition": "The percentage calculation of auto loans over total equity."},
    { "id": "LNCONR", "title": "Total Loans to Individuals Ratio %" },
    
    // Loans to Individuals - Foreign Offices
    { "id": "LNCONFOR", "title": "Foreign Office Loans to Individuals" },
    { "id": "LNCONFORR", "title": "Foreign Office Loans to Individuals Ratio %" },
    
    // Loans to Individuals - Types
    { "id": "LNCONOTH", "title": "Loans for household, family, and other personal expenditures" },
    
    // Loans to Individuals - Ratios
    { "id": "LNCONOTHR", "title": "Loans for household, family, and other personal expenditures Ratio %" },
    
    // Non-Accrual and Past Due
    { "id": "NACON", "title": "Non-accrual Loans to Individuals" },
    { "id": "P3CON", "title": "30-89 Days Past Due Loans to Individuals" },
    { "id": "P9CON", "title": "90+ Days Past Due Loans to Individuals" },
    
    // Credit Cards - Overview
    { "id": "LNCRCD", "title": "Consumer Credit Card Loans" },
    { "id": "LNCRCDR", "title": "Consumer Credit Card Loans Ratio %" },
    
    // Credit Cards - Non-Accrual and Past Due
    { "id": "NACRCD", "title": "Non-accrual Credit Card Loans" },
    { "id": "P3CRCD", "title": "30-89 Days Past Due Credit Card Loans" },
    { "id": "P9CRCD", "title": "90+ Days Past Due Credit Card Loans" },
    
    // Credit Cards - Recoveries and Charge-offs
    { "id": "CRCRCD", "title": "Credit Card Recoveries" },
    { "id": "DRCRCD", "title": "Charged-Off Credit Cards" },
    
    // Credit Cards - Net Charge-offs
    { "id": "IDNTCRDR", "title": "Net Charge-Off Rate for Credit Cards %" },

    // Loss-share and Others
    { "id": "NTCONOTR", "title": "Net Charge-Offs of Other Consumer Loans %" },
    
    // Total N/C
    { "id": "NCCON", "title": "Total N/C Consumer Loans" }
];

const fetchedREPDTE = `${selectedYear.value}${selectedQuarter.value}`;

  useEffect(() => {
    const fieldIds = columns.map(column => column.id).join('%2C');
    const apiUrl = `https://banks.data.fdic.gov/api/financials?filters=REPDTE%3A${fetchedREPDTE}&fields=${fieldIds}%2CCERT&sort_by=LNCON&sort_order=DESC&limit=10000&format=json&download=false&filename=data_file`;
    
    axios.get(apiUrl)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          setData(response.data.data);
          setErrorMessage(''); // Clear previous error messages
        } else if (response.data && response.data.meta && response.data.meta.total === 0) {
          setErrorMessage("The data for the selected quarter isn't available, public data for an FDIC bank becomes available 60 days after the quarter ends");
          setData([]); // Clear old data
        }
      })
      .catch(error => {
        console.error('Error fetching the auto loans data:', error);
      });
  }, [selectedYear, selectedQuarter]);

  const PAGE_SIZE = 25;
  const [currentPage, setCurrentPage] = useState(0);

  const numberOfPages = Math.ceil(data.length / PAGE_SIZE);

  const paginatedData = data.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

  const formatDollarValue = (value) => {
    const trueValue = Math.round(value * 1000);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(trueValue);
    };

  const formatRatioValue = (value) => {
    return parseFloat(value).toFixed(2)+"%";
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  

  return (
    <div style={style} className="auto-loans-container">
      <h2>Consumer Loans Data for {selectedYear.label} - {selectedQuarter.label}</h2>
      
      {errorMessage && <div className="error-message">{errorMessage}</div>}
  
      <div className="dropdown-container-auto">
        <label className="dropdown-label-auto">Select a Year:</label>
        <Select 
            className="custom-dropdown-auto"
            options={years} 
            value={selectedYear}
            onChange={value => setSelectedYear(value)}
            placeholder="Select Year"
        />

        <label className="dropdown-label-auto">Select a Quarter:</label>
        <Select 
            className="custom-dropdown-auto"
            options={quarters} 
            value={selectedQuarter}
            onChange={value => setSelectedQuarter(value)}
            placeholder="Select Quarter"
        />
    </div>

      {!errorMessage && (
        <>
          <div className="table-container">
          <table className="table table-hover custom-table-auto custom-table-auto--first">
            <thead>
            <tr>
                {columns.map((col, idx) => (
              <td key={col.id} className={idx === 0 ? 'sticky-column' : ''} title={col.definition}>
                <div className="name-search-wrapper">
                    <span className="name-text">{col.title}</span>
                    {col.id === "NAME" && <ConsumerSearch REPDTE={fetchedREPDTE} />} 
                </div>
            </td>
                ))}
            </tr>
            </thead>
              <tbody>
                {paginatedData.map(item => (
                    <tr key={item.data.CERT}>
                    {columns.map((col, idx) => (
                    <td key={col.id} className={idx === 0 ? 'sticky-column' : ''}>
                       {col.id === "NAME"
                        ? toTitleCase(item.data[col.id])
                        : (col.id === "LNCONFOR"
                            ? formatDollarValue(item.data[col.id])  // format as dollar
                            : (col.id.charAt(col.id.length - 1) === "R"
                                ? formatRatioValue(item.data[col.id]) 
                                : (col.id === "CONSLOAN_EQ_PERC"
                                    ? formatRatioValue((item.data["LNCON"] / (item.data["EQTOT"] || 1)) * 100)
                                    : formatDollarValue(item.data[col.id])
                                    )
                                )
                            )
                        }
                    </td>
                    ))}
                    </tr>
                ))}
            </tbody>
            </table>
          </div>
          <div className="pagination-controls">
            <button 
              onClick={() => setCurrentPage(page => Math.max(0, page - 1))}
              disabled={currentPage === 0}
              className= "btn btn-primary btn-sm"
              style={{ fontSize: '0.7rem', paddingTop: '4px',paddingBottom: '4px', backgroundColor:'royalblue'}} 
            >
              Previous
            </button>
            <span>Page {currentPage + 1} of {numberOfPages}</span>
            <button 
              onClick={() => setCurrentPage(page => Math.min(numberOfPages - 1, page + 1))}
              disabled={currentPage === numberOfPages - 1}
              className= "btn btn-primary btn-sm"
              style={{ fontSize: '0.7rem', paddingTop: '4px',paddingBottom: '4px',backgroundColor:'royalblue' }} 
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
);

};

export default ComsumerLoans;

