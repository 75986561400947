import React, { useState, useEffect } from 'react';
import './AutoSearch.css';

function AutoSearch({ REPDTE }) {
    console.log("REPDTE value:", REPDTE, "Type:", typeof REPDTE);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [financialData, setFinancialData] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [hasValidSearchTerm, setHasValidSearchTerm] = useState(false);
    const [selectedBankID, setSelectedBankID] = useState(null);

    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    // Reset highlightedIndex when results change
    useEffect(() => {
      setHighlightedIndex(-1);
    }, [results]);
  

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (highlightedIndex < results.length - 1) {
          setHighlightedIndex(prevIndex => prevIndex + 1);
        } else {
          setHighlightedIndex(0); // Loop back to the first result
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (highlightedIndex > 0) {
          setHighlightedIndex(prevIndex => prevIndex - 1);
        } else {
          setHighlightedIndex(results.length - 1); // Loop back to the last result
        }
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (highlightedIndex >= 0) {
          const selectedResult = results[highlightedIndex];
          handleResultClick(selectedResult.data.NAME, selectedResult.data.ID);
        }
      }
    };
    
    

    const quarterMapping = {
        '1231': 'Q4 ',
        '0930': 'Q3 ',
        '0630': 'Q2 ',
        '0331': 'Q1 ',
  };
  

    useEffect(() => {
        const handleEscapePress = (event) => {
            if (event.key === "Escape" && showToast) {
                handleToastClose();
            }
        };
    
        // Adding the event listener when the component mounts
        document.addEventListener("keydown", handleEscapePress);
    
        // Removing the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleEscapePress);
        };
    }, [showToast]); // The effect hook depends on the showToast state
    
  async function searchAPI(query) {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions?search=NAME%3A${query}&fields=NAME,ID&sort_by=OFFICES&sort_order=DESC&limit=4&offset=0&format=json&download=false&filename=data_file`);
    const data = await response.json();
    console.log("Search API Data:", data);  // <-- Add this line
    return data.data;
    }

    async function fetchFinancialDataForSearchTerm() {
        const searchResults = await searchAPI(searchTerm);
        if (searchResults && searchResults.length > 0) {
            fetchFinancialData(searchResults[0].data.ID);
        }
    }
    

    async function fetchFinancialData(CERT) {
        const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT%3A${CERT}&fields=NAME%2CASSET%2CEQTOT%2CLNAUTO%2CAUTOLOAN_EQ_PERC%2CLNAUTOR%2CCRAUTO%2CDRAUTO%2CDRAUTOR%2CNAAUTO%2CNAAUTOR%2CNTAUTO%2CP3AUTO%2CP3AUTOR%2CP9AUTO%2CP9AUTOR%2CCERT%2CREPDTE&sort_by=REPDTE&sort_order=DESC&limit=250&format=json&download=false&filename=data_file`);
        const data = await response.json();
        console.log("Financial Data:", data);
    
        // Diagnostic Steps
        console.log("Type of REPDTE prop:", typeof REPDTE);
        console.log("Value of REPDTE prop:", REPDTE);
        
        console.log("Direct Comparison Test:");
        data.data.forEach(item => {
            if (item.data && item.data.REPDTE) {
                console.log(item.data.REPDTE, REPDTE, item.data.REPDTE.trim() === REPDTE.trim());
            } else {
                console.log("Item with undefined REPDTE:", item);
            }
        });
        
        // Attempt to find the relevant data using trim() with a guard for undefined values
        const relevantDataIndex = data.data.findIndex(item => item.data && item.data.REPDTE && item.data.REPDTE.trim() === REPDTE.trim());

        let displayData = [];
        if (relevantDataIndex !== -1) {
            console.log("Relevant data found at index:", relevantDataIndex);
            displayData = data.data.slice(relevantDataIndex, relevantDataIndex + 20);  // Grab the relevant data plus the next 19 items
            setFinancialData(displayData);  // 'financialData' will now be an array
            setShowToast(true);
        } else {
            console.log("Relevant data not found.");
            setShowToast(false);
        }

        
    }
    async function handleSearch(event) {
        const query = event.target.value;
        setSearchTerm(query);
        setHasValidSearchTerm(false);  // <-- Reset to false when user manually changes the search term
    
        if (query.length > 1) {
            const results = await searchAPI(query);
            setResults(results);
        } else {
            setResults([]);
        }
    }
    

    function handleResultClick(fullName, CERT) {
        setSearchTerm(fullName);
        setResults([]);
        setHasValidSearchTerm(true);  
        setSelectedBankID(CERT);  // <-- Store the selected bank's ID
        fetchFinancialData(CERT);
    }
    

    async function handleSearchButtonClick() {
        if(hasValidSearchTerm && selectedBankID) {
            fetchFinancialData(selectedBankID);
        } else {
            fetchFinancialDataForSearchTerm();
        }
    }
    
    const financialMapping = [
        { "id": "ASSET", "title": "Total Assets" },
        { "id": "EQTOT", "title": "Total Equity" },
        { "id": "LNAUTO", "title": "Auto Loans" },
        { "id": "AUTOLOAN_EQ_PERC", "title": "Auto Loans/Equity %" },
        { "id": "LNAUTOR", "title": "Auto Loans/Total Loans" },
        { "id": "CRAUTO", "title": "Auto Loans Recoveries" },
        { "id": "DRAUTO", "title": "Auto Loans Charge-Offs" },
        { "id": "DRAUTOR", "title": "Auto Loans Charge-Offs Ratio" },
        { "id": "NAAUTO", "title": "Non-accrual Auto Loans" },
        { "id": "NAAUTOR", "title": "Non-accrual Auto Loans Ratio" },
        { "id": "NTAUTO", "title": "Auto Loans - Net Charge Offs" },
        { "id": "P3AUTO", "title": "30-89 Days P/D Auto Loans" },
        { "id": "P3AUTOR", "title": "30-89 Days P/D Auto Loans Ratio" },
        { "id": "P9AUTO", "title": "90 + Days P/D Auto Loans" },
        { "id": "P9AUTOR", "title": "90 + Days P/D Auto Loans Ratio" }
    ];

const formatDollarValue = (value) => {
    const trueValue = Math.round(value * 1000);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(trueValue);
};

const formatRatioValue = (value) => {
    return parseFloat(value).toFixed(2) + "%";
};

const formatDataValue = (id, entry) => {
    console.log("Specific Value for ID:", id, "is:", entry.data[id]);

    if (id === "NAME") {
        return toTitleCase(entry.data[id]);
    } else if (id.charAt(id.length - 1) === "R") {
        return formatRatioValue(entry.data[id]);
    } else if (id === "AUTOLOAN_EQ_PERC") {
        return formatRatioValue((entry.data["LNAUTO"] / (entry.data["EQTOT"] || 1)) * 100);
    } else {
        return formatDollarValue(entry.data[id]);
    }
};


function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}




  function handleToastClose() {
    setShowToast(false);
    setSearchTerm('');  // Clears the search bar
}

function toAllCaps(str) {
    return str.toUpperCase();
}

return (
<div className="app-container">
        <div className="search-toast-container">
            <div className="search-container" style={{ position: 'relative', display: 'flex', border: '1px solid black', borderRadius: '20px' }}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown} 
                    placeholder="Search for an FDIC bank"
                    style={{ 
                        border: 'none',
                        width: '170px',
                        padding: '5px',
                        borderRadius: '20px 0 0 20px',
                        outline: 'none'
                    }}
                />
                <button 
                    onClick={handleSearchButtonClick}
                    style={{ 
                        border: 'none',
                        background: 'transparent',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        color: 'blue',
                        borderRadius: '0 20px 20px 0'
                    }}>
                    &#128269;
                </button>
            </div>
        {/* Basic toast display */}
        {showToast && financialData && (
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{ zIndex: 1000,backgroundColor: 'white'}}>
                <div className="toast-header">
                    <strong className="me-auto">{financialData[0].data.NAME}</strong>
                    <button type="button" className="btn-close ms-2 mb-1" onClick={handleToastClose} aria-label="Close">
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover custom-table-autosearch">
                    <thead style={{ fontSize: '5px !important'}}>
                        <tr>
                        <th className="sticky-column-autosearch header-font-size">{toAllCaps('Metric')}</th>
                            {financialData.map(entry => {
                                const quarterPrefix = quarterMapping[entry.data.REPDTE.slice(4)] || '';
                                const year = entry.data.REPDTE.slice(0, 4);
                                return <th key={entry.data.REPDTE} className="header-font-size">{quarterPrefix + year}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    {financialMapping.map(({ id, title }, index) => (
                        <tr key={id} className={index % 2 === 0 ? 'alternate-row' : ''}>
                            <td className="first-column-caps sticky-column-autosearch" style={{ whiteSpace: 'nowrap',fontSize:'11px', fontWeight:'bold' }}>
                                {toAllCaps(title)}
                            </td>
                            {financialData.map(entry => (
                                <td key={entry.REPDTE + '-' + id} >
                                    {formatDataValue(id, entry)}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
            </div>
        )}

        </div>
        <div className="results-container" style={{
                fontSize: '0.7rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                minWidth: '255px',
                position: 'absolute',
                marginLeft: '-40px',
                backgroundColor: 'white',
                zIndex: 10,
                boxSizing: 'border-box',
                marginTop: '5px',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)'
            }}>
            {results.map((result,index) => {
                const fullName = result.data.NAME;
                const displayName = fullName.split(',')[0];
                return (
                    <div
                        className={`result-item-auto ${highlightedIndex === index ? 'highlighted' : ''}`}
                        key={result.data.ID}
                        onClick={() => handleResultClick(fullName, result.data.ID)}
                        style={{ 
                            display: 'block',
                            cursor: 'pointer',
                            padding: '5px 10px',
                            transition: 'background-color 0.3s',
                            ':hover': {
                                backgroundColor: 'lightgrey'
                            }
                        }}
                        onMouseOver={() => setHighlightedIndex(index)}  // Set highlighted index on mouse over
                        onMouseLeave={() => setHighlightedIndex(-1)} 
                    >
                        {displayName} ID:({result.data.ID})
                    </div>
                );
            })}
        </div>
    </div>
);



}

export default AutoSearch;